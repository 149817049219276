import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    minHeight: '100vh',
    // paddingTop: "40px",
    position: "relative",
    // position: "absolute",
    // top: "0px",
    // left:"0px",
    // right: "0px",
    // bottom: "0px"
  },
  welcomeMessage: {
    color: theme.palette.reflexYellow.main,
    marginBottom: "auto",
    // zIndex: "100000",
    fontSize: "2.5vh"
  },
  logo: {
    height: "40vmin",
    pointerEvents: "none"
  },
  welcomeImage: {
    height: "70vmin",
    pointerEvents: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

const WelcomeScreen = (props) => {
  const { t, i18next } = useTranslation(); 
  const classes = useStyles(props);
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
  return (
    <Box className={classes.root}>
      <Typography className={classes.welcomeMessage}>{props.welcomeMessage}</Typography>
      {/* TODO: get the logo from broadcast settings */}
      {/* {props.welcomeImage === undefined && (<PulseLoader color="#ff2900" loading="true" css={override} size={35} />)} */}
      {props.welcomeImage !== undefined && (<img src={props.welcomeImage} className={classes.welcomeImage} alt="logo reflex interactions" />)}
    </Box>
  );
};

export default WelcomeScreen;
