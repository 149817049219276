import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getUserAvatar } from "../../../../utils/callControllerUtils";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
  },
}));

export default function UserErrorNotification(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  let title = "";
  let message = "";
  switch (props.error.error.name) {
    //Devices errors
    case "DEVICE_ACCESS_DENIED":
      title = t("userErrorNotification|DEVICE_ACCESS_DENIED_TITLE");
      message = t("userErrorNotification|DEVICE_ACCESS_DENIED_MESSAGE");
      break;
    case "DEVICE_ALREADY_IN_USE":
      title = t("userErrorNotification|DEVICE_ALREADY_IN_USE_TITLE");
      message = t("userErrorNotification|DEVICE_ALREADY_IN_USE_MESSAGE");
      break;

    case "INPUT_AUDIO_DEVICE_GENERIC_ERROR":
      title = t("userErrorNotification|INPUT_AUDIO_DEVICE_GENERIC_ERROR_TITLE");
      message = t(
        "userErrorNotification|INPUT_AUDIO_DEVICE_GENERIC_ERROR_MESSAGE"
      );
      break;

    case "INPUT_AUDIO_DEVICE_NOT_FOUND":
      title = t("userErrorNotification|INPUT_AUDIO_DEVICE_NOT_FOUND_TITLE");
      message = t("userErrorNotification|INPUT_AUDIO_DEVICE_NOT_FOUND_MESSAGE");
      break;

    case "INPUT_VIDEO_DEVICE_NOT_FOUND":
      title = t("userErrorNotification|INPUT_VIDEO_DEVICE_NOT_FOUND_TITLE");
      message = t("userErrorNotification|INPUT_VIDEO_DEVICE_NOT_FOUND_MESSAGE");
      break;

    case "NO_INPUT_SOURCE_SET":
      title = t("userErrorNotification|NO_INPUT_SOURCE_SET_TITLE");
      message = t("userErrorNotification|NO_INPUT_SOURCE_SET_MESSAGE");
      break;

    case "PUBLISHER_PROPERTIES_ERROR":
      title = t("userErrorNotification|PUBLISHER_PROPERTIES_ERROR_TITLE");
      message = t("userErrorNotification|PUBLISHER_PROPERTIES_ERROR_MESSAGE");
      break;

    case "SCREEN_CAPTURE_DENIED":
      title = t("userErrorNotification|SCREEN_CAPTURE_DENIED_TITLE");
      message = t("userErrorNotification|SCREEN_CAPTURE_DENIED_MESSAGE");
      break;

    case "SCREEN_EXTENSION_DISABLED":
      title = t("userErrorNotification|SCREEN_EXTENSION_DISABLED_TITLE");
      message = t("userErrorNotification|SCREEN_EXTENSION_DISABLED_MESSAGE");
      break;

    case "SCREEN_EXTENSION_NOT_INSTALLED":
      title = t("userErrorNotification|SCREEN_EXTENSION_NOT_INSTALLED_TITLE");
      message = t(
        "userErrorNotification|SCREEN_EXTENSION_NOT_INSTALLED_MESSAGE"
      );
      break;

    case "SCREEN_SHARING_NOT_SUPPORTED":
      title = t("userErrorNotification|SCREEN_SHARING_NOT_SUPPORTED_TITLE");
      message = t("userErrorNotification|SCREEN_SHARING_NOT_SUPPORTED_MESSAGE");
      break;
  }
  const stringAvatar = () => {
    return props.error.username.charAt(0).toUpperCase();
  };
  return (
    <Card style={{ width: "100%" }} className={classes.root}>
      <CardHeader
        avatar={
          <UserAvatar
            className={classes.avatar}
            aria-label="avatar"
            online={false}
            src={getUserAvatar(props.error.userid)}
          >
            {stringAvatar()}
          </UserAvatar>
        }
        title={props.error.username + ":" + title}
      />
      <CardContent>
        <Typography>{message}</Typography>
      </CardContent>
    </Card>
  );
}
