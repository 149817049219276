import {
  MODERATIONS,
  MODERATIONS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import {
  websocketServices,
  dialogStateFactory,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {
  openDialog,
  setSelectedIntercomInputValue,
  setSelectedIntercomSlot,
  setSelectedWebcam,
  setWebcamInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import i18nInstance from "../i18n";
import { store } from "react-notifications-component";
import UserErrorNotification from "../components/callModerator/components/users/UserErrorNotification";
import webrtcVideoPlayingListener from "@reflex-interactions/library-reflex-client/dist/reflexAPI/listeners/webrtcVideoPlayingListener";
import callRoomListener from "@reflex-interactions/library-reflex-client/dist/reflexAPI/listeners/callRoomListener";


export function switchMonitorWall() {
  callRoomListener()
  webrtcVideoPlayingListener()
}


export function userErrorNotification(error) {

//   const userError = {
//     userid: websocketServices.getLocalUser().getUserId(),
//     connectionid: websocketServices.getLocalUser().getConnectionId(),
//     username: websocketServices.getLocalUser().getNickname(),
//     error: errorToSend
// }

  store.addNotification({
    type: "danger",
    insert: "bottom-right",
    container: "bottom-right",
    content: <UserErrorNotification error={error}/>,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 0,
      onScreen: true
    }
  });
  
}

export function captureUserAvatar(user) {
  const data = {
    command: websocketServices.DEVICES_COMMANDS.TAKE_AVATAR,
  };
  websocketServices.signals.sendDevicesCommand(
    data,
    user.getConnectionId()
  );
}

export function getUserAvatar(userid) {

  const websocket = websocketServices.reduxStore.default.getState().websocket
  const participantUser = Object.values(websocket.participants).find(participant => participant.userid === userid)
  const moderatedUser = websocket.intercom.moderated[userid]
  const CMUser =  Object.values(websocket.callModerators).find(participant => participant.userid === userid)

  if (participantUser !== undefined) {
    return participantUser.avatar
  }

  if (moderatedUser !== undefined) {
    return moderatedUser.avatar
  }

  if (CMUser !== undefined) {
    return CMUser.avatar
  }

  return undefined

}

export function getUserDetails(participants, user) {
  var online = false;
  var isAudioActive = true;
  var isVideoActive = true;
  var isScreenShareActive = false;
  var isScreenShareAllowed = false;
  var audio = [];
  var video = [];
  var selectedMicId = "";
  var selectedCamId = "";
  var avatar = undefined;
  var resolution = "VGA";
  var screenConstraint = {};
  var note = undefined;
  var _userDetails = participants.filter((_user) => {
    if (_user.userid === user.userid) {
      online = true;
      return _user;
    }
  });
  var userModerated = websocketServices.reduxStore.default.getState().websocket.intercom.moderated[user.userid]
  if (userModerated !== undefined) {
    note = userModerated.note
  }
  var details = {
    connectionId: "",
    contributor: false,
    language: "",
    location: "",
    participantId: "",
    platform: "",
  };
  if (online) {
    details = {
      connectionId: _userDetails[0].connectionId,
      contributor: _userDetails[0].contributor,
      language: _userDetails[0].language,
      location: _userDetails[0].location,
      participantId: _userDetails[0].participantId,
      platform: _userDetails[0].platform,
    };
    isAudioActive = _userDetails[0].isAudioActive;
    isVideoActive = _userDetails[0].isVideoActive;
    isScreenShareActive = _userDetails[0].isScreenShareActive;
    isScreenShareAllowed = _userDetails[0].isScreenShareActive;
    audio = _userDetails[0].audio;
    video = _userDetails[0].video;
    selectedMicId = _userDetails[0].selectedMicId;
    selectedCamId = _userDetails[0].selectedCamId;
    // avatar = _userDetails[0].avatar;
    resolution = _userDetails[0].resolution;
    if (_userDetails[0].screenConstraint !== undefined) {
      screenConstraint = _userDetails[0].screenConstraint;
    }
  }
  var userDetails = {
    username: user.username,
    // avatar: user.avatar,
    online: online,
    userid: user.userid,
    details: details,
    isAudioActive: isAudioActive,
    isVideoActive: isVideoActive,
    isScreenShareActive: isScreenShareActive,
    isScreenShareAllowed: isScreenShareAllowed,
    audio: audio,
    video: video,
    selectedMicId: selectedMicId,
    selectedCamId: selectedCamId,
    resolution: resolution,
    screenConstraint: screenConstraint,
    note: note,
  };
  return userDetails;
}
export function getModeratorUserId(participant, callModerators) {
  var _userId = callModerators.filter((_user) => {
    if (_user.connectionId === participant.connectionId) {
      return _user.userId;
    }
  });

  return _userId;
}
export function getUserStatus(
  user,
  onAir,
  intercom,
  moderated,
  isOnAir,
  doWebcamCheck
) {
  var status = {
    onAir: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      100: false,
    },
    intercom: {
      partyLine: false,
      controlRoom: false,
      director: false,
      moderations: {
        inModeration: false,
        id: "",
        started: false,
      },
      moderated: false,
    },
    isOnAir: false,
    isAudioActive: user.isAudioActive,
    isVideoActive: user.isVideoActive,
    isScreenShareActive: user.isScreenShareActive,
    inWebcamSlot: false,
    inScreenShareSlot: false,
  };

  if (doWebcamCheck) {
    
    if (getWebcamStatus(user, onAir["1"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["2"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["3"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["4"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["5"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["6"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["7"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["8"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["9"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["10"])) {
      status.inWebcamSlot = true
    }
    if (getWebcamStatus(user, onAir["100"])) {
      status.inScreenShareSlot = true
    }
    status.isOnAir = getWebcamOnAirStatus(user, onAir, isOnAir);
  }

  status.intercom.partyLine = getIntercomStatus(user, intercom.partyLine);
  status.intercom.controlRoom = getIntercomStatus(user, intercom.controlRoom);
  status.intercom.director = getIntercomStatus(user, intercom.director);
  status.intercom.moderated = getIntercomStatus(user, intercom.moderated);
  status.intercom.moderations = getModerationsStatus(
    user,
    intercom.moderations
  );

  return status;
}

export function addModerateUser(user, moderationsID) {
  if (user.online === true) {
    let type = "USER";
    if (user.type !== undefined) {
      type = user.type;
    }
    const userToSend = {
      userid: user.userid,
      username: user.username,
      // avatar: user.avatar,
      type: type,
    };

    websocketServices.signals.addUserToModerationCall(
      userToSend,
      moderationsID
    );
  }
}

export function removeModerateUser(user, moderationsID, moderations) {
  if (Object.keys(moderations[moderationsID]).length === 5) {
    websocketServices.signals.removeModerationCall(moderationsID);
    const payload = { intercomType: MODERATIONS, value: null };
    websocketServices.reduxStore.default.dispatch(
      setSelectedIntercomSlot(payload)
    );
    var payload2 = { type: MODERATIONS_INPUT_VALUE, value: "" };
    websocketServices.reduxStore.default.dispatch(
      setSelectedIntercomInputValue(payload2)
    );
  } else if (Object.keys(moderations[moderationsID]).length > 5) {
    var callModeratorCount = 0;
    Object.keys(moderations[moderationsID]).forEach(function (key, index) {
      if (moderations[moderationsID][key].type === "CALL_MODERATOR") {
        callModeratorCount = callModeratorCount + 1;
      }
    });
    if (
      Object.keys(moderations[moderationsID]).length - callModeratorCount === 4 &&
      user.userid === moderationsID
    ) {
      websocketServices.signals.removeModerationCall(moderationsID);
      var payload = { intercomType: MODERATIONS, value: null };
      websocketServices.reduxStore.default.dispatch(
        setSelectedIntercomSlot(payload)
      );
      var payload2 = { type: MODERATIONS_INPUT_VALUE, value: "" };
      websocketServices.reduxStore.default.dispatch(
        setSelectedIntercomInputValue(payload2)
      );
    } else {
      websocketServices.signals.removeUserFromModerationCall(
        user,
        moderationsID
      );
    }
  }
}

export function moderateUser(user, moderationsStatus, moderations, isUserModerated) {
  const localUser = websocketServices.getLocalUser();
  var callModeratorUser = {
    userid: localUser.getUserId(),
    username: localUser.getNickname(),
    // avatar: localUser.getAvatar(),
    type: "CALL_MODERATOR",
  };

  if (moderationsStatus.inModeration && user.online === true) {
    // var moderation = {
    //   id: "8fc4d770-af62-11eb-a740-8fea766052f8",
    //   broadcastId: "TestRoom",
    // };
    if (Object.keys(moderations[moderationsStatus.id]).length === 5) {
      websocketServices.signals.removeModerationCall(moderationsStatus.id);
      var payload = { intercomType: MODERATIONS, value: null };
      websocketServices.reduxStore.default.dispatch(
        setSelectedIntercomSlot(payload)
      );
      var payload2 = { type: MODERATIONS_INPUT_VALUE, value: "" };
      websocketServices.reduxStore.default.dispatch(
        setSelectedIntercomInputValue(payload2)
      );
      

    } else if (Object.keys(moderations[moderationsStatus.id]).length > 5) {
      var callModeratorCount = 0;
      Object.keys(moderations[moderationsStatus.id]).forEach(function (
        key,
        index
      ) {
        if (moderations[moderationsStatus.id][key].type === "CALL_MODERATOR") {
          callModeratorCount = callModeratorCount + 1;
        }
      });
      if (
        Object.keys(moderations[moderationsStatus.id]).length -
          callModeratorCount ===
        4
      ) {
        websocketServices.signals.removeModerationCall(moderationsStatus.id);
        var payload = { intercomType: MODERATIONS, value: null };
        websocketServices.reduxStore.default.dispatch(
          setSelectedIntercomSlot(payload)
        );
        var payload2 = { type: MODERATIONS_INPUT_VALUE, value: "" };
        websocketServices.reduxStore.default.dispatch(
          setSelectedIntercomInputValue(payload2)
        );
        
      } else {
        websocketServices.signals.removeUserFromModerationCall(
          user,
          moderationsStatus.id
        );
      }
    }
  } else if (user.online === true) {
    //Check if moderator has already created his moderation room. If yes add the user to this room. No creation needed
    const myModerationRoomId = Object.keys(moderations).find(function (
      key,
      index
    ) {
      // key: the name of the object key
      // index: the ordinal position of the key within the object
      if (
        moderations[key] !== null &&
        moderations[key] !== undefined &&
        key === localUser.getUserId()
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (myModerationRoomId !== undefined) {
      addModerateUser(user, myModerationRoomId);
      if (!isUserModerated) {
        websocketServices.signals.addUserToModerated(user);
      } 
    } else {
      var moderation = {};
      moderation[localUser.getUserId()] = callModeratorUser;

      moderation.started = false;
      moderation.name = localUser.getNickname();
      moderation.id = localUser.getUserId();
      // INFO: IMPORTANT !!!! We need to do this in 2 steps cause with avatar data we reach the maximum size of a message for openvidu server
      // and the connection to websocket will hang out and reconnect. So we create the moderations call with de call controler
      // user and then we add the first participant user in the second step
      websocketServices.signals.addModerationCall(moderation);
      addModerateUser(user, moderation.id);
    }
  }
}

export function startStopModeration(moderationID, started, moderation, moderations) {
  websocketServices.signals.startStopModeration(moderationID, started);
  if (started) {
    const localUser = websocketServices.getLocalUser();
    Object.values(moderation).filter(user =>{ 
      if (user.type === 'CALL_MODERATOR' && user.userid !== localUser.getUserId()) {
        removeModerateUser(user, moderationID, moderations)
      }
    })
  }
}

export function addUserToIntercom(user, userIsInSlot) {
  if (userIsInSlot) {
    websocketServices.signals.removeUserFromControlRoom(user.userid);
  } else {
    websocketServices.signals.addUserToControlRoom(user);
  }
}
export function sendMessageToUser(user) {}
export function removeUserFromCall(user, webcamID) {
  websocketServices.signals.removeCallParticipant(user, webcamID);
  websocketServices.reduxStore.default.dispatch(
    setWebcamInputValue({
      webcamId: webcamID,
      value: "",
    })
  );
  websocketServices.reduxStore.default.dispatch(
    setSelectedWebcam({
      webcamId: webcamID,
      value: null,
    })
  );
}
export function allowScreenShare(user, userIsInSlot) {
  if (userIsInSlot) {
  } else {
  }
}

export function muteUnmuteUserMic(user) {
  websocketServices.signals.muteUnmuteMicCallParticipant(user);
}
export function muteUnmuteUserCam(user) {
  websocketServices.signals.muteUnmuteCamCallParticipant(user);
}

// TODO: consult this as refference to implement dialog dispatch elsewhere
// export function setUserInWebcamSlot(slot, user, userIsInSlot) {
//   if (userIsInSlot) {
//     // Choisir Add ou Remove All
//     var buttons = [
//       {
//         title: i18nInstance.t("dialogRemoveFromCall|button1"),
//         user: user,
//         slot: slot,
//       },
//       {
//         title: i18nInstance.t("dialogRemoveFromCall|button2"),
//         user: user,
//         slot: slot,
//       },
//       { title: i18nInstance.t("dialogRemoveFromCall|button3") },
//     ];
//     var dialog = dialogStateFactory({
//       title: i18nInstance.t("dialogRemoveFromCall|title"),
//       message: i18nInstance.t("dialogRemoveFromCall|message"),
//       buttons: buttons,
//       event: "REMOVE_FROM_CALL",
//     });
//     websocketServices.reduxStore.default.dispatch(openDialog(dialog));
//   } else if (userIsInSlot === false) {
//     websocketServices.signals.addCallParticipant(user, slot);
//   } else {
//   }
// }

export function setUserInWebcamSlot(user, webcamSlot, timeSlot) {
    websocketServices.signals.addCallParticipant(user, webcamSlot, timeSlot);
}

export function removeUserFromCallWebcamSlot(webcamSlot, timeSlot) {
  websocketServices.signals.removeCallParticipant( webcamSlot, timeSlot);
}

export function removeCallTimeSlot(timeSlotID) {
  websocketServices.signals.removeTimeSlot(timeSlotID);
}
export function swapCallTimeSlot(fromID,ToId) {
  websocketServices.signals.swapTimeSlot(fromID,ToId);
}

export function setUserInScreenSlot(user) {
  websocketServices.signals.addCallScreenParticipant(user);
}
export function clearUserFromScreenSlot(user) {
  websocketServices.signals.clearFromCallScreenParticipant(user);
}

export function removeUserFromScreenSlotID(user, id) {
  //TODO manage ID and change in backend the behavior
  websocketServices.signals.removeCallScreenParticipant(user);
}

export function getModerationsStatus(user, moderations) {
  var moderationsStatus = {
    inModeration: false,
    id: "",
  };
  Object.keys(moderations).forEach(function (key, index) {
    // key: the name of the object key
    // index: the ordinal position of the key within the object
    if (
      moderations[key] !== null &&
      moderations[key] !== undefined &&
      moderations[key][user.userid] !== null &&
      moderations[key][user.userid] !== undefined
    ) {
      moderationsStatus.inModeration = true;
      moderationsStatus.id = key;
      moderationsStatus.name = moderations[key].name;
      moderationsStatus.started = moderations[key].started;
      return moderationsStatus;
    }
  });
  return moderationsStatus;
}

export function getIntercomStatus(user, intercomLine) {
  var userInIntercomSlot = false;
  if (intercomLine[user.userid] !== undefined) {
    userInIntercomSlot = true;
  }
  return userInIntercomSlot;
}

export function getWebcamStatus(user, onAirWebcam) {
  var userInSlot = false;
  for (var x = 0; x < onAirWebcam.length; x++) {
    if (user.userid === onAirWebcam[x].userid) {
      userInSlot = true;
      break;
    }
  }
  return userInSlot;
}

export function getWebcamOnAirStatus(user, onAir, isOnAir) {
  return (
    isOnAir &&
    !!Object.values(onAir)
      .map((it) => it[0])
      .filter((it) => it !== undefined)
      .find((it) => it.userid === user.userid)
  );
}

export function setUserCamera(selectedCamId, videoDevices, toUser) {
  if (videoDevices.length === 2) {
    const data = {
      command: websocketServices.DEVICES_COMMANDS.CHANGE_CAMERA,
      camId: videoDevices.find((it) => it.id !== selectedCamId).id,
    };
    websocketServices.signals.sendDevicesCommand(
      data,
      toUser.details.connectionId
    );
  } else if (videoDevices.length > 2) {
    let dialog = dialogStateFactory({
      title: i18nInstance.t("dialogChoseUserCam|title"),
      message: i18nInstance.t("dialogChoseUserCam|message"),
      event: "CHOSE_USER_CAM",
      selectedCamId,
      camDevices: videoDevices,
      user: toUser,
      closeButton: {
        title: i18nInstance.t("dialogRemoveFromCall|button3"),
      },
    });
    websocketServices.reduxStore.default.dispatch(openDialog(dialog));
  }
}

export function setUserScreenShare(toUser) {
  let dialogState = dialogStateFactory({
    title: i18nInstance.t("dialogUserScreenShare|title"),
    message: i18nInstance.t("dialogUserScreenShare|message"),
    event: "SCREEN_SHARE",
    user: toUser,
    closeButton: {
      title: i18nInstance.t("dialogRemoveFromCall|button3"),
    },
  });

  websocketServices.reduxStore.default.dispatch(openDialog(dialogState));
}

export function setUserMicrophone(selectedMicId, audioDevices, toUser) {
  if (audioDevices.length === 2) {
    const data = {
      command: websocketServices.DEVICES_COMMANDS.CHANGE_MICROPHONE,
      micId: audioDevices.find((it) => it.id !== selectedMicId).id,
    };
    websocketServices.signals.sendDevicesCommand(
      data,
      toUser.details.connectionId
    );
  } else if (audioDevices.length > 2) {
    let dialog = dialogStateFactory({
      title: i18nInstance.t("dialogChoseUserMic|title"),
      message: i18nInstance.t("dialogChoseUserMic|message"),
      event: "CHOSE_USER_MIC",
      selectedMicId,
      micDevices: audioDevices,
      user: toUser,
      closeButton: {
        title: i18nInstance.t("dialogRemoveFromCall|button3"),
      },
    });
    websocketServices.reduxStore.default.dispatch(openDialog(dialog));
  }
}

export function toggleUserCamera(toUser) {
  const data = {
    command: websocketServices.DEVICES_COMMANDS.TOGGLE_CAMERA,
  };
  websocketServices.signals.sendDevicesCommand(data, toUser);
}

export function setUserResolution(resolution, toUser) {
  const data = {
    command: websocketServices.DEVICES_COMMANDS.CHANGE_RESOLUTION,
    resolution,
  };
  websocketServices.signals.sendDevicesCommand(data, toUser);
}
export function setUserScreenResolution(resolution, toUser) {
  const data = {
    command: websocketServices.DEVICES_COMMANDS.CHANGE_SCREEN_RESOLUTION,
    resolution,
  };
  websocketServices.signals.sendDevicesCommand(data, toUser);
}

export function setUserAllowScreenShare(allowed, toUser) {
  const data = {
    command: websocketServices.DEVICES_COMMANDS.ALLOW_SCREEN_SHARE,
    allowed,
  };
  websocketServices.signals.sendDevicesCommand(data, toUser);
}

export function addUserToPartyLineWhisper(moderatorid, userid) {
  websocketServices.signals.addUserToPartyLineWhisper(moderatorid, userid);
}

export function removeUserFromPartyLineWhisper(moderatorid, userid) {
  websocketServices.signals.removeUserFromPartyLineWhisper(moderatorid, userid);
}

export function togglePartyLineActive(currentActive) {
  websocketServices.signals.togglePartyLineActive(currentActive);
}

export function togglePartyLinePTT(currentPTT) {
  websocketServices.signals.togglePartyLinePTT(currentPTT);
}

export function addUserToControlRoomWhisper(moderatorid, userid) {
  websocketServices.signals.addUserToControlRoomWhisper(moderatorid, userid);
}

export function removeUserFromControlRoomWhisper(moderatorid, userid) {
  websocketServices.signals.removeUserFromControlRoomWhisper(moderatorid, userid);
}

export function toggleControlRoomActive(currentActive) {
  websocketServices.signals.toggleControlRoomActive(currentActive);
}

export function toggleControlRoomPTT(currentPTT) {
  websocketServices.signals.toggleControlRoomPTT(currentPTT);
}

export function addUserToDirectorWhisper(moderatorid, userid) {
  websocketServices.signals.addUserToDirectorWhisper(moderatorid, userid);
}

export function removeUserFromDirectorWhisper(moderatorid, userid) {
  websocketServices.signals.removeUserFromDirectorWhisper(moderatorid, userid);
}

export function toggleDirectorActive(currentActive) {
  websocketServices.signals.toggleDirectorActive(currentActive);
}

export function toggleDirectorPTT(currentPTT) {
  websocketServices.signals.toggleDirectorPTT(currentPTT);
}

//Local Functions
