import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
const styledBy = (property, mapping) => (props) => mapping[props[property]];
//Get the primary and secondary style from the theme palette style

function primary(theme) {

  if (theme.palette.type === 'dark') {
    return theme.palette.primary.main
  } else {
    return theme.palette.primary.light
  }

}
function secondary(theme) {
  if (theme.palette.type === 'dark') {
    return theme.palette.secondary.main
  } else {
    return theme.palette.secondary.light
  }
}
function defaultBackground(theme) {
  
    return theme.palette.commandButtonBackgroundColor.main

  
}
export const CommandsButton = withStyles((theme) => ({
  root: {
    background: styledBy("color", {
      default: defaultBackground(theme),
      primary: defaultBackground(theme),
      secondary: defaultBackground(theme),
    }),
    borderRadius: 15,
    border: '1px',
    borderStyle: 'solid',
    fontSize: 8,
    borderColor: theme.palette.commandButtonBorderColor.main,
    color: styledBy("color", {
      default: primary(theme),
      primary: primary(theme),
      secondary: secondary(theme),
    }),
  },
}))(({ classes, color, ...other }) => (
  <Button className={classes.root} {...other} />
));
