import { Paper, Grid, makeStyles, Box, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import UserAvatar from "../UserAvatar";
import {
  PARTICIPANTS as reflexConsts_PARTICIPANTS,
  NOT_CONNECTED as reflexConsts_NOT_CONNECTED,
  CALL_MODERATORS as reflexConsts_CALL_MODERATORS,
  MODERATED as reflexConsts_MODERATED,
  CONTRIBUTORS as reflexConsts_CONTRIBUTORS,
  PARTICIPANTS_INPUT_VALUE as reflexConsts_PARTICIPANTS_INPUT_VALUE,
  NOT_CONNECTED_INPUT_VALUE as reflexConsts_NOT_CONNECTED_INPUT_VALUE,
  CONTRIBUTORS_INPUT_VALUE as reflexConsts_CONTRIBUTORS_INPUT_VALUE,
  MODERATED_INPUT_VALUE as reflexConsts_MODERATED_INPUT_VALUE,
  CALL_MODERATORS_INPUT_VALUE as reflexConsts_CALL_MODERATORS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import {
  selectedListTypes,
  translateToInputValueType,
  translateToUserType,
  selectInputValue,
  selectList,
} from "./commons";
import { UserItem } from "./UserItem";
import {
  setSelectedList,
  setSelectedUser,
  setUserInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((props, theme) => ({
  root: {
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      17,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      17,
    overflow: "hidden",
    marginLeft: "7px",
    marginRight: "7px",
    borderRadius: "5px"
  },
  scrollContainer: {
    // display: "flex",
    overflowX: "hidden",
    overflowY: "scroll",
    margin: "10px",
    paddingTop: "10px",
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      parseInt(
        document.getElementById("userSelectionFilterContainer")?.clientHeight
      ) - 25,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      parseInt(
        document.getElementById("userSelectionFilterContainer")?.clientHeight
      ) - 25,
    // height: "100%",
    // minHeight: "100%"
  },
  filterContainer: {
    // display: "flex",
    // flexGrow: 1
    padding: "10px",
  },
  searchField: {
    fontSize: "5vw",
  },
}));

export function UserSelection(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const selectedList = useSelector(selectList);
  const list = useSelector((state) => {
    return selectedList === null
      ? []
      : selectedList !== selectedListTypes.MODERATED
      ? Object.values(state.websocket[selectedList])
      : Object.values(state.websocket.intercom.moderated);
  });
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const childHandler = (user) => {
    const selectedUserPayload = {
      userType: translateToUserType(selectedList),
      value: user,
    };

    const inputValuePayload = {
      value: user.username,
      type: translateToInputValueType(selectedList),
    };

    dispatch(setSelectedUser(selectedUserPayload));
    dispatch(setUserInputValue(inputValuePayload));
    dispatch(setSelectedList(null));
  };

  const searchHandler = (event) => setSearch(event.target.value);

  return (
    <Paper elevation={0} square className={classes.root}>
      <Box
        id="userSelectionFilterContainer"
        className={classes.filterContainer}
      >
        <TextField
          label={t("global|search")}
          inputProps={{ style: { fontSize: "0.75vw" } }} // font size of input text
          InputLabelProps={{ style: { fontSize: "0.75vw" } }}
          className={classes.searchField}
          type="search"
          variant="outlined"
          onChange={searchHandler}
        />
      </Box>
      <Box className={classes.scrollContainer}>
        <Grid
          container
          xs={12}
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {list
            .filter((user) =>
              user.username.toLowerCase().includes(search.toLowerCase())
            )
            .sort()
            .map((user) => (
              <Grid item xs={3}>
                <UserItem
                  isMod={selectedList === selectedListTypes.CALL_MODERATORS}
                  user={user}
                  onClick={childHandler}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Paper>
  );
}
