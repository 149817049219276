import React from "react";
import { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import ModalDialog from "./components/contributorLogin/ModalDialog";
import FooterAppBar from "./FooterAppBar";
import logo from "./logo-greenroom.svg";
import { css } from "@emotion/react";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import i18nInstance from "./i18n";
class LoginWebcamContributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      errorMessage: "",
      redirectURL: false,
      redirectTo: false,
    };
    this.browserUtil = new BrowserUtils();
  }
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    const overrideHidden = css`
      display: none;
    `;
    const passwordProtected =
      this.browserUtil.findGetParameter("passwordProtected") === "true";
    const handleClose = (e) => {
      this.setState({ open: false });
    };
    const login = (username, pass) => {
      //Login procedure
      // this.browserUtil.findGetParameter("isPublic");

      var data = {
        username: username,
        password: pass,
        broadcast: this.browserUtil.findGetParameter("broadcast"),
        broadcaster: this.browserUtil.findGetParameter("broadcaster"),
        tokenPassword: this.browserUtil.findGetParameter("token"),
        passwordProtected:
          this.browserUtil.findGetParameter("passwordProtected") === "true",
      };
      const setState = (state) => {
        this.setState(state);
      };
      fetch("https://api.reflexinteractions.com/engineV5/join/webcamLogin", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
      })
        .then(function (response) {
          if (response.status === 200) {
            response.json().then(function (res) {
              if (res.success) {
                setState({ redirectURL: res.redirectURL });
              } else {
                renderErrorMessage(res);
                setState({ open: true });
              }
            });
          } else {
            renderErrorMessage(res);
            setState({ open: true });
          }
        })
        .catch(function (error) {
          renderErrorMessage(res);
          setState({ open: true });
        });
    };
    const renderErrorMessage = (error) => {
      switch (error.message) {
        case "UNDEFINED_ERROR":
          this.setState({
            errorMessage: i18nInstance.t("LoginContributor|UNDEFINED_ERROR"),
          });
          console.error(error);
          break;
        case "TOKEN_CREDENTIALS":
          this.setState({
            errorMessage: i18nInstance.t("LoginContributor|TOKEN_CREDENTIALS"),
          });
          break;
        case "TOKEN_EXPIRED":
          this.setState({
            errorMessage: i18nInstance.t("LoginContributor|TOKEN_EXPIRED"),
          });
          break;
        case "TOKEN_ERROR":
          this.setState({
            errorMessage: i18nInstance.t("LoginContributor|TOKEN_ERROR"),
          });
          break;
        default:
          this.setState({
            errorMessage: i18nInstance.t("LoginContributor|DEFAULT_ERROR"),
          });
          console.error(error);
          break;
      }
    };

    if (this.state.redirectURL !== false) {
      window.location.assign(this.state.redirectURL);
    }
    return (
      <header className="App-header-login">
        <img src={logo} className="App-logo" alt="logo" />
        <h3 style={{ display: this.state.open ? "none" : "flex" }}>
          <Trans>global|waiting login</Trans>
        </h3>
        <PulseLoader
          color="#ff2900"
          loading="true"
          css={this.state.open ? overrideHidden : override}
          size={35}
        />
        <ModalDialog
          open={this.state.open}
          handleClose={handleClose}
          login={login}
          errorMessage={this.state.errorMessage}
          passwordProtected={passwordProtected}
        />
        <div>
          <FooterAppBar />
        </div>
      </header>
    );
  }
}

export default withTranslation()(LoginWebcamContributor);
