import React, { Component } from 'react';
import './StreamComponent.css';

export default class OvVideoComponent extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        if (this.props && this.props.user.streamManager && !!this.videoRef) {
            this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.user.streamManager && !!this.videoRef) {
            this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
        }
    }

    render() {
        return (
            <video
                autoPlay={true}
                id={'video-' + this.props.user.getStreamManager().stream.streamId} // TODO: this should not return undefined
                ref={this.videoRef}
                muted={this.props.mutedSound}
                className={this.props.overrideClass}
            />
        );
    }
}

// import { Box, makeStyles } from '@material-ui/core';
// import React, { Component, useEffect, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { filters } from '../../utils/tensorflow/filters';
// import { useBodyPix } from '../../utils/tensorflow/useBodyPix';
// import './StreamComponent.css';

// const useStyles = makeStyles((theme) => ({
//     hiddenCanvas: {
//         display: "none",
//         position: "absolute",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         width: "100%",
//         height: "100%",
//       },
//       filteredCanvas: {
//         display: "block",
//         position: "absolute",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         width: "100%",
//       },
//       filteredCanvas4x3: {
//         display: "block",
//         position: "absolute",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         height: "100%",
//       },

// }))

// export default function OvVideoComponent({ user, mutedSound }) {
//     const classes = useStyles()
//     const videoRef = useRef(null)
//     const canvasRef = useRef(null)

//     const filter = useSelector((state) => state.ui.selectedFilters);
//     const bodypix = useBodyPix();
//     const [videoReady, setVideoReady] = useState(false)
//     const [filterReady, setFilterReady] = useState(false)

//     useEffect(() => {

//         if (user.isLocal()) {
//             videoRef.current.addEventListener("loadeddata", e => {
//                 if (!videoReady) {
//                     setVideoReady(true)
//                 }
//             })
//         }
        
//     }, [])

//     // useEffect(() => {
//     //     const isFilterInUse = !!filter
//     //     if (isFilterInUse && canvasRef.current) {
//     //         const filteredCameraStream = canvasRef.current.captureStream()
//     //         const videoTrack = filteredCameraStream.getVideoTracks()[0]
//     //         user.getStreamManager().replaceTrack(videoTrack)
//     //             .then(() => console.log("successfully pushed the filtered stream"))
//     //             .catch(error => console.error('Error pushing filtered stream', error));
//     //     }
//     // }, [filterReady])

//     // useEffect(() => {
 
//     //     if (user.isLocal()) {
//     //         console.log("######################################### tensor flow process user.getResolution(): ", user.getResolution())
  
//     //         canvasRef.current.width = videoRef.current.width = videoRef.current.videoWidth;
//     //         canvasRef.current.height = videoRef.current.height = videoRef.current.videoHeight;

//     //         let continueToNextFrame = true;
//     //         async function renderLoop() {
//     //             if (canvasIsVisible) {
//     //                 filters[filter](
//     //                     canvasRef.current,
//     //                     videoRef.current,
//     //                     bodypix,
//     //                     () => {
//     //                         if (!filterReady) {
//     //                             setFilterReady(true)
//     //                         }
//     //                     },
//     //                     user.getResolution(),
//     //                 )
//     //             }
//     //             if (continueToNextFrame && filterReady) {
//     //                 requestAnimationFrame(renderLoop);
//     //             }
//     //         }
//     //         if (filter && videoReady) {
//     //             requestAnimationFrame(renderLoop);
//     //         }

//     //         return () => {
//     //             continueToNextFrame = false;
//     //         };
//     //     } 

//     // }, [filter, bodypix, videoReady, filterReady, videoRef.current, canvasRef.current]);

//     // const canvasIsVisible = filter && bodypix




//     useEffect(() => {
//         if (user.getStreamManager?.session && videoRef.current) {
//             user.getStreamManager().session.on("signal:userChanged", event => {
//                 const data = JSON.parse(event.data);
//                 if (data.isScreenShareActive) {
//                     user.getStreamManager().addVideoElement(videoRef.current);
//                 }
//             })
//         }
//     }, [])

//     useEffect(() => {
//         if (user.getStreamManager && videoRef.current) {
//             user.getStreamManager().addVideoElement(videoRef.current)
//         }
//     }, [videoRef.current])

//     let aspectRatio = "4:3";

//     switch (user.getResolution()) {
//         case "FHD":
//         case "HD":
//         case "VGA16x9":
//         aspectRatio = "16:9";
//         break;
//         case "VGA":
//         case "QVGA":
//         aspectRatio = "4:3";
//         break;
//     }

//     return  (
//         <video
//         ref={videoRef}
//             autoPlay={true}
//             id={'video-' + user.getStreamManager().stream.streamId} // TODO: this should not return undefined
//             muted={mutedSound}
//         />
//     )
// }
