import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import reduxStore from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import reportWebVitals from "./reportWebVitals";
import {darkTheme} from "./themes/defaultDarktheme"
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { BorderAllRounded } from "@material-ui/icons";
import "@tensorflow/tfjs";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
//Theming
// https://material-ui.com/customization/theming/

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <I18nextProvider  i18n={i18n}>
        <MuiThemeProvider theme={darkTheme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
registerServiceWorker();
reportWebVitals();
