import { AppBar, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import packageJson from '../package.json';
const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        fontSize: 10,
        margin: 5,
    color: theme.palette.primary,
    backgroundColor: theme.palette.darkbackground.dark,
    },
});

class FooterAppBar extends Component {
    
    render() {
        const { classes } = this.props;
        const year = new Date().getFullYear()
        return (
            <AppBar elevation={0} position="fixed" className={classes.appBar}>
                <Box className='App-Footer' Component='span'  color={'primary'}  >
                <Trans>footerAppBar|Currently using React</Trans> {React.version} <Trans>footerAppBar|and WebRTC Server</Trans> <Trans>version</Trans>{" | "} <Trans>playerVersion</Trans>  { packageJson.version} | Copyright &copy; {year} 9337-9899 Quebec inc.
                </Box>
                
            </AppBar>
        );
    }
}

export default withTranslation()(withStyles(styles)(FooterAppBar));
// export default withTranslation()(FooterAppBar);
// export default withStyles(styles)(FooterAppBar);