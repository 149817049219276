import React from 'react';
// import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import logo from './logo.svg';
import FooterAppBar from './FooterAppBar';

const NotFound = () => (
  <div>
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>404 - <Trans>global|Not Found!</Trans></h1>
        <a
          className="App-link"
          href="/"
        >
         <Trans>global|Go to Reflex WebRTC Home</Trans> 
        </a>
        <FooterAppBar />
      </header> 
  </div>
);

export default withTranslation() (NotFound);