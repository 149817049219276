import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  title: {
    fontSize: "0.5em",
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
  },
  buttonClass: {
    margin: "5px",
  },
}));

export default function DeleteInvitation(props) {
  const { t, i18next } = useTranslation();
  const classes = useStyles();

  let name = "";
  if (
    props.contributorTokenToWorkWith !== undefined &&
    props.contributorTokenToWorkWith.token !== undefined
  ) {
    name = props.contributorTokenToWorkWith.token.name;
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={t("ContributorsComponent|DeleteInvitation")}
        subheader={name}
      />
      <CardContent></CardContent>
      <CardActions className={classes.buttonContainer}>
        <Box component="div" className={classes.buttonContainer}>
          <Button
            className={classes.buttonClass}
            onClick={() => props.deleteToken(props.contributorTokenToWorkWith)}
          >
            {" "}
            {t("ContributorsComponent|Delete")}
          </Button>
          <Button onClick={props.openList} className={classes.buttonClass}>
            {" "}
            {t("ContributorsComponent|Cancel")}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
