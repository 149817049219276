import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { openDialog } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { useTranslation } from "react-i18next";
import {
  FHdButton,
  FourByThreeButton,
  HdButton,
  HqButton,
  LowResButton,
} from "../buttons/commonButtons";
import {
  clearUserFromScreenSlot,
  getUserAvatar,
  getUserDetails,
  getUserStatus,
  muteUnmuteUserCam,
  setUserInScreenSlot,
  setUserResolution,
  setUserScreenResolution,
} from "../../utils/callControllerUtils";
import { useSelector } from "react-redux";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  dialogTitleContainer: {
    display: "flex",
    fontSize: "1.0vw",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },
  dialogTitle: {
    color: theme.palette.reflexYellow.main,
    backgroundColor: theme.palette.darkbackground.main,
  },
  cancelButton: {
    borderRadius: "20px 20px 20px 20px",
    backgroundColor: theme.palette.grey5.main,
    fontSize: "1.5vw"
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    marginRight: "10px",
    width: "3vw",
    height: "3vw",
  },
  groupTitle: {
    fontWeight: "bold",
    color: theme.palette.grey8.main,
    textTransform: "uppercase",
    fontSize: "0.9vw",
  },
  infoIcon: {
    color: theme.palette.reflexYellow.main,
    marginBottom: "-7px",
    "& svg": {
      fontSize: "1.3vw",
    },
  },
  cameraInfo: {
    color: theme.palette.reflexYellow.main,
    fontSize: "0.6vw",
  },
  allowButton: {
    margin: "10px",
    fontSize: "0.55vw",
  },
  buttonClass: {
    "& svg": {
      fontSize: "1.3vw",
    },
  },
}));

export default function ChoseScreenShare(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const participants = useSelector((state) => state.websocket.participants);
  const moderated = useSelector((state) => state.websocket.intercom.moderated);
  const intercom = useSelector((state) => state.websocket.intercom);
  const onAir = useSelector((state) => state.websocket.call.onAir);
  const isOnAir = useSelector((state) => state.websocket.call.isOnAir);
  const screenSharingSlot = useSelector(
    (state) => state.websocket.call.onAir[100]
  );
  const [muteCamColor, setMuteCamColor] = useState('primary')
  const [user, setUser] = useState({
    username: "",
    avatar: "",
    userid: "",
    details: {},
    isScreenShareAllowed: false,
  });
  const allowed = Object.values(screenSharingSlot).find(
    (it) => it.userid === user.userid
  );
  useEffect(() => {
    if (!_.isEmpty(props.dialog.user)) {
      if (props.dialog.user.details.connectionId in participants) {
        setUser(getUserDetails(Object.values(participants), props.dialog.user));
      } else if (props.dialog.user.userid in moderated) {
        setUser(getUserDetails(Object.values(moderated), props.dialog.user));
      }
    }
  }, [props.open, participants, moderated]);

  const handleClose = () => {
    // export this as a reusable object (factory function?)
    var dialog = props.defaultDialog;
    props.dispatch(openDialog(dialog));
  };

  const handleAllowScreenShare = () => {
    //TODO check first if it's mobile. If yes it is not supported

    if (!allowed) {
      handleAddToScreenShare();
    } else {
      const userTosend = {
        userid: user.userid,
        username: user.username,
      };
      clearUserFromScreenSlot(userTosend);
    }
  };

  const handleAddToScreenShare = () => {
    const userTosend = {
      userid: user.userid,
      username: user.username,
    };
    setUserInScreenSlot(userTosend);
  };

  let avatar = "";
  let username = "";
  if (props.dialog.user !== null) {
    username = props.dialog.user.username;
  }
  const stringAvatar = () => {
    if (props.dialog.user !== null && props.dialog.user !== undefined)
      return props.dialog.user.username.charAt(0).toUpperCase();
    else return "";
  };

  let resolutionButtonColor = "VGA";
  if (
    [undefined, null].every((it) => it !== user) &&
    [undefined, null].every((it) => user.resolution !== it)
  ) {
    resolutionButtonColor = user.resolution;
  }
  let resolutionScreenButtonColor = "VGA16x9";
  if (
    [undefined, null].every((it) => it !== user) &&
    [undefined, null].every((it) => user.screenConstraint !== it)
  ) {
    if (user.screenConstraint.width === 1920) {
      resolutionScreenButtonColor = "FHD";
    } else if (user.screenConstraint.width === 1280) {
      resolutionScreenButtonColor = "HD";
    } else if (user.screenConstraint.width === 640) {
      resolutionScreenButtonColor = "VGA16x9";
    } else {
      resolutionScreenButtonColor = "";
    }
  }

 let userStatus = undefined;
  if (user.userid !== "") {
    userStatus = getUserStatus(
      user,
      onAir,
      intercom,
      moderated,
      isOnAir,
      false
    );
    if (userStatus !== undefined) {
        if (userStatus.isVideoActive === false) { // if it's undefined, it should be primary
          if(muteCamColor !== 'secondary') {
            setMuteCamColor('secondary')
          }
        } else {
          if (muteCamColor !== "primary") {
            setMuteCamColor("primary")
          }   
        }
    }
  }

  const screenShareIsActive = !(userStatus && userStatus.isScreenShareActive)

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={handleClose}
      open={props.open}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogTitleContainer}>
          <Avatar aria-label="avatar" className={classes.avatar} src={getUserAvatar(props.dialog?.user?.userid)}>
            { stringAvatar()}
          </Avatar>
          {props.dialog.title + username + props.dialog.message}
        </Box>
      </DialogTitle>
      <Box style={{ margin: "10px" }}>
        <Grid
          container
          xs={12}
          direction="column"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item xs={12} spacing={2}>
            <Box
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Typography className={classes.groupTitle}>
                {t("dialogUserScreenShare|allowGroup|title")}
              </Typography>
              <Grid container xs={12}>
 
                <Grid item xs={12} justifyContent="center">
                  <Button
                    color={muteCamColor}
                    onClick={() => muteUnmuteUserCam(user)}
                    className={classes.allowButton}
                    disabled={screenShareIsActive}
                  >
                    {t(
                      `dialogUserScreenShare|allowGroup|${
                        muteCamColor === "primary" ? "deactivateCamera" : "activateCamera"
                      }`
                    )}
                  </Button>
                  <Typography className={classes.cameraInfo}>
                    <InfoIcon className={classes.infoIcon} />{" "}
                    {t("dialogUserScreenShare|allowGroup|info")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider
              width="80%"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Box
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Typography className={classes.groupTitle}>
                {t("dialogUserScreenShare|cameraResolutionGroup|title")}
              </Typography>
              <ButtonGroup disabled={screenShareIsActive}>
                <FHdButton
                  onClick={() =>
                    setUserResolution("FHD", user.details.connectionId)
                  }
                  color={
                    resolutionButtonColor === "FHD" ? "secondary" : "primary"
                  }
                  disabled={!user.online}
                  className={classes.buttonClass}
                />
                <HdButton
                  onClick={() =>
                    setUserResolution("HD", user.details.connectionId)
                  }
                  color={
                    resolutionButtonColor === "HD" ? "secondary" : "primary"
                  }
                  disabled={!user.online}
                  className={classes.buttonClass}
                />
                <HqButton
                  onClick={() =>
                    setUserResolution("VGA16x9", user.details.connectionId)
                  }
                  color={
                    resolutionButtonColor === "VGA16x9"
                      ? "secondary"
                      : "primary"
                  }
                  disabled={!user.online}
                  className={classes.buttonClass}
                />
                <FourByThreeButton
                  onClick={() =>
                    setUserResolution("VGA", user.details.connectionId)
                  }
                  color={
                    resolutionButtonColor === "VGA" ? "secondary" : "primary"
                  }
                  disabled={!user.online}
                  className={classes.buttonClass}
                />
                <LowResButton
                  onClick={() =>
                    setUserResolution("QVGA", user.details.connectionId)
                  }
                  color={
                    resolutionButtonColor === "QVGA" ? "secondary" : "primary"
                  }
                  disabled={!user.online}
                  className={classes.buttonClass}
                />
              </ButtonGroup>
              <Divider
                width="80%"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ width: "100%", textAlign: "center" }}>
              <Typography className={classes.groupTitle}>
                {t("dialogUserScreenShare|screenResolutionGroup|title")}
              </Typography>
              <ButtonGroup disabled={screenShareIsActive}>
                <FHdButton
                  onClick={() =>
                    setUserScreenResolution("FHD", user.details.connectionId)
                  }
                  color={
                    resolutionScreenButtonColor === "FHD"
                      ? "secondary"
                      : "primary"
                  }
                  className={classes.buttonClass}
                />
                <HdButton
                  onClick={() =>
                    setUserScreenResolution("HD", user.details.connectionId)
                  }
                  color={
                    resolutionScreenButtonColor === "HD"
                      ? "secondary"
                      : "primary"
                  }
                  className={classes.buttonClass}
                />
                <HqButton
                  onClick={() =>
                    setUserScreenResolution(
                      "VGA16x9",
                      user.details.connectionId
                    )
                  }
                  color={
                    resolutionScreenButtonColor === "VGA16x9"
                      ? "secondary"
                      : "primary"
                  }
                  className={classes.buttonClass}
                />
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.buttonContainer}>
        <IconButton className={classes.cancelButton} onClick={handleClose}>
          {props.dialog.closeButton.title}
        </IconButton>
      </Box>
    </Dialog>
  );
}

/*
[i][title    ][x]
[Group title]
[allow] [replace]
[Group title]
[camera res     ]
[Group title]
[screen res     ]
*/
