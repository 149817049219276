import React from "react";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import i18nInstance from "../../../../i18n";
import { Box, Grid, Button } from "@material-ui/core/";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import WebcamContributorToken from "./WebcamContributorToken";
import ContributorTokenCreator from "./ContributorTokenCreator";
import SendEmailToContributor from "./SendEmailToContributor";
import DeleteInvitation from "./DeleteInvitation";
import { store } from "react-notifications-component";


const styles = (theme) => ({ 
  listContainer : {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "65vh"
  }
})
class Contributors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webcamContributorTokens: [],
      listOpened: true,
      creatorOpened: false,
      deleteConfirmationOpened: false,
      sendEmailOpened: false,
      contributorTokenToWorkWith: {},
      // contributorEmailSent: {}
    };
    this.openCreator = this.openCreator.bind(this);
    this.openDeleteConfirmation = this.openDeleteConfirmation.bind(this);
    this.openSendEmail = this.openSendEmail.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.openList = this.openList.bind(this);
    this.createToken = this.createToken.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
  }

  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (
      prevProps.webcamContributorTokens !== this.props.webcamContributorTokens
    ) {
      state.webcamContributorTokens = this.props.webcamContributorTokens;
    }
    if (
      prevProps.contributorEmailSent !== this.props.contributorEmailSent
    ) {
      
    if (Object.keys(this.props.contributorEmailSent).length > 0) {
      store.addNotification({
        title: i18nInstance.t("ContributorsComponent|success"),
        message: i18nInstance.t("ContributorsComponent|SentEmailSuccess") + this.props.contributorEmailSent.emailUsername,
        type: "success",
        insert: "center",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  openCreator(event) {
    const state = {
      listOpened: false,
      creatorOpened: true,
      deleteConfirmationOpened: false,
      sendEmailOpened: false,
    };
    this.setState(state);
  }
  openDeleteConfirmation(contributorToken) {
    const state = {
      listOpened: false,
      creatorOpened: false,
      deleteConfirmationOpened: true,
      sendEmailOpened: false,
      contributorTokenToWorkWith: contributorToken,
    };
    this.setState(state);
  }
  openSendEmail(contributorToken) {
    const state = {
      listOpened: false,
      creatorOpened: false,
      deleteConfirmationOpened: false,
      sendEmailOpened: true,
      contributorTokenToWorkWith: contributorToken,
    };
    this.setState(state);
  }
  openList(event) {
    const state = {
      listOpened: true,
      creatorOpened: false,
      deleteConfirmationOpened: false,
      sendEmailOpened: false,
    };
    this.setState(state);
  }
  createToken(iMessage) {
    websocketServices.signals.createToken(
      iMessage,
      this.props.localUser.getConnectionId()
    );
  }
  sendEmail(iMessage) {
    websocketServices.signals.sendEmail(
      iMessage,
      this.props.localUser.getConnectionId()
    );
    this.openList();
  }
  deleteToken(iMessage) {
    let message = {
      id: "webcamcall",
      type: "deleteToken",
      token: iMessage.token,
      broadcastid: this.props.localUser.getBroadcastId(),
      broadcaster: this.props.localUser.getBroadcaster(),
    };
    websocketServices.signals.deleteToken(
      message,
      this.props.localUser.getConnectionId()
    );
    this.openList();
  }
  copyLink(contributorToken) {}
  render() {
    const { classes, theme } = this.props;
    return (
      <Box>
        <Box
          style={{
            margin: "10px",
            display: this.state.listOpened ? "block" : "none",
          }}
        >
          <Button onClick={this.openCreator}>
            <Trans>ContributorsComponent|CreateToken</Trans>
          </Button>
        </Box>
        <Box style={{ display: this.state.listOpened ? "block" : "none" }} className={classes.listContainer}>
          {/* <Grid container> */}
            {this.state.webcamContributorTokens.map(
              (contributorToken, index) => (
                <WebcamContributorToken
                  key={index.toString()}
                  contributorToken={contributorToken}
                  openSendEmail={this.openSendEmail}
                  openDeleteConfirmation={this.openDeleteConfirmation}
                  copyLink={this.copyLink}
                />
              )
            )}
          {/* </Grid> */}
        </Box>
        <Box
          style={{
            margin: "10px",
            display: this.state.creatorOpened ? "block" : "none",
          }}
        >
          <ContributorTokenCreator
            localUser={this.props.localUser}
            createToken={this.createToken}
            openSendEmail={this.openSendEmail}
            openList={this.openList}
          />
        </Box>
        <Box
          style={{
            margin: "10px",
            display: this.state.sendEmailOpened ? "block" : "none",
          }}
        >
          <SendEmailToContributor
            localUser={this.props.localUser}
            sendEmail={this.sendEmail}
            contributorTokenToWorkWith={this.state.contributorTokenToWorkWith}
            openList={this.openList}
          />
        </Box>
        <Box
          style={{
            margin: "10px",
            display: this.state.deleteConfirmationOpened ? "block" : "none",
          }}
        >
          <DeleteInvitation
            localUser={this.props.localUser}
            deleteToken={this.deleteToken}
            contributorTokenToWorkWith={this.state.contributorTokenToWorkWith}
            openList={this.openList}
          />
        </Box>
      </Box>
    );
  }
}
const mapStateToProps = (state) => ({
  webcamContributorTokens: state.websocket.webcamContributorTokens,
  contributorEmailSent: state.websocket.contributorEmailSent
});

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(Contributors)));
