import React from 'react';
import { Component } from 'react';
import logo from './logo.svg';
import { withTranslation, Trans } from 'react-i18next';
import FooterAppBar from './FooterAppBar';

class Homepage extends Component {

  componentDidUpdate(prevProps) {
  }

  render() {
    return (<header className="App-header">
      <img src={logo} className="App-logo" alt="logo reflex interactions" />
      <p>
        Client V5 (Alpha)
        </p>
      <a
        className="App-link"
        href="https://reflexinteractions.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>homepage|More about us</Trans>
      </a><div>
        <FooterAppBar />
      </div>
    </header>);
  }
}

export default withTranslation()(Homepage);