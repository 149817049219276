import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  Button,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core/";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import WebcamSelectComponent from "./WebcamSelectorComponent";
import {CommandsButton} from "../../../../commons/CommandsButton"
import { BorderAllRounded } from "@material-ui/icons";
import { StyledTypography } from "../../../../commons/StyledTypography";
class IntercomCommandComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      webcamSelectIsOpen: false,
    };
    this.addModerationCall = this.addModerationCall.bind(this);
    this.addToControlRoom = this.addToControlRoom.bind(this);
    this.addToDirector = this.addToDirector.bind(this);
    this.addToModerationCall = this.addToModerationCall.bind(this);
    this.addToPartyLine = this.addToPartyLine.bind(this);
    this.clearControlRoom = this.clearControlRoom.bind(this);
    this.clearDirector = this.clearDirector.bind(this);
    this.clearModerationCalls = this.clearModerationCalls.bind(this);
    this.clearPartyLine = this.clearPartyLine.bind(this);
    this.removeFromControlRoom = this.removeFromControlRoom.bind(this);
    this.removeFromDirector = this.removeFromDirector.bind(this);
    this.removeFromModerationCall =this.removeFromModerationCall.bind(this);
    this.removeFromPartyLine = this.removeFromPartyLine.bind(this);
    this.removeModerationCall =this.removeModerationCall.bind(this);

  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  addToPartyLine() {
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    websocketServices.signals.addUserToPartyLine(user);
  }
  removeFromPartyLine() {
    var userid;
    websocketServices.signals.removeUserFromPartyLine(userid);
  }
  clearPartyLine() {
    websocketServices.signals.clearPartyLine();
  }
  addToControlRoom() {
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    websocketServices.signals.addUserToControlRoom(user);
  }
  removeFromControlRoom() {
    var userid;
    websocketServices.signals.removeUserFromControlRoom(userid);
  }
  clearControlRoom() {
    websocketServices.signals.clearControlRoom();
  }
  addToDirector() {
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    websocketServices.signals.addUserToDirector(user);
  }
  removeFromDirector() {
    var userid;
    websocketServices.signals.removeUserFromDirector(userid);
  }
  clearDirector() {
    websocketServices.signals.clearDirector();
  }
  addModerationCall() {
    var moderation;
    //     moderation: {fdftfre-dfs-5435-fsd-dsf:{userid:64732647', username:'Carmen Santiago', avatar:'base64 encoded image'},543542687:{userid:543542687', username:'Peter Sellers', avatar:'base64 encoded image'}}},
    websocketServices.signals.addModerationCall(moderation);
  }
  addToModerationCall() {
    var uid;
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    websocketServices.signals.addUserToModerationCall(user, uid);
  }
  removeModerationCall() {
    var uid;
    websocketServices.signals.removeModerationCall(uid);
  }
  removeFromModerationCall() {
    var uid;
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    websocketServices.signals.removeUserFromModerationCall(user, uid);
  }
  clearModerationCalls() {
    websocketServices.signals.clearModerationCalls();
  }
  render() {
    return (
      <Paper
        style={{
          transition: "all .5s ease-in-out",
          // backgroundColor: "#000000",
          // BorderAllRounded: true
          margin:5,
          padding:5,
        }}
      >
        <StyledTypography align='left' variant={"h6"} color={"reflexBlue"}>
          Intercom Commands
        </StyledTypography>
        <Box
          marginLeft={"auto"}
          marginRight={"auto"}
          style={{ display: "flex" }}
        >
          <Grid
            id="intercomCommandsContainer"
            justify="space-between"
            container
            spacing={2}
          >
            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addToPartyLine} color="primary">
                add to party line
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeFromPartyLine} color="primary">
                remove from party line
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.clearPartyLine} color="primary">
                clear party line
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addToControlRoom} color="primary">
                add to control room
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeFromControlRoom} color="primary">
                remove from control room
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.clearControlRoom} color="primary">
                clear control room
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addToDirector} color="primary">
                add to director
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeFromDirector} color="primary">
                remove from director
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.clearDirector} color="primary">
                clear director
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addModerationCall} color="primary">
                add moderation call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addToModerationCall} color="primary">
                add to moderation call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeFromModerationCall} color="primary">
                remove from moderation call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeModerationCall} color="primary">
                remove moderation call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.clearModerationCalls} color="primary">
                clear moderation calls
              </CommandsButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  notConnectedSelected: state.ui.callModeratorComponent.notConnectedSelected,
  participantSelected: state.ui.callModeratorComponent.participantSelected,
  callModeratorSelected: state.ui.callModeratorComponent.callModeratorSelected,
  streamersSelected: state.ui.callModeratorComponent.streamersSelected,
  webcamPlayerSelected: state.ui.callModeratorComponent.webcamPlayerSelected,
  moderatedSelected: state.ui.callModeratorComponent.moderatedSelected,
  contributorSelected: state.ui.callModeratorComponent.contributorSelected,
  webcamIdSelected: state.ui.callModeratorComponent.webcamIdSelected,
});

export default withTranslation()(
  connect(mapStateToProps)(IntercomCommandComponent)
);
