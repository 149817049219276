// import { setTutorialScenario } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";

const steps = {
  chooseWebcamId: {
    element: "#webcamSlot-select",
    intro: "Please choose a Webcam Input.",
  },
  chooseParticipant: {
    element: "#participants-container",
    intro: "Please choose a participant.",
  },
  thenChooseWebcamId: {
    element: "#webcamSlot-select",
    intro: "Then choose a Webcam Input.",
  },
  chooseModerated: {
    element: "#moderated-container",
    intro: "Choose a Moderated User.",
  },
};
export const resetTutorialScenario = {
  steps: [],
  hints: [],
};
export const participantWebcamIdNotSelected = {
  steps: [steps.chooseParticipant, steps.thenChooseWebcamId],
  hints: [],
};
export const participantNotSelected = {
  steps: [steps.chooseParticipant],
  hints: [],
};
export const webcamIdNotSelected = {
  steps: [steps.chooseWebcamId],
  hints: [],
};

export const moderatedNotSelected = {
  steps: [steps.chooseModerated],
  hints: [],
};

export function webcamSlotWebcamIdNotSelected(webcamId) {
  var scenario = {
    steps: [steps.chooseWebcamId, thenChooseWebcamPlayer("#webcamInputsLabel")],
    position: "top",
    hints: [],
  };
  return scenario;
}
export function webcamSlotNotSelected(webcamId) {
  var scenario = {
    // steps: [chooseWebcamPlayerStep("#webcamSlot" + webcamId)],
    steps: [chooseWebcamPlayerStep("#webcamInputsLabel")],
    position: "top",
    hints: [],
  };
  return scenario;
}
function chooseWebcamPlayerStep(element) {
  var value = {
    title: "Welcome",
    position: "top",
    element: "",
    intro: "Choose a user in the Webcam inputs.",
  };
  value.element = element;
  return value;
}
function thenChooseWebcamPlayer(element) {
  var value = {
    title: "Welcome",
    position: "top",
    element: "",
    intro: "Then choose a user in the Webcam inputs.",
  };
  value.element = element;
  return value;
}
