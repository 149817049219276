import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import BallotIcon from "@material-ui/icons/Ballot";
import Typography from "@material-ui/core/Typography";
import i18nInstance from "../../../../i18n";
import {
  AddUserToIntercomButton,
  AllowScreenShareButton,
  ModerateUserButton,
  SwitchCameraButton,
  FHdButton,
  HdButton,
  HqButton,
  FourByThreeButton,
  LowResButton,
  ChooseMicButton,
  SendMessageToUserButton,
  KickUserButton,
  AddUserToModeratedButton,
  AddUserToDirectorButton,
  AddUserToPartyLineButton,
  ConnectUserButton,
  OnlineButton,
  MuteMicButton,
  MuteCamButton,
  TakeUserNote,
} from "../../../buttons/commonButtons";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  clearUserFromScreenSlot,
  getUserAvatar,
  getUserStatus,
} from "../../../../utils/callControllerUtils";
import UserAvatar from "../users/UserAvatar";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: -15,
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    position: "relative",
    marginLeft : "5px"
  },
  rootOnAir: {
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.reflexRed.main,
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    position: "relative",
    marginLeft : "5px"
  },
  rootModerated: {
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.reflexBlue.main,
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("usersContainer")?.clientHeight) -
      30,
    position: "relative",
    marginLeft : "5px"
  },
  title: {},
  cardHeader: {
    "& span": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "0.7vw" : "1.0vw"),
    },
  },
  cardHeaderBigger: {
    "& span": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "0.7vw" : "1.3vw"),
    },
  },
  avatar: {
    backgroundColor: theme.palette.reflexBlue.main,
  },
  avatarBig: {
    backgroundColor: theme.palette.reflexYellow.main,
    width: (props) => (props.cameraConnected || props.monitorWallActive ? "1.8vw" : "3vw"),
    height: (props) => (props.cameraConnected || props.monitorWallActive? "1.8vw" : "3vw"),
  },
  select: {
    fontSize: (props) => (props.cameraConnected || props.monitorWallActive? "0.5vw" : "0.7vw"),
  },
  selectLabel: {
    paddingTop: (props) => (props.cameraConnected || props.monitorWallActive ? "8px" : "10Px"),
    fontSize: (props) => (props.cameraConnected || props.monitorWallActive? "0.5vw" : "0.7vw"),
  },
  CardActions: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
  },
  buttonClass: {
    marginLeft: "0.2vw",
    marginRight: "0.2vw",
    "& svg": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "1.2vw" : "1.8vw"),
    },
  },

  screenShareButtonClass: {
    marginLeft: "0.2vw",
    marginRight: "0.2vw",
    "& svg": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "1.2vw" : "1.8vw"),
    },
    color: theme.palette.primary.main
  },
  screenShareAllowedButtonClass: {
    marginLeft: "0.2vw",
    marginRight: "0.2vw",
    "& svg": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "1.2vw" : "1.8vw"),
    },
    color: theme.palette.reflexYellow.main
  },
  screenShareStartedButtonClass: {
    marginLeft: "0.2vw",
    marginRight: "0.2vw",
    "& svg": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "1.2vw" : "1.8vw"),
    },
    color: theme.palette.secondary.main
  },



  buttonClassBigger: {
    marginLeft: "0.4vw",
    marginRight: "0.4vw",
    "& svg": {
      fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "1.5vw" : "1.8vw"),
      // fontSize: "1.8vw",
    },
  },
  cardContent: {
    marginTop: (props) => (props.cameraConnected || props.monitorWallActive ? "-10px" : "0px"),
  },
  platform: {
    fontSize: (props) => (props.cameraConnected || props.monitorWallActive ? "0.5vw" : "0.7vw"),
    marginBottom: (props) => (props.cameraConnected || props.monitorWallActive ? "0px" : "10px"),
  },
  note: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35vw",
    backgroundColor: theme.palette.grey1.main,
  },
  header: {
    display: "inline-flex",
    width: "100%",
    height: "40px",
    backgroundColor: theme.palette.reflexGreen.main,
    position: "relative",
    textAlign: "center",
  },
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "primary",
    marginTop: "7px",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    marginTop: -3
  },
  choseButton: {
    display: "flex",
    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    padding: "15px",
    textAlign: "center",
  },
  noteText: {
    width: "32vw",
  },
}));

export default function UserCard(props) {
  // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ props.user in UserCard: ", props.user)
  const [noteOpen, setNoteOpen] = useState(false);
  const [note, setNote] = useState(undefined);
  let selectedCamId = props.user.selectedCamId || "";
  let selectedMicId = props.user.selectedMicId || "";
  let audio = props.user.audio || [];
  let video = props.user.video || [];

  const { t, i18next } = useTranslation();
  const classes = useStyles(props);

  let buttonClass = classes.buttonClass;
  if (props.bigger) {
    buttonClass = classes.buttonClassBigger;
  }

  const moderateUser = (event) => {
    if (props.moderateUser) {
      props.user.avatar = getUserAvatar(props.user.userid);
      props.moderateUser(
        props.user,
        userStatus.intercom.moderations,
        props.intercom.moderations,
        userStatus.intercom.moderated
      );
      
      // if (userStatus.intercom.moderated) {
        // addUserToModerated()
      // }
    }
  };
  const addUserToIntercom = (event) => {
    if (props.addUserToIntercom) {
      props.addUserToIntercom(props.user, userStatus.intercom.controlRoom);
    }
  };
  const sendMessageToUser = (event) => {
    if (props.sendMessageToUser) {
      props.sendMessageToUser(props.user);
    }
  };
  const removeUserFromCall = (event) => {
    if (props.removeUserFromCall) {
      props.removeUserFromCall(props.user);
    }
  };
  const setUserNote = () => {
    if (props.addUserToModerated && note !== undefined) {
      props.user.avatar = getUserAvatar(props.user.userid);
      props.addUserToModerated(props.user, userStatus.intercom.moderated, note);
    }
    setNoteOpen(false);
  };
  const handleChangeNote = (event) => {
    setNote(event.target.value);
  };
  const setUserMicrophone = (event) => {
    if (props.setUserMicrophone) {
      props.setUserMicrophone(selectedMicId, audio, props.user);
    }
  };
  const setUserCamera = (event) => {
    if (props.setUserCamera)
      props.setUserCamera(selectedCamId, video, props.user);
  };
  const setUserScreenShare = (event) => {
    if (props.setUserScreenShare) props.setUserScreenShare(props.user);
  };
  const addUserToModerated = (event) => {
    if (props.addUserToModerated) {
      props.user.avatar = getUserAvatar(props.user.userid);
      props.addUserToModerated(props.user, userStatus.intercom.moderated);
    }
  };
  const addUserToDirector = (event) => {
    if (props.addUserToDirector) {
      props.addUserToDirector(props.user, userStatus.intercom.director);
    }
  };
  const addUserToPartyLine = (event) => {
    if (props.addUserToPartyLine) {
      props.addUserToPartyLine(props.user, userStatus.intercom.partyLine);
    }
  };
  const connectUser = (event) => {
    if (props.connectUser) {
      props.connectUser(props.user);
    }
  };
  const kickUser = (event) => {
    if (props.kickUser) {
      props.kickUser(props.user);
    }
  };
  const muteUnmuteUserMic = (event) => {
    if (props.muteUnmuteUserMic) {
      props.muteUnmuteUserMic(props.user);
    }
  };
  const muteUnmuteUserCam = (event) => {
    if (props.muteUnmuteUserCam) {
      props.muteUnmuteUserCam(props.user);
    }
  };
  const setUserResolution = (resolution) => {
    if (props.setUserResolution) {
      props.setUserResolution(resolution, props.user.details.connectionId);
    }
  };
  const openTimeSlot = () => {
    if (props.openTimeSlot) {
      props.openTimeSlot();
    }
  };
  var subheader = "";
  var big = false;
  if (props.size === "big") {
    subheader = props.user.details.location;
    big = true;
  }
  var userStatus = {
    onAir: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      100: false,
    },
    intercom: {
      partyLine: false,
      controlRoom: false,
      director: false,
      moderations: {
        inModeration: false,
        id: "",
      },
      moderated: false,
    },
    isOnAir: false,
    isAudioActive: true,
    isVideoActive: true,
    isScreenShareActive: false,
  };
  if (big && props.user !== undefined && props.onAir !== undefined) {
    userStatus = getUserStatus(
      props.user,
      props.onAir,
      props.intercom,
      props.moderated,
      props.isOnAir,
      true
    );
  } else if (!big && props.user !== undefined) {
    userStatus = getUserStatus(
      props.user,
      props.onAir,
      props.intercom,
      props.moderated,
      props.isOnAir,
      false
    );
  }
  var muteMicColor =
    userStatus.isAudioActive === true || props.user.userid === ""
      ? "primary"
      : "secondary";

  var muteCamColor =
    userStatus.isVideoActive === true || props.user.userid === ""
      ? "primary"
      : "secondary";

  var inWebcamSlotColor =
    userStatus.inWebcamSlot === false ? "primary" : "secondary";

  var ScreenShareButtonClasse =
  userStatus.isScreenShareActive ? classes.screenShareStartedButtonClass : userStatus.inScreenShareSlot?classes.screenShareAllowedButtonClass:classes.screenShareButtonClass; //userStatus.isScreenShareActive userStatus.inScreenShareSlot

  var partyLineColor =
    userStatus.intercom.partyLine === false ? "primary" : "secondary";
  var controlRoomColor =
    userStatus.intercom.controlRoom === false ? "primary" : "secondary";
  var directorColor =
    userStatus.intercom.director === false ? "primary" : "secondary";
  var moderatedColor =
    userStatus.intercom.moderated === false ? "primary" : "secondary";

  var moderationsColor =
    userStatus.intercom.moderations.inModeration === false
      ? "primary"
      : "secondary";

  var moderationStarted =
    userStatus.intercom.moderations.inModeration === true &&
    userStatus.intercom.moderations.started === true;
  let resolutionButtonColor = "VGA";
  if (
    [undefined, null].every((it) => it !== props.user) &&
    [undefined, null].every((it) => props.user.resolution !== it)
  ) {
    resolutionButtonColor = props.user.resolution;
  }
  const stringAvatar = () => {
    return props.user.username.charAt(0).toUpperCase();
  };
  const isMobile = () => {
    if(props.user.details.platform.includes('Mobile')) {
      return true
    }
    if(props.user.details.platform.includes('IOS')) {
      return true
    }
    if(props.user.details.platform.includes('Android')) {
      return true
    }
    return false
  }
  let rootClass = classes.root;
  if (props.user.username === "") {
    props.user.username = i18nInstance.t("UserCard|choseUser");
  }
  return (
    <Card
      className={
        userStatus.isOnAir && props.user.userid !== ""
          ? classes.rootOnAir
          : moderationStarted
          ? classes.rootModerated
          : classes.root
      }
    >
      <Modal open={noteOpen}>
        <Box className={classes.note}>
          <Box className={classes.header}>
            <Typography className={classes.title}>
              {i18nInstance.t("UserCard|noteTitle")}
            </Typography>

            <IconButton
              className={classes.closeButton}
              onClick={() => setNoteOpen(false)}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <Box style={{ textAlign: "center", marginTop: 10, width: "100%" }}>
            <TextField
              className={classes.noteText}
              id="noteText"
              multiline
              rows={4}
              placeholder={i18nInstance.t("UserCard|writeNote")}
              variant="outlined"
              onChange={handleChangeNote}
              defaultValue={
                props.user.note !== undefined && props.user.note !== ""
                  ? props.user.note
                  : undefined
              }
            />
          </Box>
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.choseButton}
              onClick={() => setUserNote()}
            >
              {i18nInstance.t("UserCard|noteButton")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <CardHeader
        avatar={
          <UserAvatar
            aria-label="avatar"
            className={props.bigger ? classes.avatarBig : classes.avatar}
            online={props.user.online}
            src={getUserAvatar(props.user.userid)}
          >
            {stringAvatar()}
          </UserAvatar>
        }
        title={props.user.username}
        subheader={subheader}
        className={props.bigger ? classes.cardHeaderBigger : classes.cardHeader}
      />
      {big && (
        <CardActions>
          <Box className={classes.CardActions}>
            <Tooltip
              arrow
              title={i18nInstance.t("CommonButtons|Send user to webcam input")}
              className={buttonClass}
            >
              <IconButton
                onClick={openTimeSlot}
                color={inWebcamSlotColor}
                disabled={
                  props.user.userid === "" || props.user.userid === undefined
                }
              >
                <BallotIcon />
              </IconButton>
            </Tooltip>

            <AllowScreenShareButton
              onClick={setUserScreenShare}
              // color={ScreenShareColor}
              className={ScreenShareButtonClasse}
              disabled={
                props.user.userid === "" || props.user.userid === undefined || isMobile()
              }
            />
            <MuteMicButton
              color={muteMicColor}
              onClick={muteUnmuteUserMic}
              disabled={!props.user.online}
              className={buttonClass}
            />
            <MuteCamButton
              color={muteCamColor}
              onClick={muteUnmuteUserCam}
              disabled={!props.user.online}
              className={buttonClass}
            />
            <ChooseMicButton
              onClick={setUserMicrophone}
              disabled={!props.user.online}
              className={buttonClass}
              devices={props.user.audio}
            />
            <SwitchCameraButton
              onClick={setUserCamera}
              disabled={!props.user.online}
              className={buttonClass}
              devices={props.user.video}
            />
            
          </Box>
        </CardActions>
      )}
      {big && (
        <CardActions>
          <Box className={classes.CardActions}>
            <FHdButton
              onClick={() => setUserResolution("FHD")}
              color={resolutionButtonColor === "FHD" ? "secondary" : "primary"}
              disabled={!props.user.online}
              className={buttonClass}
            />
            <HdButton
              onClick={() => setUserResolution("HD")}
              color={resolutionButtonColor === "HD" ? "secondary" : "primary"}
              disabled={!props.user.online}
              className={buttonClass}
            />
            <HqButton
              onClick={() => setUserResolution("VGA16x9")}
              disabled={!props.user.online}
              className={buttonClass}
              color={
                resolutionButtonColor === "VGA16x9" ? "secondary" : "primary"
              }
            />
            <FourByThreeButton
              onClick={() => setUserResolution("VGA")}
              color={resolutionButtonColor === "VGA" ? "secondary" : "primary"}
              disabled={!props.user.online}
              className={buttonClass}
            />
            <LowResButton
              onClick={() => setUserResolution("QVGA")}
              color={resolutionButtonColor === "QVGA" ? "secondary" : "primary"}
              disabled={!props.user.online}
              className={buttonClass}
            />
          </Box>
        </CardActions>
      )}
      {big && (
        <CardActions>
          {/* //TODO ajouter la condition si le user est dans la liste not connected alors afficher ce bouton */}
          <Box className={classes.CardActions}>
            {!props.user.online && props.user.notConnected && (
              <ConnectUserButton
                onClick={connectUser}
                disabled={
                  props.user.userid === "" || props.user.userid === undefined
                }
              />
            )}
            <AddUserToModeratedButton
              onClick={addUserToModerated}
              color={moderatedColor}
              className={buttonClass}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
            />
            <ModerateUserButton
              onClick={moderateUser}
              color={moderationsColor}
              disabled={userStatus.isOnAir || !props.user.online}
              className={buttonClass}
            />
            <AddUserToIntercomButton
              onClick={addUserToIntercom}
              color={controlRoomColor}
              className={buttonClass}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
            />
            <AddUserToDirectorButton
              onClick={addUserToDirector}
              color={directorColor}
              className={buttonClass}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
            />
            <AddUserToPartyLineButton
              onClick={addUserToPartyLine}
              color={partyLineColor}
              className={buttonClass}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
            />
            <TakeUserNote
              onClick={() => setNoteOpen(true)}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
              color={
                props.user.note !== "" && props.user.note !== undefined
                  ? "secondary"
                  : "primary"
              }
              className={buttonClass}
              tootTip={
                props.user.note !== "" && props.user.note !== undefined
                  ? props.user.note
                  : i18nInstance.t("CommonButtons|TakeUserNote")
              } 
            />
            {/* <SendMessageToUserButton
              onClick={sendMessageToUser}
              disabled={!props.user.online}
              className={buttonClass}
            /> */}
            {/* <KickUserButton
              onClick={kickUser}
              disabled={
                props.user.userid === "" || props.user.userid === undefined
              }
            /> */}
          </Box>
        </CardActions>
      )}
      <CardContent className={classes.cardContent}>
        <Typography className={classes.platform} paragraph>
          {props.user.details.platform}
        </Typography>

        <Typography
          paragraph
          className={classes.selectLabel}
        >
          {t("UserCard|Language") + props.user.details.language}
        </Typography>
      </CardContent>
    </Card>
  );
}
