import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  callDidUpdate,
  websocketServices,
  dialogStateFactory
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { Box } from "@material-ui/core";
import RemoveFromCallDialog from "./RemoveFromCallDialog";
import ChoseUserCamDevice from "./ChoseUserCamDevice";
import ChoseUserMicDevice from "./ChoseUserMicDevice";
import ChoseScreenShare from "./ChoseScreenShare";

class DialogManager extends Component {
  constructor(props) {
    super(props);
    this.dialogStateDefault = dialogStateFactory({}) // TODO: import this
    this.state = {
      dialog: this.dialogStateDefault,
      call: {
        isOnAir: false,
        onAir: {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
          8: [],
          9: [],
          10: [],
          100: [],
        },
      },
    };
  }

  componentDidUpdate(prevProps) {

    if (prevProps.dialog !== this.props.dialog) {
      this.setState({ dialog: this.props.dialog });
    }
  }

  componentDidMount() {

  }

  render() {

    let removeFromCallDialog = false;
    let choseUserCamDialog = false;
    let choseUserMicDialog = false;
    let choseScreenShareDialog = false;

    switch (this.state.dialog.event) {
      case "REMOVE_FROM_CALL":
        removeFromCallDialog = true;
        break;
      case "CHOSE_USER_CAM":
        choseUserCamDialog = true;
        break;
      case "CHOSE_USER_MIC":
        choseUserMicDialog = true;
        break;
      case "SCREEN_SHARE":
        choseScreenShareDialog = true;
        break;
    }

    return (
      <Box>
        <RemoveFromCallDialog
          dialog={this.state.dialog}
          open={removeFromCallDialog}
          dispatch={this.props.dispatch}
          defaultDialog={this.dialogStateDefault}
          websocketServices={websocketServices}
        />
        <ChoseUserCamDevice
          open={choseUserCamDialog}
          dialog={this.state.dialog}
          dispatch={this.props.dispatch}
          defaultDialog={this.dialogStateDefault}
          websocketServices={websocketServices}
        />
        <ChoseUserMicDevice
          open={choseUserMicDialog}
          dialog={this.state.dialog}
          dispatch={this.props.dispatch}
          defaultDialog={this.dialogStateDefault}
          websocketServices={websocketServices}
        />
        <ChoseScreenShare
          open={choseScreenShareDialog}
          dialog={this.state.dialog}
          dispatch={this.props.dispatch}
          defaultDialog={this.dialogStateDefault}
          websocketServices={websocketServices}
        />
      </Box>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  dialog: state.ui.dialog,
});

export default withTranslation()(connect(mapStateToProps)(DialogManager));
