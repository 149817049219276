import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import UserAvatar from "../users/UserAvatar";
import { useTranslation } from "react-i18next";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import {
  getUserAvatar,
  getUserDetails,
  getUserStatus,
} from "../../../../utils/callControllerUtils";
import { useSelector } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyle = makeStyles((theme) => ({
  root: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "5px",
  },
  rootOnair: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: theme.palette.secondary.main,
    paddingLeft: "5px",
  },
  rootInModeration: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: theme.palette.reflexBlue.main,
    paddingLeft: "5px",
  },
  whisperButton: {
      padding:"0px",
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "2.5vh",
    },
  },
  whisperButtonActive: {
    padding:"0px",
    color: theme.palette.secondary.main,
    "& svg": {
      fontSize: "2.5vh",
    },
  },
  whisperButtonOtherActive: {
    padding:"0px",
    color: theme.palette.reflexYellow.main,
    "& svg": {
      fontSize: "2.5vh",
    },
  },
  deleteButton: {
    padding:"0px",
    paddingLeft:"5px",
    "& svg": {
        fontSize: "2.5vh",
      },
  },
  userName: {
    "& span": {
      fontSize: "1.4vh",
    },
  },
  userNameCC: {
    "& span": {
      fontSize: "1.5vh",
      color: theme.palette.reflexBlue.main,
    },
  },
  iconContainer: {
    marginRight: "-10px",
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    width: "4vh",
    height: "4vh",
  },
  buttonContainer : {
      marginRight: "-15px"
  }
}));

/**
 * a list item representing a user in the intercom
 *
 * props:
 *  participant:
 *      userid: string
 *      avatar: string
 *      username: string
 *  disabled: bool
 *  onDelete : (id) => void
 *
 */
export function IntercomUserComponent(props) {
  const { t } = useTranslation();
  const classes = useStyle();
  const storeParticipants = useSelector((state) => {
    const participants = Object.values(state.websocket.participants || {});
    const moderators = Object.values(state.websocket.callModerators || {});
    return participants.concat(moderators);
  });
  const call = useSelector((state) => state.websocket.call);
  const intercoms = useSelector(state => state.websocket.intercom)
  const stringAvatar = (participant) => {
    return participant.username.charAt(0).toUpperCase();
  };
  const getOtherwhispering = (userId) => {
    if (
      props.intercom[props.localUser.getUserId()] !== undefined &&
      props.intercom[props.localUser.getUserId()].whispering !== undefined
    ) {
      return !!props.participants
        .filter(
          (participant) =>
            participant.userType === "CALL_MODERATOR" &&
            participant.userId !== props.localUser.getUserId()
        )
        .flatMap((participant) => participant.whispering)
        .find((whispererId) => whispererId === userId);
    } else {
      return false;
    }
  };
  const getWhisperButtonClass = (userId) => {
    let userIsInIntercom = [];
    if (
      props.intercom[props.localUser.getUserId()] !== undefined &&
      props.intercom[props.localUser.getUserId()].whispering !== undefined
    ) {
      userIsInIntercom = props.intercom[
        props.localUser.getUserId()
      ].whispering.filter((id) => id === userId);
      if (userIsInIntercom.length === 0) {
        if (getOtherwhispering(userId)) {
          return classes.whisperButtonOtherActive;
        } else {
          return classes.whisperButton;
        }
      } else if (userIsInIntercom.length === 1) {
        return classes.whisperButtonActive;
      } else {
        if (getOtherwhispering(userId)) {
          return classes.whisperButtonOtherActive;
        } else {
          return classes.whisperButton;
        }
      }
    } else {
      if (getOtherwhispering(userId)) {
        return classes.whisperButtonOtherActive;
      } else {
        return classes.whisperButton;
      }
    }
  };

  let rootClass = classes.root;
  let userDetails = {
    online: false,
  };
  let userStatus = {
    isOnAir: false,
  };
  if (storeParticipants) {
    userDetails = getUserDetails(storeParticipants, props.participant);
    userStatus = getUserStatus(
      props.participant,
      call.onAir,
      intercoms,
      {},
      call.isOnAir,
      true
    );
  }
  var moderationStarted =
    userStatus.intercom.moderations.inModeration === true &&
    userStatus.intercom.moderations.started === true;
  if (userStatus.isOnAir) {
    rootClass = classes.rootOnair;
  } else if (moderationStarted) {
    rootClass = classes.rootInModeration;
  }else {
    rootClass = classes.root;
  }

  const handleDelete = () => {
    props.removeUser(props.participant.userid)
  }

  return (
    <ListItem className={rootClass} key={props.participant.userid}>
      <ListItemIcon className={classes.iconContainer}>
        <UserAvatar
          className={classes.avatar}
          online={userDetails.online}
          src={getUserAvatar(props.participant.userid)}
        >
          {stringAvatar(props.participant)}
        </UserAvatar>
      </ListItemIcon>
      <ListItemText
        className={
          props.participant.userType === "CALL_MODERATOR"
            ? classes.userNameCC
            : classes.userName
        }
        primary={props.participant.username}
      />
      <ListItemSecondaryAction className={classes.buttonContainer}>
        <Tooltip title={t("IntercomComponent|pttAllButtonTooltip")} arrow>
          <IconButton
            // disabled={!localUserIsInIntercom || !props.intercom.active}
            disabled={props.disabled || !userDetails.online}
            onClick={() => props.handleToggleWhisper(props.participant.userid)}
            className={getWhisperButtonClass(props.participant.userid)}
          >
            <RecordVoiceOverIcon />
          </IconButton>
        </Tooltip>
        <IconButton
        className={classes.deleteButton}
          onClick={handleDelete}>
          <DeleteIcon/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
