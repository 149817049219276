import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Send from "@material-ui/icons/Send";
import { toggle } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import "./ChatComponent.css";
import { Avatar, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import i18nInstance from "../../i18n";
import { getUserAvatar } from "../../utils/callControllerUtils";
import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";

const styles = (theme) => ({
  chatContainerCallModerator: {
    position: "absolute",
    width: "21.7%",
    height: (props) =>
    parseInt(props.dimension?.height) -
    parseInt(document.getElementById("header")?.clientHeight) -
    parseInt(
      document.getElementById("moderatorToolBarContainer")?.clientHeight
    ) -
    parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
    parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
    parseInt(document.getElementById("usersContainer")?.clientHeight),
    maxHeight: (props) =>
    parseInt(props.dimension?.height) -
    parseInt(document.getElementById("header")?.clientHeight) -
    parseInt(
      document.getElementById("moderatorToolBarContainer")?.clientHeight
    ) -
    parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
    parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
    parseInt(document.getElementById("usersContainer")?.clientHeight) ,
  },
  chatContainerCallModeratorCameraConnected: {
    position: "absolute",
    width: "21.7%",
    height: (props) =>
    parseInt(props.dimension?.height) -
    parseInt(document.getElementById("header")?.clientHeight) -
    parseInt(
      document.getElementById("moderatorToolBarContainer")?.clientHeight
    ) -
    parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
    parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
    parseInt(document.getElementById("usersContainer")?.clientHeight),
    maxHeight: (props) =>
    parseInt(props.dimension?.height) -
    parseInt(document.getElementById("header")?.clientHeight) -
    parseInt(
      document.getElementById("moderatorToolBarContainer")?.clientHeight
    ) -
    parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
    parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
    parseInt(document.getElementById("usersContainer")?.clientHeight) ,
  },
  chatContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  },
  chatComponent: {
    backgroundColor: "#d7d9d8",
    position: "relative",
    height: "calc(100%)",
    width: "calc(100%)",
    borderRadius: "20px",
    // zIndex: "100001"
  },

  chatComponentCallModerator: {
    backgroundColor: "#d7d9d8",
    position: "relative",
    height: "calc(100% - 30px)",
    width: "calc(100% - 30px)",
    borderRadius: "20px",
  },
  chatToolbar: {
    height: "30px",
    backgroundColor: theme.palette.darkbackground.main,
    boxSizing: "border-box",
    fontSize: "14px",
    textAlign: "center",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    color: theme.palette.primary.main,
    paddingTop: "5px",
  },
});
class ChatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageList:
        websocketServices.reduxStore.default.getState().websocket.chat.messages,
      message: "",
    };
    this.chatScroll = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handlePressKey = this.handlePressKey.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    if (this.props.user.isCallModerator()) {
      this.props.dispatch(toggle());
    }
  }
  componentDidUpdate(prevprops) {
    if (prevprops.chatMessages !== this.props.chatMessages) {
      this.setState({ messageList: this.props.chatMessages });
      setTimeout(() => {
        this.props.messageReceived();
      }, 50);
      this.scrollToBottom();
    }
  }
  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  handlePressKey(event) {
    if (event.key === "Enter") {
      this.sendMessage();
    }
  }

  sendMessage() {
    if (this.props.user && this.state.message) {
      let message = this.state.message.replace(/ +(?= )/g, "");
      if (message !== "" && message !== " ") {
        const data = {
          message: message,
          nickname: this.props.user.getNickname(),
          userid: this.props.user.getUserId(),
        };
        this.props.websocketServices.signals.sendSignal(data, "chat");
      }
    }
    this.setState({ message: "" });
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.chatScroll.current.scrollTop =
          this.chatScroll.current.scrollHeight;
      } catch (err) {}
    }, 20);
  }

  close() {
    this.props.close(undefined);
  }

  render() {
    const { classes, theme } = this.props;
    const styleChat = { display: this.props.chatDisplay };
    const closeChat = () => {
      this.props.dispatch(toggle());
    };
    let chatClasse = classes.chatContainer;
    let chatComponentClasse = classes.chatComponent;
    const stringAvatar = (username) => {
      return username.charAt(0).toUpperCase();
    };
    if (this.props.user.isCallModerator() && (this.props.cameraConnected || this.props.monitorWallActive)) {
      chatClasse = classes.chatContainerCallModeratorCameraConnected;
      chatComponentClasse = classes.chatComponentCallModerator;
    } else if (
      this.props.user.isCallModerator() &&
      !this.props.cameraConnected
    ) {
      chatClasse = classes.chatContainerCallModerator;
      chatComponentClasse = classes.chatComponentCallModerator;
    }
    return (
      <div id="chatContainer" className={chatClasse}>
        <div
          id="chatComponent"
          className={chatComponentClasse}
          style={styleChat}
        >
          <div id="chatToolbar" className={classes.chatToolbar}>
            <span>
              {this.props.user.getConnectionId !== null &&
                this.props.user.getConnectionId() !== null &&
                this.props.user.getConnectionId !== undefined &&
                this.props.user.getConnectionId() !== undefined}{" "}
              {i18nInstance.t("chat|Chat")}
            </span>
            <IconButton id="closeButton" onClick={closeChat}>
              <CloseIcon color="primary" />
            </IconButton>
          </div>



          {/* https://v4.mui.com/components/tabs/#tabs */}
          

          <div className="message-wrap" ref={this.chatScroll}>
            {this.state.messageList.map((data, i) => (
              <div
                key={i}
                id="remoteUsers"
                className={
                  "message" +
                  (data.connectionId !== this.props.user.getConnectionId()
                    ? " left"
                    : " right")
                }
              >
                <Avatar className="user-img" src={getUserAvatar(data.userid)}>
                  {stringAvatar(data.nickname)}
                </Avatar>
                <div className="msg-detail">
                  <div className="msg-info">
                    <p> {data.nickname}</p>
                  </div>
                  <div className="msg-content">
                    <span className="triangle" />
                    <p className="text">{data.message}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>






          <div id="messageInput">
            <input
              placeholder={this.props.t("chat|Send a message")}
              id="chatInput"
              value={this.state.message}
              onChange={this.handleChange}
              onKeyPress={this.handlePressKey}
            />
            <Tooltip title={this.props.t("chat|Send a message")}>
              <Fab size="small" id="sendButton" onClick={this.sendMessage}>
                <Send />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chatDisplay: state.ui.chat.chatDisplay,
  chatMessages: state.websocket.chat.messages,
});

export default withStyles(styles, { withTheme: true })(
  withTranslation()(connect(mapStateToProps)(ChatComponent))
);
