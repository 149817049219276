import { Component } from "react";
import { TimeSlotEditorComponent } from "./TimeSlotEditorComponent";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { callDidUpdate, websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { removeCallTimeSlot, removeUserFromCallWebcamSlot, setUserInWebcamSlot, swapCallTimeSlot } from "../../../../utils/callControllerUtils";

class TimeSlotEditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      onAir: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        100: [],
      },
      monitorWallActive: false,
      cameraConnected: false
    };
    this.addUserToTimeSLot = this.addUserToTimeSLot.bind(this);
    this.removeUserFromTimeSLot = this.removeUserFromTimeSLot.bind(this);
    this.removeTimeSlot = this.removeTimeSlot.bind(this);
    this.moveTimeSlot = this.moveTimeSlot.bind(this);
    this.setOnAir = this.setOnAir.bind(this);
  }

  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (prevProps.monitorWallActive !== this.props.monitorWallActive) {
      state.monitorWallActive = this.props.monitorWallActive
    }
    if (prevProps.cameraConnected !== this.props.cameraConnected) {
      state.cameraConnected = this.props.cameraConnected
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  setOnAir() {
    if (this.props.isOnAir) {
      websocketServices.signals.stopCall();
    } else {
      websocketServices.signals.startCall();
    }
  }
  addUserToTimeSLot(user, webcamSlot, timeSlot) {
    setUserInWebcamSlot(user, webcamSlot, timeSlot)
  }
  removeUserFromTimeSLot(webcamSlot, timeSlot) {
    const userCount = Object.values(this.props.onAir)
                            .map(webcamSlots => webcamSlots[timeSlot])
                            .filter(timeSlot => !(timeSlot === undefined || timeSlot.userid === ""))
                            .length
    
    if (userCount === 1) {
      this.removeTimeSlot(timeSlot)
    } else {
      removeUserFromCallWebcamSlot(webcamSlot, timeSlot)
    }
  }
  removeTimeSlot(timeslot) {
    removeCallTimeSlot(timeslot)
  }
  moveTimeSlot(fromID, ToId) {
    console.log("moveTimeSlot")
    swapCallTimeSlot(fromID,ToId)
  }

  render() {
    return (
    //   <></>
      <TimeSlotEditorComponent
        localUser={this.props.localUser}
        handleClosedTimeSlot={this.props.handleClosedTimeSlot}
        addUserToTimeSLot={this.addUserToTimeSLot}
        removeUserFromTimeSLot={this.removeUserFromTimeSLot}
        removeTimeSlot={this.removeTimeSlot}
        moveTimeSlot={this.moveTimeSlot}
        setOnAir={this.setOnAir}
        dimension={this.props.dimension}
        cameraConnected={this.state.cameraConnected}
        monitorWallActive={this.state.monitorWallActive}
      />
    );
  }
}

// TODO: promissing idea but needs work
// function mapOnAirToOnAirQueue(onAir) {
//     return Object.entries(onAir)
//                  .reduce((accumulator, [key, value]) => {
//                    accumulator[`webcam${key}`] = value
//                    return accumulator
//                  }, {})
// }

const mapStateToProps = (state) => ({
  onAir: state.websocket.call.onAir,
  isOnAir: state.websocket.call.isOnAir,
  monitorWallActive: state.ui.callModeratorComponent.monitorWallActive,
  cameraConnected: state.websocket.connection.cameraConnected,
});

export default withTranslation()(
  connect(mapStateToProps)(TimeSlotEditorContainer)
);
