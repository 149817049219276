import { useState, useEffect } from "react";
import {
  DevicesSettingsManager,
  LocalStorageManager,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { changeCamera, changeMicrophone, changeResolution } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/DevicesCommands";
import { useSelector } from "react-redux";
import {switchIntercomMic} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/intercom/switchIntercomMic"
/**
    user: UserModel
    devices: {audio: [], video[] }
    toggleDeviceSettings: () => void
*/
export default function useDevices({ defaultResolution }) {
  // console.log(`%c ${devices}`, "color:blue")
  const [selectedCamId, setSelectedCamId] = useState(null);
  const [selectedMicId, setSelectedMicId] = useState(null);
  const [selectedResolution, setSelectedResolution] =
    useState(LocalStorageManager.getStorage(LocalStorageManager.STORAGE_KEYS.CAM_CONSTRAINT) || defaultResolution)
  const [devices, setDevices] = useState({ audio: [], video: [] });
  const cameraConnected = useSelector(state => state.websocket.connection.cameraConnected)
  const onAir = useSelector(state => state.ui.devicesSettings.onAir)

  let error = null;
  const audio = devices.audio;
  const video = devices.video;

  useEffect(() => {
    // 0. initialise devices
    DevicesSettingsManager.initPerms()
  }, []);

  useEffect(() => {
    // 1. get list of devices
  
      DevicesSettingsManager.getDevices().then((newDevices) =>
        setDevices(newDevices)
      );
  
  }, []);

  useEffect(() => {
    const noAudio = audio.length === 0,
      noVideo = video.length === 0;
    if (noAudio || noVideo) {
      error = {
        type: "MISSING_DEVICES",
        missingAudio: noAudio,
        missingVideo: noVideo,
      };
    } else {
      // 2. set default device at devices[0]
      setSelectedCamId(video[0].id);
      setSelectedMicId(audio[0].id);

      // 3. check if device present in local storage
      const savedMicId = LocalStorageManager.getStorage(
          LocalStorageManager.STORAGE_KEYS.MIC_DEVICE
        ),
        savedCamId = LocalStorageManager.getStorage(
          LocalStorageManager.STORAGE_KEYS.CAM_DEVICE
        );

      // 4. check if local storage's device still exists in list of devices
      // 5. set default device to localstorage's device

      if (audio.find((mic) => mic.id === savedMicId))
        setSelectedMicId(savedMicId);
      if (video.find((cam) => cam.id === savedCamId))
        setSelectedCamId(savedCamId);
    }
  }, [audio.length, video.length]);

  // 6. return bindings to change selection (use reducer?)
  const fields = { selectedCamId, selectedMicId, selectedResolution };
  const updaters = {
    updateMicId: (micId) => {
      if (audio.every((mic) => mic.id !== micId)) return;
      setSelectedMicId(micId);
      LocalStorageManager.setStorage(
        LocalStorageManager.STORAGE_KEYS.MIC_DEVICE,
        micId
      );
      if (cameraConnected) {
        changeMicrophone({micId})
        if (websocketServices.getLocalUser().getStreamManager() === null || websocketServices.getLocalUser().getStreamManager() === undefined || websocketServices.getLocalUser().getStreamManager().stream === undefined) {
          websocketServices.disconnectWebcam();
          websocketServices.connectWebcam(onAir);
        }
      }
      if (websocketServices.getLocalUser().getIntercomStatus()) {
        switchIntercomMic(micId)
      }
    },
    updateCamId: (camId) => {
      if (video.every((cam) => cam.id !== camId)) return;
      setSelectedCamId(camId);
      LocalStorageManager.setStorage(
        LocalStorageManager.STORAGE_KEYS.CAM_DEVICE,
        camId
      );
      if (cameraConnected) {
        changeCamera({camId})
        if (websocketServices.getLocalUser().getStreamManager() === null || websocketServices.getLocalUser().getStreamManager() === undefined || websocketServices.getLocalUser().getStreamManager().stream === undefined) {
          // console.warn("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ restarted camera")
          websocketServices.disconnectWebcam();
          websocketServices.connectWebcam(onAir);

        }
      }
    },
    updateResolution: (res) => {
      setSelectedResolution(res);
      LocalStorageManager.setStorage(
        LocalStorageManager.STORAGE_KEYS.CAM_CONSTRAINT,
        res
      );
      if (cameraConnected) {
        changeResolution({resolution: res})
      }
    },
  };
  return [fields, updaters, devices, error];
}
