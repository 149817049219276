import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import i18nInstance from "../../../../i18n";
import { Autocomplete } from "@material-ui/lab/";
import { TextField, Grid, Paper, Badge, Box } from "@material-ui/core/";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import {
  setSelectedUser,
  setUserInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import {
  PARTICIPANTS,
  NOT_CONNECTED,
  CALL_MODERATORS,
  MODERATED,
  CONTRIBUTORS,
  PARTICIPANTS_INPUT_VALUE,
  NOT_CONNECTED_INPUT_VALUE,
  CONTRIBUTORS_INPUT_VALUE,
  MODERATED_INPUT_VALUE,
  CALL_MODERATORS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import { StyledTypography } from "../../../commons/StyledTypography";
import PartipantTag from "./PartipantTag";
import { UserPopper } from "./UserPopper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "7.5vw",
    margin: "0px",
    marginBottom: 10,
    
    padding: "0px",
    "& input": {
      fontSize: "0.8vw",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.8vw",
    },
  },
  boxContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    fontSize: "1.0vw",
    color: theme.palette.reflexYellow.main
  }
});
class UsersComponent extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      moderated: [],
      notConnected: [],
      contributors: [],
      dimension: this.browserUtil.getWindowDimensions(),
      participantsInputValue: "",
      streamersInputValue: "",
      webcamPlayersInputValue: "",
      moderatedInputValue: "",
      notConnectedInputValue: "",
      contributorsInputValue: "",
      callModeratorsInputValue: "",
      participantSelected: null,
      streamersSelected: null,
      webcamPlayerSelected: null,
      moderatedSelected: null,
      notConnectedSelected: null,
      contributorSelected: null,
      callModeratorSelected: null,
    };
    this.handleResize = this.handleResize.bind(this);
    this.setSelectedUser = this.setSelectedUser.bind(this);
    this.setUserInputValue = this.setUserInputValue.bind(this);
  }
  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);

    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  componentDidMount() {}
  handleResize() {}
  setSelectedUser(userType, value) {
    var payload = { userType: userType, value: value };
    this.props.dispatch(setSelectedUser(payload));
  }
  setUserInputValue(type, newInputValue) {
    var payload = { type: type, value: newInputValue };
    this.props.dispatch(setUserInputValue(payload));
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Paper
        id="usersContainer"
        style={{
          // transition: "all .5s ease-in-out",
          margin: 5,
          padding: 5,
          // height: "10vh",
          // maxHeight: "10vh"
        }}
      >
        <StyledTypography className={classes.header} align="left" variant={"h6"} >
          <Trans>Users|Users</Trans>
        </StyledTypography>

        <Box
          id="participantsContainer"
          style={{ display: "flex", flexDirection: "row", flexGrow: 0 }}
        >
     
          <Box className={classes.boxContainer}>
            <Badge
              badgeContent={this.state.notConnected.length}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Autocomplete
                id="not-connected-container"
                className={classes.root}
                options={this.state.notConnected.map((option) => option)}
                getOptionLabel={(option) => option.username}
                onChange={(event, value) =>
                  this.setSelectedUser(NOT_CONNECTED, value)
                }
                onInputChange={(event, newInputValue) => {
                  this.setUserInputValue(
                    NOT_CONNECTED_INPUT_VALUE,
                    newInputValue
                  );
                }}
                PopperComponent={UserPopper}
                inputValue={this.state.notConnectedInputValue}
                value={this.state.notConnectedSelected}
                fullWidth
                freeSolo
                renderOption={(option, { selected }) => (
                  <PartipantTag option={option} selected={selected} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18nInstance.t("UsersComponent|Not Connected")}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  />
                )}
              />
            </Badge>
          </Box>

          <Box className={classes.boxContainer}>
            <Badge
              badgeContent={this.state.participants.length}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ width: "100%" }}
            >
              <Autocomplete
                id="participants-container"
                className={classes.root}
                options={this.state.participants.map((option) => option)}
                getOptionLabel={(option) => option.username}
                onChange={(event, value) =>
                  this.setSelectedUser(PARTICIPANTS, value)
                }
                onInputChange={(event, newInputValue) => {
                  this.setUserInputValue(
                    PARTICIPANTS_INPUT_VALUE,
                    newInputValue
                  );
                }}
                inputValue={this.state.participantsInputValue}
                value={this.state.participantSelected}
                fullWidth
                freeSolo
                PopperComponent={UserPopper}
                renderOption={(option, { selected }) => (
                  <PartipantTag option={option} selected={selected} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18nInstance.t("UsersComponent|Participants")}
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    size="small"
                  />
                )}
              />
            </Badge>
          </Box>

          <Box className={classes.boxContainer}>
            <Badge
              badgeContent={this.state.moderated.length}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ width: "100%" }}
            >
              <Autocomplete
                id="moderated-container"
                className={classes.root}
                freeSolo
                options={this.state.moderated.map((option) => option)}
                getOptionLabel={(option) => option.username}
                onChange={(event, value) =>
                  this.setSelectedUser(MODERATED, value)
                }
                onInputChange={(event, newInputValue) => {
                  this.setUserInputValue(MODERATED_INPUT_VALUE, newInputValue);
                }}
                PopperComponent={UserPopper}
                inputValue={this.state.moderatedInputValue}
                value={this.state.moderatedSelected}
                renderOption={(option, { selected }) => (
                  <PartipantTag option={option} selected={selected} />
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18nInstance.t("UsersComponent|Moderated")}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Badge>
          </Box>
          <Box className={classes.boxContainer}>
            <Badge
              badgeContent={this.state.contributors.length}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ width: "100%" }}
            >
              <Autocomplete
                id="contributors-container"
                className={classes.root}
                freeSolo
                options={this.state.contributors.map((option) => option)}
                getOptionLabel={(option) => option.username}
                onChange={(event, value) =>
                  this.setSelectedUser(CONTRIBUTORS, value)
                }
                onInputChange={(event, newInputValue) => {
                  this.setUserInputValue(
                    CONTRIBUTORS_INPUT_VALUE,
                    newInputValue
                  );
                }}
                PopperComponent={UserPopper}
                inputValue={this.state.contributorsInputValue}
                value={this.state.contributorSelected}
                renderOption={(option, { selected }) => (
                  <PartipantTag option={option} selected={selected} />
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18nInstance.t("UsersComponent|Contributors")}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Badge>
          </Box>
          <Box className={classes.boxContainer}>
            <Badge
              badgeContent={this.state.callModerators.length}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ width: "100%" }}
            >
              <Autocomplete
                id="callModerators-container"
                className={classes.root}
                freeSolo
                options={this.state.callModerators.map((option) => option)}
                getOptionLabel={(option) => option.username}
                onChange={(event, value) =>
                  this.setSelectedUser(CALL_MODERATORS, value)
                }
                onInputChange={(event, newInputValue) => {
                  this.setUserInputValue(
                    CALL_MODERATORS_INPUT_VALUE,
                    newInputValue
                  );
                }}
                PopperComponent={UserPopper}
                inputValue={this.state.callModeratorsInputValue}
                value={this.state.callModeratorSelected}
                renderOption={(option, { selected }) => (
                  <PartipantTag option={option} selected={selected} />
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18nInstance.t("UsersComponent|Call Moderators")}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Badge>
          </Box>
        </Box>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  moderated: state.websocket.intercom.moderated,
  notConnected: state.websocket.notConnected,
  contributors: state.websocket.contributors,
  participantsInputValue:
    state.ui.callModeratorComponent.participantsInputValue,
  streamersInputValue: state.ui.callModeratorComponent.streamersInputValue,
  webcamPlayersInputValue:
    state.ui.callModeratorComponent.webcamPlayersInputValue,
  moderatedInputValue: state.ui.callModeratorComponent.moderatedInputValue,
  notConnectedInputValue:
    state.ui.callModeratorComponent.notConnectedInputValue,
  contributorsInputValue:
    state.ui.callModeratorComponent.contributorsInputValue,
  callModeratorsInputValue:
    state.ui.callModeratorComponent.callModeratorsInputValue,
  participantSelected: state.ui.callModeratorComponent.participantSelected,
  streamersSelected: state.ui.callModeratorComponent.streamersSelected,
  webcamPlayerSelected: state.ui.callModeratorComponent.webcamPlayerSelected,
  moderatedSelected: state.ui.callModeratorComponent.moderatedSelected,
  notConnectedSelected: state.ui.callModeratorComponent.notConnectedSelected,
  contributorSelected: state.ui.callModeratorComponent.contributorSelected,
  callModeratorSelected: state.ui.callModeratorComponent.callModeratorSelected,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(UsersComponent))
);
