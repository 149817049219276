import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Box, Typography } from "@material-ui/core/";
import "react-notifications-component/dist/theme.css";
import StreamComponent from "../stream/StreamComponent";
import DialogExtensionComponent from "../dialog-extension/DialogExtension";
import ReflexPlayerLayout from "../../layout/reflex-player-layout";
import {
  reflexComponentDidUpdate,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {
  reflexWsDisconnect,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/middlewares/actions/reflexApiActions";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import $ from "jquery"
import i18nInstance from "../../i18n";
import { withStyles } from "@material-ui/core/styles";
import playerSpinner from "../../assets/images/player-spinner.gif"

const styles = (theme) => ({
  mainBounds: {

    position: "relative",
    height: "inherit",
    width: "inherit",
    background: "url('"+playerSpinner+"') "+theme.palette.grey4.main+" no-repeat center center",
    backgroundAttachment: "fixed",
  },
  mainBoundsCameraConnected: {

    position: "relative",
    height: "inherit",
    width: "inherit",
    backgroundColor: theme.palette.grey4.main,
  },
  videoBounds : {
    height: "100%",
    position: "relative",
    background: "url('"+playerSpinner+"') "+theme.palette.grey4.main+" no-repeat center center",
    backgroundSize: "contain",
    // backgroundAttachment: "fixed",
  },
  waitingMessageMainVideoRoom: {
    position: "absolute",
    bottom: "100px",
    left: "50%",
    transform: "translate(-50%)",
  }
});

class MainVideoRoomComponent extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.hasBeenUpdated = false;
    this.layout = new ReflexPlayerLayout();

    this.state = {
      myCallSessionId: "",
      myUserName: "",
      callSession: undefined,
      localUser: undefined,
      subscribers: [],
      dimension: this.browserUtil.getWindowDimensions(),
      cameraConnected: false,
      toDisplaySubscriber: []
    };
    this.joinSession = this.joinSession.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.closeDialogExtension = this.closeDialogExtension.bind(this);
    this.checkSize = this.checkSize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onbeforeunload);
    window.addEventListener("resize", this.updateLayout);
  }
  
  resizeVideo() {
    if (this.state.toDisplaySubscriber.length > 0) {
      var playerHeight = this.state.dimension.height;
      var pch = (1 - this.props.ratio) * playerHeight + 40;
      var vch = (this.props.ratio * playerHeight) - 40 ;
      var videoRatio = 1.778;
      var $pc = $("#videoRoomContainer");
      var vcw = this.state.dimension.width - 4;
      var $vp = $("#video-"+this.state.toDisplaySubscriber[0].stream.streamId);
      var vph = vcw / videoRatio;

      if (vph > vch) {
          $vp.height(vch);
          $vp.width(vch * videoRatio);
      } else {
          $vp.height(vph);
          $vp.width(vph * videoRatio);
      }
    }  
  }
  
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
    window.removeEventListener("resize", this.updateLayout);
  }

  doReflexComponentDidUpdate(result) {
    if (result.state) {
      
      if ( result.state.subscribers !== undefined) {
        let subscribers = result.state.subscribers.filter((sub) => {
          return sub.isStreamer()
        })
        
        if (result.state.subscribers.length > 1) {   
          // if we have more than 1 streamer in our subscribers, find the one with the localuser's language 
          let streamerSubscriber = subscribers.find((sub) => {
            return sub.getLanguage() === this.props.localUser.getLanguage()
          })
          // if there is no streamer with the localuser's language, default to the english stream
          if (streamerSubscriber === undefined) {
            streamerSubscriber = subscribers.find((sub) => {
              return sub.getLanguage() === "en"
            })
          }
          // if there is no english stream, default to the first streamer in the list
          if (streamerSubscriber === undefined) {
            streamerSubscriber = subscribers[0]
          }
          if (subscribers[0] !==  undefined) {
            subscribers = [ streamerSubscriber ]
          } else {
            subscribers = []
          }
          
        }
        result.state.toDisplaySubscriber = subscribers
      }

      this.setState(result.state)
    }
      
    if (result.layoutToUpdate) {
      this.updateLayout();
    }
  }

  componentDidUpdate(prevProps) {
    this.doReflexComponentDidUpdate(
      reflexComponentDidUpdate(this.props, prevProps)
    );
    if (prevProps.ratio !== this.props.ratio) {
      this.updateLayout();
    }
  }

  onbeforeunload(event) {
    this.props.dispatch(reflexWsDisconnect());
  }

  checkSize() {
  
  }

  updateLayout() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
    setTimeout(() => {
      
      this.resizeVideo();
    }, 20);
  }
  
  closeDialogExtension() {
    this.setState({ showExtensionDialog: false });
  }

  //TODO remove
  /////////////////////////////////////////////////////////////////////
  joinSession() {}

  screenShare() {}

  stopScreenShare() {}

  checkSomeoneShareScreen() {
    
  }

  render() {
    const { classes, theme } = this.props;
    const myCallSessionId = this.state.myCallSessionId;
    const localUser = this.state.localUser;
    var playerWidth = this.state.dimension.width;
    var playerHeight = this.state.dimension.height;
    var videoroomHeight = this.props.ratio * playerHeight;
    var lowerHeight = playerHeight - videoroomHeight;

    return (
      <Box  >

        <DialogExtensionComponent
          showDialog={this.state.showExtensionDialog}
          cancelClicked={this.closeDialogExtension}
        />
        <Box height={videoroomHeight} id="mainStreamerVideoContainer">
          <Box id="layout" className={this.state.toDisplaySubscriber.length > 0?classes.mainBoundsCameraConnected:classes.mainBounds}>
            {this.state.toDisplaySubscriber.length === 0 && (<Typography variant="h4" className={classes.waitingMessageMainVideoRoom}>{i18nInstance.t("WebRTCMainVideo|waitingMessage")}</Typography>)}
            {this.state.toDisplaySubscriber.map((sub, i) => sub !== undefined && (
              <Box
                key={i}
                className="OT_root OT_publisher custom-class"
                id="remoteUsers"
              >
                <StreamComponent
                  isMainVideo={true}
                  user={sub}
                  streamId={sub.streamManager.stream.streamId}
                  overrideClass={classes.videoBounds}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionReady: state.websocket.connection.events.sessionReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  subscriberEvent: state.websocket.callEvents.subscriberEvent,
  refresh: state.ui.refresh,
  localUserRefresh: state.websocket.callEvents.localUserRefresh,
  cameraConnected: state.websocket.connection.cameraConnected,
});

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(MainVideoRoomComponent)));
