import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  errorMessage: {
    color: theme.palette.secondary.main,
  },
}));

const Form = ({ handleClose, login, errorMessage, passwordProtected }) => {
  const { t, i18next } = useTranslation();
  const classes = useStyles();
  // create state variables for each input
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
    handleClose();
  };

  return (
    <Box className={classes.root}>
      <form
        style={{ justifyContent: "center", alignItems: "center",flexDirection: "column",display: passwordProtected ? "flex" : "none" }}
        className={classes.root}
        onSubmit={handleSubmit}
        autoComplete="one-time-code"
      >
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        <TextField
          label={t("LoginContributor|Email")}
          variant="filled"
          type="email"
          required
          value={email}
          autoComplete="one-time-code"
          readonly
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label={t("LoginContributor|Password")}
          variant="filled"
          type="password"
          required
          value={password}
          autoComplete="one-time-code"
          readonly
          onChange={(e) => setPassword(e.target.value)}
        />
        <div>
          <Button type="submit" variant="contained" color="primary">
            {t("LoginContributor|Login")}
          </Button>
        </div>
      </form>
      <form style={{ justifyContent: "center", alignItems: "center",flexDirection: "column", display: !passwordProtected ? "flex" : "none" }}
        className={classes.root}
        onSubmit={handleSubmit}
        autoComplete="one-time-code">
        <Typography >{t("LoginContributor|LoginTitle")}</Typography>
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        <div>
          <Button type="submit" variant="contained" color="primary">
            {t("LoginContributor|Login")}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default Form;
