import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import UserAvatar from "../users/UserAvatar";
import { useTranslation } from "react-i18next";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import {
  getUserAvatar,
  getUserDetails,
  getUserStatus,
} from "../../../../utils/callControllerUtils";
import { useSelector } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyle = makeStyles((theme) => ({
  root: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "5px",
  },
  rootOnair: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: theme.palette.secondary.main,
    paddingLeft: "5px",
  },
  deleteButton: {
    padding:"0px",
    paddingLeft:"5px",
    "& svg": {
        fontSize: "2.5vh",
      },
  },
  userName: {
    "& span": {
      fontSize: "1.4vh",
    },
  },
  userNameCC: {
    "& span": {
      fontSize: "1.5vh",
      color: theme.palette.reflexBlue.main,
    },
  },
  iconContainer: {
    marginRight: "-10px",
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    width: "4vh",
    height: "4vh",
  },
  buttonContainer : {
      marginRight: "-15px"
  }
}));

/**
 * a list item representing a user in the intercom
 *
 * props:
 *  participant:
 *      userid: string
 *      avatar: string
 *      username: string
 *  disabled: bool
 *  onDelete : (id) => void
 *
 */
export function ModerationUserComponent(props) {
  const { t } = useTranslation();
  const classes = useStyle();
  const storeParticipants = useSelector((state) => {
    const participants = Object.values(state.websocket.participants || {});
    const moderators = Object.values(state.websocket.callModerators || {});
    return participants.concat(moderators);
  });
  const call = useSelector((state) => state.websocket.call);

  const stringAvatar = (participant) => {
    return participant.username.charAt(0).toUpperCase();
  };
  

  let rootClass = classes.root;
  let userDetails = {
    online: false,
  };
  let userStatus = {
    isOnAir: false,
  };
  if (storeParticipants) {
    userDetails = getUserDetails(storeParticipants, props.participant);
    userStatus = getUserStatus(
      props.participant,
      call.onAir,
      {
        partyLine: {},
        controlRoom: {},
        moderated: {},
        director: {},
        moderations: {},
      },
      {},
      call.isOnAir,
      true
    );
  }

  if (userStatus.isOnAir) {
    rootClass = classes.rootOnair;
  } else {
    rootClass = classes.root;
  }

  const handleDelete = () => {
    props.removeUser(props.participant.userid)
  }

  return (
    <ListItem className={rootClass} key={props.participant.userid}>
      <ListItemIcon className={classes.iconContainer}>
        <UserAvatar
          className={classes.avatar}
          online={userDetails.online}
          src={getUserAvatar(props.participant.userid)}
        >
          {stringAvatar(props.participant)}
        </UserAvatar>
      </ListItemIcon>
      <ListItemText
        className={
          props.participant.type === "CALL_MODERATOR"
            ? classes.userNameCC
            : classes.userName
        }
        primary={props.participant.username}
      />
      { !props.userIsProprietor &&
        (<ListItemSecondaryAction className={classes.buttonContainer}>
          <IconButton
          className={classes.deleteButton}
            onClick={handleDelete}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>)
      }
      
    </ListItem>
  );
}
