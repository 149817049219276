import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { store } from "react-notifications-component";
import { Button } from "@material-ui/core";
import UserAvatar from "../users/UserAvatar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 250,
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
    display: "inline-block"
  },
  header: {
    // fontSize: "0.5em",
    
    "& span" : {
      fontSize: "0.7vw",
    }
  },
  avatarBig: {
    backgroundColor: theme.palette.reflexYellow.main,
    height: "2vw",
    width: "2vw",
  },
  hiddenDiv: {
    display: "none",
  },
  expiringClass: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
  },
  expiredClass: {
    color: theme.palette.secondary.main,
    fontSize: "0.9vw",
  },
  buttonClass : {
    fontSize: "0.5vw",
  },
  passwordProtected :{
    fontSize: "0.9vw",
  }
}));

export default function WebcamContributorToken(props) {
  const { t, i18next } = useTranslation();
  const classes = useStyles();

  const stringAvatar = () => {
    return props.contributorToken.token.name.charAt(0).toUpperCase();
  };
  const validateDate = (date) => {
    const dateToCHeck = new Date(date);
    var now = new Date();
    if (date !== "0" && dateToCHeck < now) {
      return false;
    }
    return true;
  };
  const passwordProtected = props.contributorToken.token.passwordProtected;

  let expiring = "Never";
  let passwordProtectedText = "Not protected";

  if (props.contributorToken.token.date === "0") {
    expiring = t("ContributorsComponent|Never");
  } else {
    expiring = props.contributorToken.token.date;
  }
  if (passwordProtected) {
    passwordProtectedText = t("ContributorsComponent|passwordProtected");
  } else {
    passwordProtectedText = t("ContributorsComponent|notProtected");
  }
  const sendEmail = (event) => {
    if (
      props.contributorToken.token.date === "0" ||
      validateDate(props.contributorToken.token.date)
    ) {
      props.openSendEmail(props.contributorToken);
    } else {
      //notification the token is expired
      store.addNotification({
        title: t("ContributorsComponent|Error"),
        message: t("ContributorsComponent|DateExpiredErrorMessage"),
        type: "danger",
        insert: "center",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  const copyLink = (event) => {
    if (
      props.contributorToken.token.date === "0" ||
      validateDate(props.contributorToken.token.date)
    ) {
      navigator.clipboard.writeText(props.contributorToken.url);
      store.addNotification({
        title: t("ContributorsComponent|success"),
        message: t("ContributorsComponent|urlCopied"),
        type: "success",
        insert: "center",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      //notification the token is expired
      store.addNotification({
        title: t("ContributorsComponent|Error"),
        message: t("ContributorsComponent|DateExpiredErrorMessage"),
        type: "danger",
        insert: "center",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <UserAvatar
            aria-label="avatar"
            className={classes.avatarBig}
            online={false}
          >
            {stringAvatar()}
          </UserAvatar>
        }
        className={classes.header}
        title={props.contributorToken.token.name}
        subheader={props.contributorToken.token.username}
      />
      <CardContent>
        <Typography
          className={
            validateDate(props.contributorToken.token.date)
              ? classes.expiringClass
              : classes.expiredClass
          }
        >
          {t("ContributorsComponent|Expiring") + expiring}
        </Typography>
        <Typography className={classes.passwordProtected}>{passwordProtectedText}</Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => props.openDeleteConfirmation(props.contributorToken)} className={classes.buttonClass}
        >
          {t("ContributorsComponent|Delete")}
        </Button>
        <Button onClick={sendEmail} className={classes.buttonClass}>
          {t("ContributorsComponent|SendEmail")}
        </Button>
        <Button onClick={copyLink} className={classes.buttonClass}>
          {t("ContributorsComponent|CopyLink")}
        </Button>
      </CardActions>
      <input
        id={"hidden" + props.contributorToken.token.username}
        type="test"
        value={props.contributorToken.url}
        className={classes.hiddenDiv}
      />
    </Card>
  );
}
