import React, { useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import screenImage from "../../assets/images/screen-sharing-icon.png";
import i18nInstance from "../../i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: "50px",
    left: "50px",
    width: "200px",
    height: "157px",
    position: "absolute",
    backgroundImage: "url('" + screenImage + "')",
  },
  videoContainer: {
    position: "relative !important",
    width: " 100% ! important",
    height: "auto ! important",
    display: "block",
    margin: "auto",
    cursor: "pointer",
  },
  videoParentContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    width: "182px",
    height: "120px",
  },
  startStopText: {
    position: "absolute",
    top: "-2px",
    // left: "10px",
    width: "182px",
    height: "111px",
    backgroundColor: "rgba(0,0,0,0.5)",
    cursor: "pointer",
    borderRadius: "0px",
    fontWeight: "bold",
  },
}));

const ScreenSharingMonitor = ({
  screenSharingStarted,
  screenSharingAllowed,
  startScreenShare,
  stopScreenShare,
}) => {
  const { t, i18next } = useTranslation();
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      style={{ display: screenSharingAllowed ? "block" : "none" }}
    >
      <Box className={classes.videoParentContainer}>
        <video
          autoPlay={true}
          id={"screenSharingVideo"}
          muted={true}
          className={classes.videoContainer}
        />
        <Button
          onClick={startScreenShare}
          className={classes.startStopText}
          style={{ display: screenSharingStarted ? "none" : "block" }}
        >
          {i18nInstance.t("monitorScreenShare|startScreenShare")}
        </Button>
        <Button
          onClick={stopScreenShare}
          className={classes.startStopText}
          style={{ display: screenSharingStarted ? "block" : "none" }}
        >
          {i18nInstance.t("monitorScreenShare|stopScreenShare")}
        </Button>
      </Box>
    </Box>
  );
};

export default ScreenSharingMonitor;
