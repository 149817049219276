import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  RemoveUsersFromCallButton,
  AddUsersToIntercomButton,
  AllowScreenShareButton,
  ModerateUsersButton,
  AddUsersToDirectorButton,
  AddUsersToPartyLineButton,
} from "../../../buttons/commonButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: theme.palette.darkbackground.main,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: theme.palette.reflexBlue.main,
  },
}));

export default function QueuedFunctionsCard(props) {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  
  
  return (
    <Card elevation={0} className={classes.root}>

      <CardActions style={{justifyContent: 'center'}} disableSpacing>
        <RemoveUsersFromCallButton />
        <AllowScreenShareButton />
        <ModerateUsersButton />
        
      </CardActions>
      <CardActions style={{justifyContent: 'center'}}  disableSpacing>
      <AddUsersToIntercomButton />
        <AddUsersToPartyLineButton/>
        <AddUsersToDirectorButton />
      </CardActions>
     
    </Card>
  );
}
