import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Autocomplete } from "@material-ui/lab/";
import { TextField, Box, Badge } from "@material-ui/core/";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import CallModeratorTag from "../users/CallModeratorTag";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    "& input" : {
      fontSize: "1.6vh",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.6vh",
    },
    
   
  },
  

});
class IntercomSlotComponent extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      intercomSlot: [],
      dimension: this.browserUtil.getWindowDimensions(),
    };
    this.handleResize = this.handleResize.bind(this);
    this.setSelectedIntercomSlot = this.setSelectedIntercomSlot.bind(this);
    this.setSelectedIntercomInputValue =
      this.setSelectedIntercomInputValue.bind(this);
    this.getModerationOwner = this.getModerationOwner.bind(this);
  }
  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);
    if (prevProps.intercomSlot !== this.props.intercomSlot) {
      var intercomSlot = [];

      for (var key in this.props.intercomSlot) {
        var value = JSON.parse(JSON.stringify(this.props.intercomSlot[key]));
        value["id"] = key;
        intercomSlot.push(value);
      }

      state.intercomSlot = intercomSlot;
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  setSelectedIntercomSlot(event, value) {
    if (this.props.setSelectedIntercomSlot !== undefined) {
      this.props.setSelectedIntercomSlot(event, value);
    }
  }
  getModerationOwner(value) {
    if (value !== null && value !== undefined) {
      let jsonObj = JSON.parse(value);
      return jsonObj.name;
    } else {
      return "";
    }
  }
  setSelectedIntercomInputValue(event, newInputValue) {
    if (this.props.setSelectedIntercomInputValue !== undefined)
      this.props.setSelectedIntercomInputValue(event, newInputValue);
  }

  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  render() {
    const { classes, theme } = this.props;
    var title = this.props.intercomSlotName;
    var intercomSlotId = this.props.intercomSlotId;
    var width = this.state.dimension.width / 13 + "px";
    function getOption(option) {
      var optionToreturn = option.username;
      if (intercomSlotId === "moderations") {
        //TODO Render a option component with command
    
        optionToreturn = JSON.stringify(option);
      }
      return optionToreturn;
    }

    return (
      <Box
        style={{
          transition: "all .5s ease-in-out",
          display: "flex",
        }}
        id="intercomSlotComponent"
      >

        <Autocomplete
          id={this.props.intercomSlotName}
          className={classes.root}
          getOptionLabel={(option) => this.getModerationOwner(option)}
          options={this.state.intercomSlot.map((option) => getOption(option))}
          fullWidth
          freeSolo
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "0px",
          }}
          onChange={(event, value) =>
            this.setSelectedIntercomSlot(this.props.type, value)
          }
          onInputChange={(event, newInputValue) => {
            this.setSelectedIntercomInputValue(
              this.props.inputValueType,
              newInputValue
            );
          }}
          renderOption={(option) => (
            <CallModeratorTag option={JSON.parse(option)} />
          )}
          inputValue={this.props.inputValue}
          value={this.props.selected}
          renderInput={(params) => (
            <TextField
              {...params}
              label={title}
              margin="normal"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
          disabled={this.props.disabled}
        />
      </Box>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  intercomSlot: state.websocket.intercom[thisProps.intercomSlotId],
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(IntercomSlotComponent))
);
