import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Box, Divider, Grid } from "@material-ui/core/";
import CallCommandComponent from "./components/CallCommandComponent";
import IntercomCommandComponent from "./components/IntercomCommandComponent";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import UserCommandComponent from "./components/UserCommandComponent";

class PatchBayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  render() {
    return (
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={4}>
          <CallCommandComponent
            notConnectedSelected={null}
            participantSelected={null}
            callModeratorSelected={null}
            streamersSelected={null}
            webcamPlayerSelected={null}
            moderatedSelected={null}
            contributorSelected={null}
          />
        </Grid>
        <Grid item xs={4}>
          <IntercomCommandComponent
            notConnectedSelected={null}
            participantSelected={null}
            callModeratorSelected={null}
            streamersSelected={null}
            webcamPlayerSelected={null}
            moderatedSelected={null}
            contributorSelected={null}
          />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
});

export default withTranslation()(connect(mapStateToProps)(PatchBayComponent));
