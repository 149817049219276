import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { store } from "react-notifications-component";
import i18nInstance from "../i18n";

export function localUserErrorNotification(error) {
  console.log("###################### error: ", error);

  let title = "";
  let message = "";
  switch (error.errorType) {
    //Session Errors
    case "BROWSER_NOT_SUPPORTED":
      title = i18nInstance.t("errorNotification|BROWSER_NOT_SUPPORTED_TITLE");
      message = i18nInstance.t(
        "errorNotification|BROWSER_NOT_SUPPORTED_MESSAGE"
      );
      break;
    case "OPENVIDU_NOT_CONNECTED":
      title = i18nInstance.t("errorNotification|OPENVIDU_NOT_CONNECTED_TITLE");
      message = i18nInstance.t(
        "errorNotification|OPENVIDU_NOT_CONNECTED_MESSAGE"
      );
      break;
    // Publish error
    case "OPENVIDU_PERMISSION_DENIED":
      title = i18nInstance.t(
        "errorNotification|OPENVIDU_PERMISSION_DENIED_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|OPENVIDU_PERMISSION_DENIED_MESSAGE"
      );
      break;
    //Devices errors
    case "DEVICE_ACCESS_DENIED":
      title = i18nInstance.t("errorNotification|DEVICE_ACCESS_DENIED_TITLE");
      message = i18nInstance.t(
        "errorNotification|DEVICE_ACCESS_DENIED_MESSAGE"
      );
      break;
    case "DEVICE_ALREADY_IN_USE":
      title = i18nInstance.t("errorNotification|DEVICE_ALREADY_IN_USE_TITLE");
      message = i18nInstance.t(
        "errorNotification|DEVICE_ALREADY_IN_USE_MESSAGE"
      );
      break;

    case "INPUT_AUDIO_DEVICE_GENERIC_ERROR":
      title = i18nInstance.t(
        "errorNotification|INPUT_AUDIO_DEVICE_GENERIC_ERROR_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|INPUT_AUDIO_DEVICE_GENERIC_ERROR_MESSAGE"
      );
      break;

    case "INPUT_AUDIO_DEVICE_NOT_FOUND":
      title = i18nInstance.t(
        "errorNotification|INPUT_AUDIO_DEVICE_NOT_FOUND_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|INPUT_AUDIO_DEVICE_NOT_FOUND_MESSAGE"
      );
      break;

    case "INPUT_VIDEO_DEVICE_NOT_FOUND":
      title = i18nInstance.t(
        "errorNotification|INPUT_VIDEO_DEVICE_NOT_FOUND_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|INPUT_VIDEO_DEVICE_NOT_FOUND_MESSAGE"
      );
      break;

    case "NO_INPUT_SOURCE_SET":
      title = i18nInstance.t("errorNotification|NO_INPUT_SOURCE_SET_TITLE");
      message = i18nInstance.t("errorNotification|NO_INPUT_SOURCE_SET_MESSAGE");
      break;

    case "PUBLISHER_PROPERTIES_ERROR":
      title = i18nInstance.t(
        "errorNotification|PUBLISHER_PROPERTIES_ERROR_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|PUBLISHER_PROPERTIES_ERROR_MESSAGE"
      );
      break;

    case "SCREEN_CAPTURE_DENIED":
      title = i18nInstance.t("errorNotification|SCREEN_CAPTURE_DENIED_TITLE");
      message = i18nInstance.t(
        "errorNotification|SCREEN_CAPTURE_DENIED_MESSAGE"
      );
      break;

    case "SCREEN_EXTENSION_DISABLED":
      title = i18nInstance.t(
        "errorNotification|SCREEN_EXTENSION_DISABLED_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|SCREEN_EXTENSION_DISABLED_MESSAGE"
      );
      break;

    case "SCREEN_EXTENSION_NOT_INSTALLED":
      title = i18nInstance.t(
        "errorNotification|SCREEN_EXTENSION_NOT_INSTALLED_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|SCREEN_EXTENSION_NOT_INSTALLED_MESSAGE"
      );
      break;

    case "SCREEN_SHARING_NOT_SUPPORTED":
      title = i18nInstance.t(
        "errorNotification|SCREEN_SHARING_NOT_SUPPORTED_TITLE"
      );
      message = i18nInstance.t(
        "errorNotification|SCREEN_SHARING_NOT_SUPPORTED_MESSAGE"
      );
      break;
  }
  store.addNotification({
    title: title,
    message: message,
    type: "danger",
    insert: "center",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function addOrRemoveMeFromWhispering(CMuserid, localUserID, inWhisper) {
  const state = websocketServices.reduxStore.default.getState();

  const data = {
    CMuserid: CMuserid,
    userid: localUserID,
    inWhisper: inWhisper,
  };
  const moderatorConnectionEntry = Object.entries(
    state.websocket.callModerators
  ).find(([_key, value]) => value.userid === CMuserid);

  let moderatorConnectionId = undefined;
  if (moderatorConnectionEntry !== undefined) {
    moderatorConnectionId = moderatorConnectionEntry[0];
    websocketServices.signals.sendSignal(data, "intercom:REQUEST", [
      { connectionId: moderatorConnectionId },
    ]);
  } else {
    // uh-oh
    console.error(`${CMuserid} has no connection id`);
  }
}
