import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { setSelectedWebcamId } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import {CommandsButton} from "../../../../commons/CommandsButton"
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    borderRadius: 15,
    border: 0,
    fontSize: 8,
  },
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  select: {
    fontSize: 8,
  },
  item: {
    // fontSize: 10,
  },
  label: {
    fontSize: 10,
  }
}));

export default function WebcamSelectComponent() {
  const reduxStore = require("@reflex-interactions/library-reflex-client/dist/reflexAPI/store/");
  const classes = useStyles();
  const [webcamSlot, setWebcamSlot] = React.useState("");
  const [open, setOpen] = React.useState(false);
  function dispatch(returnValue) {
    reduxStore.default.dispatch(setSelectedWebcamId(returnValue));
  }
  const handleChange = (event) => {
    setWebcamSlot(event.target.value);
    var returnValue = null;
    if (event.target.value !== null && event.target.value !== undefined && event.target.value !== '') {
      returnValue = event.target.value
    }
    dispatch(returnValue)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      {/* <CommandsButton className={classes.button} onClick={handleOpen}>
        Webcam Input
      </CommandsButton> */}
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="webcamSlot-label">Webcam Input</InputLabel>
        <Select
          labelId="webcamSlot-select-label"
          id="webcamSlot-select"
          className={classes.select}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={webcamSlot}
          onChange={handleChange}
        >
          <MenuItem className={classes.item} value="">
            <em>None</em>
          </MenuItem>
          <MenuItem className={classes.item} value={1}>1</MenuItem>
          <MenuItem className={classes.item} value={2}>2</MenuItem>
          <MenuItem className={classes.item} value={3}>3</MenuItem>
          <MenuItem className={classes.item} value={4}>4</MenuItem>
          <MenuItem className={classes.item} value={5}>5</MenuItem>
          <MenuItem className={classes.item} value={6}>6</MenuItem>
          <MenuItem className={classes.item} value={7}>7</MenuItem>
          <MenuItem className={classes.item} value={8}>8</MenuItem>
          <MenuItem className={classes.item} value={9}>9</MenuItem>
          <MenuItem className={classes.item} value={10}>10</MenuItem>
          <MenuItem className={classes.item} value={100}>Screen</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
