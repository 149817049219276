import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { openDialog } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { getUserAvatar } from "../../utils/callControllerUtils";

/**
 * props.open: boolean
 * props.dalog:
 *  onClose: (selectedValue) => void
 *  camDeviceSelectedId: string
 *  camDevices: {id:string, name:string}[]
 */
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  dialogTitleContainer: {
    display: "flex",
    fontSize: "1.0vw",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },
  dialogTitle: {
    color: theme.palette.reflexYellow.main,
    backgroundColor: theme.palette.darkbackground.main,
  },
  cancelButton: {
    borderRadius: "20px 20px 20px 20px",
    backgroundColor: theme.palette.grey5.main,
    fontSize: "1.5vw"
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    marginRight: "10px",
    width: "3vw",
    height: "3vw",
  },
  listItemNotSelected : {

  },
  listItemSelected : {
    backgroundColor: theme.palette.grey5.main,
    color: theme.palette.reflexYellow.main,
  },
  camName : {
    "& span" : {
      fontSize: "1.0vw"
    },
    
  }
}));
export default function ChoseUserCamDevice(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = (id) => {
    const data = {
      command: websocketServices.DEVICES_COMMANDS.CHANGE_CAMERA,
      camId: id,
    };
    websocketServices.signals.sendDevicesCommand(
      data,
      props.dialog.user.details.connectionId
    );
    handleClose();
  };

  const handleClose = () => {
    // export this as a reusable object (factory function?)
    var dialog = props.defaultDialog;
    props.dispatch(openDialog(dialog));
  };
  
  // if (props.dialog.user !== null) {
  //   avatar = props.dialog.user.avatar;
  // }
  let username = "";
  if (props.dialog.user !== null) {
    username = props.dialog.user.username;
  }
  const stringAvatar = () => {
    if (props.dialog.user !== null && props.dialog.user !== undefined)
      return props.dialog.user.username.charAt(0).toUpperCase();
    else return "";
  };
  let avatar = ""
  if (props.dialog?.user?.userid) {
    avatar = getUserAvatar(props.dialog?.user?.userid)
  }
  return (
    <div>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
        open={props.open}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box className={classes.dialogTitleContainer}>
            <Avatar aria-label="avatar" className={classes.avatar} src={avatar}>
              {stringAvatar()}
            </Avatar>
            {props.dialog.title + username + props.dialog.message}
          </Box>
        </DialogTitle>
        <List>
          {props.dialog.camDevices.map((camDevice) => (
            <ListItem
              className={props.dialog.selectedCamId === camDevice.id? classes.listItemSelected : classes.listItemNotSelected}
              button
              onClick={() => handleClick(camDevice.id)}
              key={camDevice.id}
            >
              <ListItemText primary={camDevice.name} className={classes.camName}/>
            </ListItem>
          ))}
        </List>
        <Box className={classes.buttonContainer}>
          <IconButton className={classes.cancelButton} onClick={handleClose}>
            {props.dialog.closeButton.title}
          </IconButton>
        </Box>
      </Dialog>
    </div>
  );
}
