import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@material-ui/core/";
import VideoRoomComponent from "../videoRoom/VideoRoomComponent";
import {
  callDidUpdate,
  DevicesSettingsManager,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import ModeratorToolBarComponent from "./components/ModeratorToolBarComponent";
import PatchBayComponent from "./components/patchBay/PatchBayComponent";
import ToolbarComponent from "../toolbar/ToolbarComponent";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import TutorialController from "../tutorials/TutorialController";
import CallManagerFooter from "./components/footer/CallManagerFooter";
import Users from "./components/users/UsersComponent";
import ParticipantCard from "./components/users/ParticipantCard";
import ChatComponent from "../chat/ChatComponent";
import DevicesSettings from "../commons/DevicesSettings";
import ModerationComponent from "./components/intercom/ModerationComponent";
import Contributors from "./components/contributors/Contributors";
import IntercomPanelContainer from "./components/intercom/IntercomPanelContainer";
import TimeSlotEditorContainer from "./components/webcamSlots/TimeSlotEditorContainer";
import CloseIcon from "@material-ui/icons/Close";
import i18nInstance from "../../i18n";
import {
  removeCallTimeSlot,
  swapCallTimeSlot,
  userErrorNotification,
} from "../../utils/callControllerUtils";
import { withStyles } from "@material-ui/core/styles";
import PulseLoader from "react-spinners/PulseLoader";
import { localUserErrorNotification } from "../../utils/participantUtils";
import { Redirect } from "react-router-dom";
import { UserSelection } from "./components/users/UserSelection/UserSelection";
import { ListTabs } from "./components/users/UserSelection/ListTabs";

const styles = (theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35vw",
    height: "20vh",
    maxHeight: "30vh",
    overflow: "hidden",
    backgroundColor: theme.palette.grey1.main,
  },
  header: {
    display: "inline-flex",
    width: "100%",
    height: "40px",
    backgroundColor: theme.palette.reflexGreen.main,
    position: "relative",
    textAlign: "center",
  },
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "primary",
    marginTop: "7px",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: "10px",
  },
  choseButton: {
    display: "flex",
    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    padding: "15px",
    textAlign: "center",
    height: "100%",
  },
});
class CallModerator extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      isOnAir: false,
      onAir: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        100: [],
      },
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      dimension: this.browserUtil.getWindowDimensions(),
      publishingReady: false,
      subscribingReady: false,
      contributorsView: false,
      callModerator: true,
      audioMixer: false,
      monitorWall: false,
      settings: false,
      localUser: undefined,
      devicesSettingsIsOpen: "none",
      deviceSettingsIsOnAir: false,
      cameraConnected: false,
      startIntercom: false,
      timeSlotOpened: false,
      modalNextOpen: false,
      initCompleted: false,
      monitorWallActive: false,
      alreadyLogin: false,
      selectedList: null,
    };
    this.handleResize = this.handleResize.bind(this);
    this.isAudioActive = this.isAudioActive.bind(this);
    this.isVideoActive = this.isVideoActive.bind(this);
    this.toggleDevicesSettings = this.toggleDevicesSettings.bind(this);
    this.reduxOpenDevicesSettings = this.reduxOpenDevicesSettings.bind(this);
    this.handleClosedTimeSlot = this.handleClosedTimeSlot.bind(this);
    this.closeModalNext = this.closeModalNext.bind(this);
    this.putNextTimeSlotOnAir = this.putNextTimeSlotOnAir.bind(this);
  }

  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);

    if (
      prevProps.localUserReady !== this.props.localUserReady &&
      this.props.localUserReady === true
    ) {
      state.localUser = websocketServices.localUser;
      state.subscribers = websocketServices.subscribers;
    }
    if (prevProps.devicesSettingsIsOpen !== this.props.devicesSettingsIsOpen) {
      this.reduxOpenDevicesSettings(this.props.deviceSettings);
    }
    if (prevProps.cameraConnected !== this.props.cameraConnected) {
      state.cameraConnected = this.props.cameraConnected;
    }
    if (prevProps.timeSlotOpenEvent !== this.props.timeSlotOpenEvent) {
      state.timeSlotOpened = true;
    }
    if (prevProps.modalNextOpenEvents !== this.props.modalNextOpenEvents) {
      state.modalNextOpen = true;
    }
    if (prevProps.userError !== this.props.userError) {
      userErrorNotification(this.props.userError);
    }
    if (prevProps.error !== this.props.error) {
      localUserErrorNotification(this.props.error);
    }
    if (prevProps.monitorWallActive !== this.props.monitorWallActive) {
      state.monitorWallActive = this.props.monitorWallActive;
    }
    if (prevProps.alreadyLogin !== this.props.alreadyLogin) {
      if (this.props.alreadyLogin === true) {
        state.alreadyLogin = this.props.alreadyLogin;
      }
    }
    if (prevProps.selectedList !== this.props.selectedList) {
      state.selectedList = this.props.selectedList;
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  isAudioActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isAudioActive &&
      this.state.localUser.isAudioActive()
    );
  }
  isVideoActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isVideoActive &&
      this.state.localUser.isVideoActive()
    );
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  toggleDevicesSettings() {
    if (!this.state.devicesSettingsOpened) {
      DevicesSettingsManager.getDevices().then((_devices) => {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: false,
          connectWebcamOnAir: false,
        });
      });
    } else {
      this.setState({
        devices: null,
        devicesSettingsOpened: false,
        connectWebcam: false,
        connectWebcamOnAir: false,
      });
    }
  }
  handleClosedTimeSlot() {
    this.setState({ timeSlotOpened: false });
  }
  closeModalNext() {
    this.setState({ modalNextOpen: false });
  }
  reduxOpenDevicesSettings(deviceSettings) {
    DevicesSettingsManager.getDevices().then((_devices) => {
      if (!deviceSettings.onIntercom) {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: deviceSettings.connectWebcam,
          connectWebcamOnAir: deviceSettings.onAir,
          startIntercom: deviceSettings.onIntercom,
        });
      } else {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: deviceSettings.connectWebcam,
          connectWebcamOnAir: deviceSettings.onAir,
          startIntercom: deviceSettings.onIntercom,
        });
      }
    });
  }

  putNextTimeSlotOnAir(putCurrentTimeSlotAtTheEnd) {
    if (putCurrentTimeSlotAtTheEnd) {
      const newTimeSlotIndex = this.state.onAir[1].length;
      swapCallTimeSlot(0, newTimeSlotIndex);
    } else {
      removeCallTimeSlot(0);
    }
    this.setState({ modalNextOpen: false });
  }

  render() {
    const { classes, theme } = this.props;
    const localUser = this.state.localUser;
    var chatDisplay = { display: this.props.chatDisplay };
    if (this.state.selectedList !==null) {
      chatDisplay = { display: 'none' };
    }
    var playerWidth = this.state.dimension.width;
    var playerHeight = this.state.dimension.height;
    var ratio = 0;
    if (
      this.state.cameraConnected === true ||
      this.state.monitorWallActive === true
    ) {
      ratio = 0.15;
    } else {
      ratio = 0;
    }
    var videoroomHeight = ratio * playerHeight;
    var lowerHeight = playerHeight - videoroomHeight;
    if (this.state.alreadyLogin) {
      return (
        <Redirect
          to={{
            pathname: "/alreadyLogin",
          }}
        />
      );
    }
    return (
      <Box
        className="reflexPlayer"
        id="container"
        style={{ display: "flex", marginTop: "40px" }}
      >
        <Box
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: !this.state.initCompleted ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000000",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderWidth: "0px",
            }}
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Trans>global|waitingInit</Trans>
            </Typography>
            <PulseLoader
              color="#ff2900"
              loading="true"
              style={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
                borderWidth: "0px",
              }}
              size={35}
            />
          </Box>
        </Box>

        <Modal
          open={this.state.devicesSettingsOpened}
          onClose={this.handleClosedDevicesSettings}
        >
          <DevicesSettings
            devices={this.state.devices}
            toggleDevicesSettings={this.toggleDevicesSettings}
            localUser={this.state.localUser}
            connectWebcam={this.state.connectWebcam}
            connectWebcamOnAir={this.state.connectWebcamOnAir}
            startIntercom={this.state.startIntercom}
          />
        </Modal>
        <Modal open={this.state.timeSlotOpened} hideBackdrop={true}>
          <TimeSlotEditorContainer
            localUser={this.state.localUser}
            handleClosedTimeSlot={this.handleClosedTimeSlot}
            dimension={this.state.dimension}
          />
        </Modal>

        <Modal open={this.state.modalNextOpen}>
          <Box className={classes.root}>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {i18nInstance.t("timeSlotComponent|modalTitleNext")}
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={this.closeModalNext}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.choseButton}
                onClick={() => this.putNextTimeSlotOnAir(false)}
              >
                {i18nInstance.t("timeSlotComponent|nextDelete")}
              </Button>
              <Button
                className={classes.choseButton}
                onClick={() => this.putNextTimeSlotOnAir(true)}
              >
                {i18nInstance.t("timeSlotComponent|nextMove")}
              </Button>
            </Box>
          </Box>
        </Modal>

        <TutorialController />
        <ToolbarComponent
          id="toolBar"
          sessionId={websocketServices.callSessionId}
          user={websocketServices.localUser}
          camStatusChanged={websocketServices.camStatusChanged}
          micStatusChanged={websocketServices.micStatusChanged}
          screenShare={this.screenShare}
          stopScreenShare={this.stopScreenShare}
          isaudioactive={"" + this.isAudioActive()}
          isvideoactive={"" + this.isVideoActive()}
          leaveSession={websocketServices.leaveSession}
          toggleDevicesSettings={this.toggleDevicesSettings}
        />
        <Grid
          id="reflexPlayerContainer"
          justifyContent="center"
          container
          spacing={0}
        >
          <Grid item xs={12} spacing={0}>
            <Box id="videoRoomContainer" height={videoroomHeight}>
              <VideoRoomComponent ratio={ratio} callModerator={true} />
            </Box>
          </Grid>

          <Grid item xs={12} spacing={0}>
            <Box
              id="moderatorToolBarContainer"
              boxShadow={5}
              width={playerWidth}
            >
              <ModeratorToolBarComponent width={playerWidth} />
            </Box>
          </Grid>

          {/* Contributors Manager */}
          <Box
            style={{
              marginLeft: 10,
              marginRight: 5,
              display: this.state.contributorsView ? "block" : "none",
            }}
          >
            <Contributors localUser={this.state.localUser} />
          </Box>

          {/* Call Moderator */}
          <Box
            style={{
              marginLeft: "auto",
              display: this.state.callModerator ? "block" : "none",
            }}
            width="100%"
          >
            <Grid item xs={12} spacing={0} container>
              <Grid item xs={5} spacing={1} container>
                <Grid item xs={12} id="userGridContainer">
                  {/* <Users /> */}
                  <ListTabs />
                </Grid>
                  <Grid style={{display: this.state.selectedList? "block": "none"}} item xs={12}>
                    <UserSelection dimension={this.state.dimension} />
                  </Grid>
               
       
                  <Grid style={{display: this.state.selectedList? "none": "block"}}  item xs={this.props.chatDisplay === "none" ? 12 : 6}>
                    <ParticipantCard
                      bigger={this.props.chatDisplay === "none"}
                      cameraConnected={this.state.cameraConnected}
                      monitorWallActive={this.state.monitorWallActive}
                    />
                  </Grid>
            

                
                    <Grid item xs={6} style={chatDisplay}>
                      {localUser !== undefined &&
                        localUser.getConnectionId !== undefined &&
                        localUser.getConnectionId() !== undefined && (
                          <Box style={chatDisplay}>
                            <ChatComponent
                              user={localUser}
                              chatDisplay={this.state.chatDisplay}
                              websocketServices={websocketServices}
                              cameraConnected={this.state.cameraConnected}
                              monitorWallActive={this.state.monitorWallActive}
                              dimension={this.state.dimension}
                            />
                          </Box>
                        )}
                    </Grid>
                 
              </Grid>

              <Grid item xs={7} spacing={1} container>
                <Grid item xs={3}>
                  {localUser !== undefined &&
                    localUser.getConnectionId !== undefined &&
                    localUser.getConnectionId() !== undefined && (
                      <ModerationComponent
                        localUser={localUser}
                        cameraConnected={this.state.cameraConnected}
                        monitorWallActive={this.state.monitorWallActive}
                      />
                    )}
                </Grid>
                <Grid item xs={9}>
                  {localUser !== undefined &&
                    localUser.getConnectionId !== undefined &&
                    localUser.getConnectionId() !== undefined && (
                      <IntercomPanelContainer
                        localUser={localUser}
                        cameraConnected={this.state.cameraConnected}
                        monitorWallActive={this.state.monitorWallActive}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Monitor Wall */}
          <Box
            style={{
              marginLeft: 10,
              marginRight: 5,
              display: this.state.monitorWall ? "block" : "none",
            }}
            height={lowerHeight}
          >
            <Typography variant="h3" color={"primary"}>
              TODO Monitor Wall and Audio Mixer
            </Typography>
          </Box>

          {/* Settings */}
          <Box
            style={{
              marginLeft: 10,
              marginRight: 5,
              display: this.state.settings ? "block" : "none",
            }}
            height={lowerHeight}
          >
            <Typography variant="h3" color={"primary"}>
              TODO Settings
            </Typography>
            <PatchBayComponent />
          </Box>
        </Grid>

        <CallManagerFooter />
        <Box id="audioContainer" style={{ display: "none" }} />
        {/* /////////////////////////////////////////////////////////////////////////////////
        //                     Modif Tensorflow */}
        {/* {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined && (
        <OvVideoComponentHidden user={localUser} style={{display: "none"}}/>)} */}
        {/* ///////////////////////////////////////////////////////////////////////////////// */}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  initCompleted: state.ui.initCompleted,
  chatDisplay: state.ui.chat.chatDisplay,
  isOnAir: state.websocket.call.isOnAir,
  onAir: state.websocket.call.onAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  publishingReady: state.websocket.connection.events.publishingReady,
  subscribingReady: state.websocket.connection.events.subscribingReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  callModerator: state.ui.callModeratorComponent.callModerator,
  monitorWall: state.ui.callModeratorComponent.monitorWall,
  audioMixer: state.ui.callModeratorComponent.audioMixer,
  contributorsView: state.ui.callModeratorComponent.contributorsView,
  settings: state.ui.callModeratorComponent.settings,
  devicesSettingsIsOpen: state.ui.devicesSettings.isOpenEvent,
  deviceSettings: state.ui.devicesSettings,
  cameraConnected: state.websocket.connection.cameraConnected,
  timeSlotOpenEvent: state.ui.callModeratorComponent.timeSlotOpenEvents,
  modalNextOpenEvents: state.ui.callModeratorComponent.modalNextOpenEvents,
  userError: state.websocket.connection.events.userError,
  error: state.websocket.connection.events.error,
  monitorWallActive: state.ui.callModeratorComponent.monitorWallActive,
  alreadyLogin: state.websocket.connection.alreadyLogin,
  selectedList: state.ui.callModeratorComponent.selectedList,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(CallModerator))
);
