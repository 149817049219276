import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import UserCard from "./UserCard";
import { Collapse } from "@material-ui/core";
import {
  getUserDetails,
  addUserToIntercom,
  allowScreenShare,
  moderateUser,
  removeUserFromCall,
  sendMessageToUser,
  setUserInWebcamSlot,
  muteUnmuteUserMic,
  muteUnmuteUserCam,
  setUserCamera,
  toggleUserCamera,
  setUserMicrophone,
  setUserResolution,
  setUserAllowScreenShare,
} from "../../../../utils/callControllerUtils";

class WebcamUserCard extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.removeUserFromCall = this.removeUserFromCall.bind(this);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      intercomSlot: [],
      webcamSlot: [],
      webcamSelected: null,
      webcamValue: "",
      webcamInputValue: "",
      intercom: {
        partyLine: {},
        controlRoom: {},
        director: {},
        moderations: {},
        moderated: {},
      },
      webcamIdSelected: null,
    };
  }
  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);
   
    if (prevProps.micStatusChanged !== this.props.micStatusChanged) {
      state.micStatusChanged = this.props.micStatusChanged;
    }
    if (prevProps.camStatusChanged !== this.props.camStatusChanged) {
      state.camStatusChanged = this.props.camStatusChanged;
    }

    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  removeUserFromCall() {
    removeUserFromCall(
      this.state.webcamSelected,
      this.props.webcamId
    );
  }
  componentDidMount() {}
  render() {
    var visible =
      this.state.webcamSelected !== null && this.state.webcamInputValue !== "";
    var user = {
      username: "",
      avatar: "",
      userid: "",
      details: {},
    };
    if (visible) {
      user = getUserDetails(this.state.participants, this.state.webcamSelected);
    }

    return (
      <Collapse in={visible}>
        <UserCard
          user={user}
          webcamId={this.props.webcamId}
          onAir={this.state.onAir}
          intercom={this.state.intercom}
          moderated={this.state.moderated}
          isOnAir={this.state.isOnAir}
          moderateUser={moderateUser}
          addUserToIntercom={addUserToIntercom}
          sendMessageToUser={sendMessageToUser}
          removeUserFromCall={this.removeUserFromCall}
          allowScreenShare={allowScreenShare}
          muteUnmuteUserMic={muteUnmuteUserMic}
          muteUnmuteUserCam={muteUnmuteUserCam}
          setUserCamera={setUserCamera}
          toggleUserCamera={toggleUserCamera}
          setUserMicrophone={setUserMicrophone}
          setUserResolution={setUserResolution}
          setUserAllowScreenShare={setUserAllowScreenShare}
        />
      </Collapse>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  intercomSlot: state.websocket.intercom[thisProps.intercomSlotId],
  webcamSlot: state.websocket.call.onAir[thisProps.webcamId],
  webcamInputValue:
    state.ui.callModeratorComponent.webcamInputValue[thisProps.webcamId],
  webcamSelected:
    state.ui.callModeratorComponent.webcamSelected[thisProps.webcamId],
  intercom: state.websocket.intercom,
  webcamIdSelected: state.ui.callModeratorComponent.webcamIdSelected,
});

export default withTranslation()(connect(mapStateToProps)(WebcamUserCard));
