import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Button, Box, Grid, Typography, Paper } from "@material-ui/core/";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {CommandsButton} from "../../../../commons/CommandsButton";
import { StyledTypography } from "../../../../commons/StyledTypography";
class UserCommandComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      webcamSelectIsOpen: false,
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  connectUser() {}
  switchCamera() {}
  switchMicrophone() {}
  volume() {}
  muteUnmute() {}
  changeResolution() {}
  splitUnplitScreenSharing() {}
  allowScreenShare() {}
  allowChatRoom() {}
  sendPrivateMessage() {}
  kickOutUser() {}
  banUnBanUser() {}
  render() {
    return (
      <Paper
        style={{
          transition: "all .5s ease-in-out",
          // backgroundColor: "#000000",
          // BorderAllRounded: true
          margin:5,
          padding:5,
        }}
      >
        <StyledTypography align='left' variant={"h6"} color={"reflexYellow"}>
          User Commands
        </StyledTypography>
        <Box
          marginLeft={"auto"}
          marginRight={"auto"}
          style={{ display: "flex" }}
        >
          <Grid
            id="userCommandsContainer"
            justify="space-around"
            container
            spacing={2}
          >
     

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.volume} color="primary">
                Volume
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.muteUnmute} color="primary">
                Mute/Unmute
              </CommandsButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
});

export default withTranslation()(
  connect(mapStateToProps)(UserCommandComponent)
);
