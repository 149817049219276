import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  getUserAvatar,
} from "../../../../utils/callControllerUtils";
import { withStyles } from "@material-ui/core/styles";
import UserAvatar from "./UserAvatar";

const styles = (theme) => ({
  root: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "5px",
  },
  rootOnair: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    marginBottom: "5px",
    borderColor: theme.palette.secondary.main,
    paddingLeft: "5px",
  },
  deleteButton: {
    padding: "0px",
    paddingLeft: "5px",
    "& svg": {
      fontSize: "2.5vh",
    },
  },
  userName: {
    "& span": {
      fontSize: "1.4vh",
    },
  },
  userNameCC: {
    "& span": {
      fontSize: "1.5vh",
      color: theme.palette.reflexBlue.main,
    },
  },
  iconContainer: {
    marginRight: "-10px",
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    width: "4vh",
    height: "4vh",
  },
  buttonContainer: {
    marginRight: "-15px",
  },
});
class CallModeratorTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      intercomSlot: [],
      webcamSlot: [],
      webcamSelected: null,
      webcamValue: "",
      webcamInputValue: "",
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
 
  componentDidMount() {}
  nextWebcam() {}
  handleDelete() {}
  stringAvatar() {
    return this.props.option?.name?.charAt(0).toUpperCase();
  }
  render() {
    const { classes, theme } = this.props;
    let rootClass = classes.root;

    return (
      <ListItem className={rootClass} key={this.props.option}>
        <ListItemIcon className={classes.iconContainer}>
          <UserAvatar
            className={classes.avatar}
            online={false}
            src={getUserAvatar(this.props.option.id)}
          >
            {this.stringAvatar()}
          </UserAvatar>
        </ListItemIcon>
        <ListItemText
          className={classes.userNameCC
          }
          primary={this.props.option.name}
        />
       
          <ListItemSecondaryAction className={classes.buttonContainer}>
            {/* <IconButton className={classes.deleteButton} onClick={undefined}>
              <ModerateUserButton />
            </IconButton> */}
          </ListItemSecondaryAction>
   
      </ListItem>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  intercomSlot: state.websocket.intercom[thisProps.intercomSlotId],
  webcamSlot: state.websocket.call.onAir[thisProps.webcamId],
  webcamInputValue:
    state.ui.callModeratorComponent.webcamInputValue[thisProps.webcamId],
  webcamSelected:
    state.ui.callModeratorComponent.webcamSelected[thisProps.webcamId],
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(CallModeratorTag))
);
