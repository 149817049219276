import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const styledBy = (property, mapping) => (props) => mapping[props[property]];
//Get the primary and secondary style from the theme palette style

function primary(theme) {

  if (theme.palette.type === 'dark') {
    return theme.palette.primary.main
  } else {
    return theme.palette.primary.light
  }

}
function secondary(theme) {
  if (theme.palette.type === 'dark') {
    return theme.palette.secondary.main
  } else {
    return theme.palette.secondary.light
  }
}

export const StyledTypography = withStyles((theme) => ({
  root: {
    
   
    color: styledBy("color", {
      default: primary(theme),
      primary: primary(theme),
      secondary: secondary(theme),
      reflexBlue: theme.palette.reflexBlue.main,
      reflexOrange: theme.palette.reflexOrange.main,
      reflexRed: theme.palette.reflexRed.main,
      reflexGreen: theme.palette.reflexGreen.main,
      reflexYellow: theme.palette.reflexYellow.main,
      reflexIndigo: theme.palette.reflexIndigo.main,
      reflexIndigoDark: theme.palette.reflexIndigoDark.main,
    }),

  },
}))(({ classes, color, ...other }) => (
  <Typography className={classes.root} {...other} />
));
