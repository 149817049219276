import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UserAvatar from "../callModerator/components/users/UserAvatar";

const useStyles = makeStyles((theme) => ({
  intercomButton: {
    color: theme.palette.secondary.main,
  },
  listContainer: {
    backgroundColor: theme.palette.grey1.main,
    padding: "5px",
    marginLeft: "-150px",
    borderRadius: "5px"
  },
  item: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: "rgba(0,0,0,0)",
  },
  itemInWhisper: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: theme.palette.reflexBlue.main,
  },
  itemInWhispering: {
    border: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: theme.palette.reflexYellow.main,
  },
}));

const stringAvatar = (username) => {
  return username
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

function IntercomStatusEntry({ user, onClick, localUser }) {
  const classes = useStyles();

  function getModeratorStatus() {
    const whispering = user.whispering;
    if (whispering === undefined || whispering.length === 0)
      return { classes: classes.item, inWhisper: false };
    else if (!whispering.find((id) => id === localUser.getUserId()))
      return { classes: classes.itemInWhisperinge, inWhisper: false };
    else return { classes: classes.itemInWhisper, inWhisper: true };
  }

  const status = getModeratorStatus();
  return (
    <ListItem
      button
      onClick={() =>
        onClick(user.userid, localUser.getUserId(), status.inWhisper)
      }
      className={status.classes}
    >
      <ListItemIcon className={classes.iconContainer}>
        <UserAvatar className={classes.avatar} src={user.avatar} online={false}>
          {stringAvatar(user.username)}
        </UserAvatar>
      </ListItemIcon>
      <ListItemText primary={user.username} />
    </ListItem>
  );
}

export function IntercomStatus(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const intercomStatus = useSelector((state) => {
    return {
      active: !!Object.values(state.websocket.intercom).find(
        (intercom) => intercom.active && intercom[props.localUser.getUserId()]
      ),
      moderators: Object.entries(state.websocket.intercom)
        .filter(
          ([key, _value]) =>
            !["moderations", "moderated"].find((it) => it === key)
        )
        .map(([_key, value]) => value)
        .filter((intercom) => props.localUser.getUserId() in intercom)
        .filter((intercom) => intercom.active)
        .flatMap((intercom) =>
          Object.entries(intercom)
            .filter(
              ([key, _value]) => !["active", "ptt"].find((it) => it === key)
            )
            .map(([_key, value]) => value)
        )
        .filter((user) => user.userType === "CALL_MODERATOR")
        .filter((user, index, arr) => arr.findIndex(u => u.userid === user.userid) === index), // ref https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
    };
  });
  const [open, setOpen] = useState(false);

  return (
    <Box id={props.id}>
      <Tooltip
        title={
          props.localUser.isCallModerator()
            ? t("intercomStatus|intercomStatusButtonTipCM")
            : t("intercomStatus|intercomStatusButtonTip")
        }
        arrow
      >
        <Button
          className={classes.intercomButton}
          onClick={() => setOpen(!open)}
          disabled={!intercomStatus.active}
        >
          {intercomStatus.active
            ? t("intercomStatus|intercomStatusButton")
            : t("intercomStatus|intercomStatusButtonInactive")}
        </Button>
      </Tooltip>
      <Collapse in={open && !props.localUser.isCallModerator()}>
        <List className={classes.listContainer}>
          {intercomStatus.moderators
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((moderator) => (
              <IntercomStatusEntry
                user={moderator}
                onClick={props.addOrRemoveMeFromWhispering}
                localUser={props.localUser}
              />
            ))}
        </List>
      </Collapse>
    </Box>
  );
}
