import { Badge, Card, CardHeader, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CONTRIBUTORS,
  CONTRIBUTORS_INPUT_VALUE,
  MODERATED,
  MODERATED_INPUT_VALUE,
  PARTICIPANTS,
  PARTICIPANTS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import { useSelector } from "react-redux";
import i18nInstance from "../../../../i18n";
import PartipantTag from "../users/PartipantTag";
import { UserPopper } from "../users/UserPopper";
import {
  setSelectedUser,
  setUserInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import UserAvatar from "../users/UserAvatar";
import { getUserAvatar, getUserDetails } from "../../../../utils/callControllerUtils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
paper: {
  backgroundColor: theme.palette.grey7.main
},
userSelected : {
  backgroundColor: theme.palette.grey5.main
},
avatar: {
  backgroundColor: theme.palette.reflexYellow.main
}

}));

export default function UsersLists(props) {
  const classes = useStyles(props);
  const { t } = useTranslation()
  const participantsInputValue = useSelector(
    (state) => state.ui.callModeratorComponent.participantsInputValue
  );
  const participantSelected = useSelector(
    (state) => state.ui.callModeratorComponent.participantSelected
  );
  const moderatedInputValue = useSelector(
    (state) => state.ui.callModeratorComponent.moderatedInputValue
  );
  const moderatedSelected = useSelector(
    (state) => state.ui.callModeratorComponent.moderatedSelected
  );
  const contributorsInputValue = useSelector(
    (state) => state.ui.callModeratorComponent.contributorsInputValue
  );
  const contributorSelected = useSelector(
    (state) => state.ui.callModeratorComponent.contributorSelected
  );

  const participants = useSelector((state) =>
    Object.values(state.websocket.participants)
  );

  const contributors = useSelector((state) =>
    Object.values(state.websocket.contributors)
  );

  const moderated = useSelector((state) =>
    Object.values(state.websocket.intercom.moderated)
  );

  const setSelected = (userType, value) => {
    var payload = { userType: userType, value: value };
    websocketServices.reduxStore.default.dispatch(setSelectedUser(payload));
  };
  const setInputValue = (type, newInputValue) => {
    var payload = { type: type, value: newInputValue };
    websocketServices.reduxStore.default.dispatch(setUserInputValue(payload));
  };
  
  let selected;
    if (participantSelected !== null && participantSelected !== undefined) {
      selected = participantSelected;
    }  else if (moderatedSelected !== null && moderatedSelected !== undefined) {
      selected = moderatedSelected;
    } else if (contributorSelected !== null && contributorSelected !== undefined) {
      selected = contributorSelected;
    }
    let user = {
        username: "",
        avatar: "",
        userid: "",
        details: {},
      };
      if (selected !== undefined) {
        user = getUserDetails(participants, selected);
      }
      const stringAvatar = () => {
        return user.username.charAt(0).toUpperCase();
      };
      const avatarCondition = getUserAvatar(user.userid) !== undefined
    if (user.username === "") {
      user.username = t("timeSlotComponent|choseUser")
    }
  return (
    <Paper
    className={classes.paper}
      style={{
        transition: "all .5s ease-in-out",
        marginTop: 30,
        marginBottom: 15,
        padding: 5,
      }}
    >
      <Grid
        justify="space-between"
        container
        spacing={1}
      >
        <Grid item xs={3}>
          <Badge
            badgeContent={participants.length}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ width: "100%" }}
          >
            <Autocomplete
              // id="participants-container"
              options={participants.map((option) => option)}
              getOptionLabel={(option) => option.username}
              onChange={(event, value) => setSelected(PARTICIPANTS, value)}
              onInputChange={(event, newInputValue) => {
                setInputValue(PARTICIPANTS_INPUT_VALUE, newInputValue);
              }}
              inputValue={participantsInputValue}
              value={participantSelected}
              fullWidth
              freeSolo
              PopperComponent={UserPopper}
              renderOption={(option, { selected }) => (
                <PartipantTag option={option} selected={selected} />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18nInstance.t("UsersComponent|Participants")}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  size="small"
                />
              )}
            />
          </Badge>
        </Grid>
        <Grid item xs={3}>
          <Badge
            badgeContent={moderated.length}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ width: "100%" }}
          >
            <Autocomplete
              freeSolo
              options={moderated.map((option) => option)}
              getOptionLabel={(option) => option.username}
              onChange={(event, value) => setSelected(MODERATED, value)}
              onInputChange={(event, newInputValue) => {
                setInputValue(MODERATED_INPUT_VALUE, newInputValue);
              }}
              PopperComponent={UserPopper}
              inputValue={moderatedInputValue}
              value={moderatedSelected}
              renderOption={(option, { selected }) => (
                <PartipantTag option={option} selected={selected} />
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18nInstance.t("UsersComponent|Moderated")}
                  margin="normal"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullWidth
                />
              )}
            />
          </Badge>
        </Grid>

        <Grid item xs={3}>
          <Badge
            badgeContent={contributors.length}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ width: "100%" }}
          >
            <Autocomplete
              freeSolo
              options={contributors.map((option) => option)}
              getOptionLabel={(option) => option.username}
              onChange={(event, value) => setSelected(CONTRIBUTORS, value)}
              onInputChange={(event, newInputValue) => {
                setInputValue(CONTRIBUTORS_INPUT_VALUE, newInputValue);
              }}
              PopperComponent={UserPopper}
              inputValue={contributorsInputValue}
              value={contributorSelected}
              renderOption={(option, { selected }) => (
                <PartipantTag option={option} selected={selected} />
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18nInstance.t("UsersComponent|Contributors")}
                  margin="normal"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullWidth
                />
              )}
            />
          </Badge>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.userSelected} >
            <CardHeader
              avatar={
                <UserAvatar
                  aria-label="avatar"
                  online={false}
                  src={getUserAvatar(user.userid)}
                  className={classes.avatar}
                >
                  {avatarCondition && stringAvatar()}
                </UserAvatar>
              }
              title={user.username}            
            />
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}
