import { load } from "@tensorflow-models/body-pix";
import { useEffect, useState } from "react";

export function useBodyPix() {
    const [bodypix, setBodypix] = useState(null)
    useEffect(() => {
        load().then(bp => {
            setBodypix(bp)
            console.info("loaded BodyPix model: ", bp)
        })
    }, [])
    return bodypix
}