import { AppBar, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { StyledTypography } from "../../../commons/StyledTypography";
import WebcamSlotsComponent from "../webcamSlots/WebcamSlotsComponent";
import packageJson from '../../../../../package.json';

const styles = (theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    fontSize: 15,
    margin: 0,
    padding: 5,
    color: theme.palette.primary,
    backgroundColor: theme.palette.darkbackground.dark,
    // minHeight: "13vh",
    // maxHeight: "13vh"
  },
});

class CallManagerFooter extends Component {
  render() {
    const { classes } = this.props;
    const year = new Date().getFullYear()
    return (
      <AppBar
        boxShadow={10}
        position="fixed"
        justify="center"
        className={classes.appBar}
        id="callControllerFooter"
      >
        <WebcamSlotsComponent id="webcam-slots-component" />
        <Divider variant="middle" />
        <StyledTypography style={{marginTop:5, fontSize:"1.4vh"}} variant='caption' color="primary">
        <Trans>footerAppBar|Currently using React</Trans> {React.version} <Trans>footerAppBar|and WebRTC Server</Trans> <Trans>version</Trans>{" | "} <Trans>playerVersion</Trans>  { packageJson.version} | Copyright &copy; {year} 9337-9899 Quebec inc.
        </StyledTypography>
      </AppBar>
    );
  }
}

export default withTranslation()(withStyles(styles)(CallManagerFooter));
// export default withTranslation()(FooterAppBar);
// export default withStyles(styles)(FooterAppBar);
