
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { useStore, useSelector } from 'react-redux';
import { setMonitorWall } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { useTranslation } from 'react-i18next';
import { switchMonitorWall } from '../../../utils/callControllerUtils';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    monitorWallButtonClass: {    
        color: props => props.active? theme.palette.secondary.main : theme.palette.primary.main,
    }
}))

export function MonitorWallButton(_props) {
    const { t } = useTranslation()
    const { dispatch } = useStore()
    const active = useSelector(state => state.ui.callModeratorComponent.monitorWallActive)
    const cameraConnected = useSelector(state => state.websocket.connection.cameraConnected) 

    const handleClick = () => {
        dispatch(setMonitorWall(!active))
        // setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    }

    useEffect(() => {
        switchMonitorWall()
    }, [active])

    

    const classes = useStyles({active})
    return (
        <Tooltip
            arrow
            title={t("CommonButtons|monitorWall") }
        >
            <IconButton 
                className={classes.monitorWallButtonClass}
                onClick={handleClick}
                disabled={cameraConnected}
            >
                <ViewComfyIcon/>
            </IconButton>
        </Tooltip>
    )
}
