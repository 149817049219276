import React from "react";
import { IconIcon, SvgIcon, Tooltip } from "@material-ui/core";

export function Webcam1Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,17h2V7h-4v2h2V17z M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z
           M19,19H5V5h14V19z"
      />
    </SvgIcon>
  );
}

export function Webcam2Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M15,15h-4v-2h2c1.1,0,2-0.9,2-2V9c0-1.1-0.9-2-2-2H9v2h4v2h-2c-1.1,0-2,0.9-2,2v4h6V15z"
      />
    </SvgIcon>
  );
}

export function Webcam3Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M15,15v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V9c0-1.1-0.9-2-2-2H9v2h4v2h-2v2h2v2H9v2h4C14.1,17,15,16.1,15,15z"
      />
    </SvgIcon>
  );
}

export function Webcam4Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13,17h2V7h-2v4h-2V7H9v6h4V17z M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5
          C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"
      />
    </SvgIcon>
  );
}

export function Webcam5Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M15,15v-2c0-1.1-0.9-2-2-2h-2V9h4V7H9v6h4v2H9v2h4C14.1,17,15,16.1,15,15z"
      />
    </SvgIcon>
  );
}

export function Webcam6Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M11,17h2c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2h-2V9h4V7h-4C9.9,7,9,7.9,9,9v6C9,16.1,9.9,17,11,17z M11,13h2v2h-2V13z"
      />
    </SvgIcon>
  );
}

export function Webcam7Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
              M11,17l4-8V7H9v2h4l-4,8H11z"
      />
    </SvgIcon>
  );
}

export function Webcam8Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M11,17h2c1.1,0,2-0.9,2-2v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V9c0-1.1-0.9-2-2-2h-2C9.9,7,9,7.9,9,9v1.5
          c0,0.8,0.7,1.5,1.5,1.5C9.7,12,9,12.7,9,13.5V15C9,16.1,9.9,17,11,17z M11,9h2v2h-2V9z M11,13h2v2h-2V13z"
      />
    </SvgIcon>
  );
}

export function Webcam9Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
           M13,7h-2C9.9,7,9,7.9,9,9v2c0,1.1,0.9,2,2,2h2v2H9v2h4c1.1,0,2-0.9,2-2V9C15,7.9,14.1,7,13,7z M13,11h-2V9h2V11z"
      />
    </SvgIcon>
  );
}

export function Webcam10Icon(props) {
  return (
    <SvgIcon {...props}>
      <polygon points="9,17 11,17 11,7 7,7 7,9 9,9 		" />
      <path d="M15,7h-1c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h1c1.1,0,2-0.9,2-2V9C17,7.9,16.1,7,15,7z M15,15h-1V9h1V15z" />
      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
    </SvgIcon>
  );
}
