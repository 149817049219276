import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { TextField, Box, Grid } from "@material-ui/core/";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import WebcamUserCard from "../cards/WebcamUserCard";
import {
  WebcamDownButton,
  WebcamUpButton,
} from "../../../buttons/commonButtons";

class SelectedUsersCards extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      intercomSlot: [],
      dimension: this.browserUtil.getWindowDimensions(),
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  handleResize() {}
  componentDidMount() {}
  render() {
    return (
      <Box
        style={{
          transition: "all .5s ease-in-out",
        }}
      >
        <Grid
          id="usersCardsContainer"
          justify="space-evenly"
          container
          spacing={1}
        >
          <Grid xs={1} sm={1} md={1} lg={1} xl={1} item container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              >
                {/* &#8595; */}
              </WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam1" item xs={12}>
              <WebcamUserCard webcamId={1} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(2)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam2" item xs={12}>
              <WebcamUserCard webcamId={2} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(3)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam3" item xs={12}>
              <WebcamUserCard webcamId={3} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(4)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam4" item xs={12}>
              <WebcamUserCard webcamId={4} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(5)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam5" item xs={12}>
              <WebcamUserCard webcamId={5} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(6)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam6" item xs={12}>
              <WebcamUserCard webcamId={6} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(7)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam7" item xs={12}>
              <WebcamUserCard webcamId={7} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(8)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam8" item xs={12}>
              <WebcamUserCard webcamId={8} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(9)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam9" item xs={12}>
              <WebcamUserCard webcamId={9} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(10)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam10" item xs={12}>
              <WebcamUserCard webcamId={10} />
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container spacing={0}>
            <Grid item xs={12}>
              <WebcamDownButton
                // onClick={this.nextWebcam(100)}
                color="primary"
              ></WebcamDownButton>
              <WebcamUpButton
                // onClick={this.nextWebcam(1)}
                color="primary"
              ></WebcamUpButton>
            </Grid>
            <Grid id="userCardWebcam100" item xs={12}>
              <WebcamUserCard webcamId={100} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  intercomSlot: state.websocket.intercom[thisProps.intercomSlotId],
});

export default withTranslation()(connect(mapStateToProps)(SelectedUsersCards));
