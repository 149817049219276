import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { updateCallModeratorView } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({ 
  root : {
    backgroundColor: theme.palette.darkbackground.main,
    color: theme.palette.primary.main,
    minHeight: 30,
    margin: "0 0 0 0!important",
    padding: "0 !important"
  }
})
class ModeratorToolBarComponent extends Component {
  constructor(props) {
    super(props);
    this.showCallModerator = this.showCallModerator.bind(this);
    this.showMonitorWall = this.showMonitorWall.bind(this);
    this.showAudioMixer = this.showAudioMixer.bind(this);
    this.showcontributorsView = this.showcontributorsView.bind(this);
    this.showWebcamButon = this.showWebcamButon.bind(this);
    this.showSettings = this.showSettings.bind(this);
    this.state = {
      isOnAir: false,
      contributorsView: false,
      callModerator: true,
      audioMixer: false,
      monitorWall: false,
      webcamOnOff: false,
      settings: false,
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  showCallModerator() {
    var state = {
      callModerator: true,
      monitorWall: false,
      audioMixer: false,
      contributorsView: false,
    };
    this.props.dispatch(updateCallModeratorView(state));
  }
  showMonitorWall() {
    var state = {
      callModerator: false,
      monitorWall: true,
      audioMixer: false,
      contributorsView: false,
      settings: false,
    };
    this.props.dispatch(updateCallModeratorView(state));
  }
  showAudioMixer() {
    var state = {
      callModerator: false,
      monitorWall: false,
      audioMixer: true,
      contributorsView: false,
      settings: false,
    };
    this.props.dispatch(updateCallModeratorView(state));
  }
  showcontributorsView() {
    var state = {
      callModerator: false,
      monitorWall: false,
      audioMixer: false,
      contributorsView: true,
      settings: false,
    };
    this.props.dispatch(updateCallModeratorView(state));
  }
  showSettings() {
    var state = {
      callModerator: false,
      monitorWall: false,
      audioMixer: false,
      contributorsView: false,
      settings: true,
    };
    this.props.dispatch(updateCallModeratorView(state));
  }
  showWebcamButon() {}
  render() {
    const { classes, theme } = this.props;
    var callModeratorColor = "primary";
    var monitorWallColor = "primary";
    var audioMixerColor = "primary";
    var contributorsViewColor = "primary";
    var webcamOnOffColor = "primary";
    var settingsColor = "primary";

    if (this.state.callModerator) {
      callModeratorColor = "secondary";
    } else {
      callModeratorColor = "primary";
    }
    if (this.state.monitorWall) {
      monitorWallColor = "secondary";
    } else {
      monitorWallColor = "primary";
    }
    if (this.state.audioMixer) {
      audioMixerColor = "secondary";
    } else {
      audioMixerColor = "primary";
    }
    if (this.state.contributorsView) {
      contributorsViewColor = "secondary";
    } else {
      contributorsViewColor = "primary";
    }
    if (this.state.webcamOnOff) {
      webcamOnOffColor = "secondary";
    } else {
      webcamOnOffColor = "primary";
    }
    if (this.state.settings) {
      settingsColor = "secondary";
    } else {
      settingsColor = "primary";
    }

    return (
      <Toolbar className={classes.root}>
        <Grid container xs={12} spacing={0} justify={"center"}>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button style={{fontSize: "0.5vw"}} color={callModeratorColor} onClick={this.showCallModerator}>
              <Trans>moderatorToolbar|Call Moderator</Trans>
            </Button>
          </Grid>
          {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button style={{fontSize: "0.5vw"}} color={monitorWallColor} onClick={this.showMonitorWall}>
              <Trans>moderatorToolbar|Monitor Wall</Trans>
            </Button>
          </Grid> */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button style={{fontSize: "0.5vw"}} color={contributorsViewColor} onClick={this.showcontributorsView}>
            <Trans>moderatorToolbar|Contributors</Trans>
            </Button>
          </Grid>
          {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button style={{fontSize: "0.5vw"}} color={webcamOnOffColor} onClick={this.showWebcamButon}>
            <Trans>moderatorToolbar|Webcam On/OFF</Trans>
            </Button>
          </Grid> */}
          {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button style={{fontSize: "0.5vw"}} color={settingsColor} onClick={this.showSettings}>
            <Trans>moderatorToolbar|Settings</Trans>
            </Button>
          </Grid> */}
        </Grid>
      </Toolbar>
    );
  }
}
const mapStateToProps = (state) => ({
  callModerator: state.ui.callModeratorComponent.callModerator,
  monitorWall: state.ui.callModeratorComponent.monitorWall,
  audioMixer: state.ui.callModeratorComponent.audioMixer,
  contributorsView: state.ui.callModeratorComponent.contributorsView,
  settings: state.ui.callModeratorComponent.settings,
});

export default withStyles(styles)(withTranslation()(
  connect(mapStateToProps)(ModeratorToolBarComponent))
);
