import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { store } from "react-notifications-component";
import { Box,Typography } from "@material-ui/core/";
import "react-notifications-component/dist/theme.css";
import "./webRTCPlayerComponent.css";
import StreamComponent from "../stream/StreamComponent";
import { displayChatRoom } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import ReflexPlayerLayout from "../../layout/reflex-webrtc-player-layout";
import {
  localUser,
  reflexComponentDidUpdate,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {
  reflexWsConnect,
  reflexWsDisconnect,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/middlewares/actions/reflexApiActions";
import PulseLoader from "react-spinners/PulseLoader";
import { localUserErrorNotification } from "../../utils/participantUtils";
class WebRTCPlayerComponent extends Component {
  constructor(props) {
    super(props);

    this.hasBeenUpdated = false;
    this.layout = new ReflexPlayerLayout();

    this.state = {
      myCallSessionId: "",
      myUserName: "",
      callSession: undefined,
      localUser: undefined,
      subscribers: [],
      isOnAir: false,
      userReady: false,
      initCompleted: false,
    };
    this.joinSession = this.joinSession.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.checkNotification = this.checkNotification.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.closeDialogExtension = this.closeDialogExtension.bind(this);
    this.checkSize = this.checkSize.bind(this);
  }

  componentDidMount() {
    this.setState({ localUser: localUser });
    const reflexPlayerLayoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      bigClass: "OV_big", // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: true, // Whether you want to animate the transitions
    };

    this.layout.initLayoutContainer(
      document.getElementById("layout"),
      reflexPlayerLayoutOptions
    );
    window.addEventListener("beforeunload", this.onbeforeunload);
    window.addEventListener("resize", this.updateLayout);
    window.addEventListener("resize", this.checkSize);
    this.props.dispatch(reflexWsConnect());
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
    window.removeEventListener("resize", this.updateLayout);
    window.removeEventListener("resize", this.checkSize);
  }

  doReflexComponentDidUpdate(result) {
    if (result.state) {
      this.setState(result.state);
    }
    if (result.layoutToUpdate && !result.userReady) {
      this.updateLayout();
    }
    if (result.isOnAir !== undefined && result.isOnAir !== this.state.isOnAir) {
      this.setState({ isOnAir: result.isOnAir });
    }
    if (result.userReady) {
      this.setState({ userReady: true });
    }
    var reflexPlayerLayoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      bigClass: "OV_big", // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: true,
      webcamPlayer: 0,
      onAir: false,
    };
    if (this.state.userReady === true && this.state.isOnAir === true) {
      if (this.state.subscribers.length > 0)
        reflexPlayerLayoutOptions.onAir = true;
      reflexPlayerLayoutOptions.webcamPlayer =
        this.state.localUser.getWebcamPlayer();
      this.layout.setLayoutOptions(reflexPlayerLayoutOptions);
      this.updateLayout();
    } else if (this.state.userReady === true && this.state.isOnAir === false) {
      reflexPlayerLayoutOptions.webcamPlayer =
        this.state.localUser.getWebcamPlayer();
      this.layout.setLayoutOptions(reflexPlayerLayoutOptions);
      this.updateLayout();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initCompleted !== this.props.initCompleted) {
      this.setState({
        initCompleted: this.props.initCompleted,
      });
    }
    this.doReflexComponentDidUpdate(
      reflexComponentDidUpdate(this.props, prevProps)
    );

    if (prevProps.error !== this.props.error) {
      localUserErrorNotification(this.props.error)
    }
  }

  onbeforeunload(event) {
    this.props.dispatch(reflexWsDisconnect());
  }

  checkSize() {
    if (
      document.getElementById("layout").offsetWidth <= 700 &&
      !this.hasBeenUpdated
    ) {
      this.props.dispatch(displayChatRoom("none"));
      this.hasBeenUpdated = true;
    }
    if (
      document.getElementById("layout").offsetWidth > 700 &&
      this.hasBeenUpdated
    ) {
      this.hasBeenUpdated = false;
    }
  }

  updateLayout() {
    setTimeout(() => {
      this.layout.updateLayout();
    }, 20);
  }

  closeDialogExtension() {
    this.setState({ showExtensionDialog: false });
  }

  //TODO remove
  joinSession() {}

  screenShare() {}

  stopScreenShare() {}

  checkSomeoneShareScreen() {
    let isScreenShared;
    // return true if at least one passes the test
    isScreenShared =
      this.state.subscribers.some((user) => user.isScreenShareActive()) ||
      localUser.isScreenShareActive();
    const reflexPlayerLayoutOptions = {
      maxRatio: 3 / 2,
      minRatio: 9 / 16,
      fixedRatio: isScreenShared,
      bigClass: "OV_big",
      bigPercentage: 0.8,
      bigFixedRatio: false,
      bigMaxRatio: 3 / 2,
      bigMinRatio: 9 / 16,
      bigFirst: true,
      animate: true,
    };
    this.layout.setLayoutOptions(reflexPlayerLayoutOptions);
    this.updateLayout();
  }

  checkNotification(event) {
    this.setState({});
  }

  render() {
    const localUser = this.state.localUser;

    return (
      <Box id="webRTCPlayer" className="">
        <Box id="layout" className="webRTCPlayerRoomContainerBounds">
          {localUser !== undefined &&
            localUser.getWebcamPlayer !== undefined &&
            this.state.subscribers.map((sub, i) => (
              <Box
                key={i}
                className="OT_root OT_publisher custom-class"
                id="remoteUsers"
              >
                <StreamComponent
                  user={sub}
                  streamId={sub.streamManager.stream.streamId}
                  player={localUser.getWebcamPlayer()}
                />
              </Box>
            ))}
        </Box>
        <Box
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: !this.state.initCompleted ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000000",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderWidth: "0px",
            }}
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Trans>global|waitingInit</Trans>
            </Typography>
            <PulseLoader
              color="#ff2900"
              loading="true"
              style={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
                borderWidth: "0px",
              }}
              size={35}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  initCompleted: state.ui.initCompleted,
  sessionReady: state.websocket.connection.events.sessionReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  subscriberEvent: state.websocket.callEvents.subscriberEvent,
  refresh: state.ui.refresh,
  localUserRefresh: state.websocket.callEvents.localUserRefresh,
  isOnAir: state.websocket.call.isOnAir,
  error: state.websocket.connection.events.error,
});

export default withTranslation()(
  connect(mapStateToProps)(WebRTCPlayerComponent)
);
