import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import i18nInstance from "../../../../i18n";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 0,
    },
  },
  chip : {
    fontSize: "1.3vh",
    height:"3vh"
  }
 
}));

export default function UserOnAirChip(props) {
  const classes = useStyles();
  const handleDelete = () => {
    if (props.user.userid !== "" ){
      var user = JSON.parse(JSON.stringify(props.user));
      user.id = 0;
      props.websocketServices.signals.removeCallParticipant(user,props.webcamId);
    }
  };

  const username = () => {
    if (props.user.username === "" && props.user.userid === "") {
      return i18nInstance.t("UserCard|nullUser")
    } else if (props.user.userid === undefined) {
      return ""
    }else {
      return props.user.username.split(" ")[0]
    }
    
  }

  return (
    <div className={classes.root}>
      <Chip
      className={classes.chip}
      variant="outlined"
        size="small"
        label={username()}
        // onDelete={handleDelete}
        
      />
  
    </div>
  );
}