import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import IntercomPanelComponent from "./intercomPanelComponent";
import { Component } from "react";
import { Grid } from "@material-ui/core";
import {
  addUserToControlRoomWhisper,
  addUserToDirectorWhisper,
  addUserToPartyLineWhisper,
  removeUserFromControlRoomWhisper,
  removeUserFromDirectorWhisper,
  removeUserFromPartyLineWhisper,
  toggleControlRoomActive,
  toggleControlRoomPTT,
  toggleDirectorActive,
  toggleDirectorPTT,
  togglePartyLineActive,
  togglePartyLinePTT,
} from "../../../../utils/callControllerUtils";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";

class IntercomPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      controlRoomInputValue: "",
      controlRoomSelected: null,
      directorInputValue: "",
      directorSelected: null,
      partyLineInputValue: "",
      partyLineSelected: null,
      intercom: {
        partyLine: {},
        controlRoom: {},
        director: {},
        moderations: {},
        moderated: {},
      },
      dimension: this.browserUtil.getWindowDimensions(),
    };
    this.addUserToPartyLine = this.addUserToPartyLine.bind(this);
    this.removeUserFromPartyLine = this.removeUserFromPartyLine.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  addUserToPartyLine(user) {
    websocketServices.signals.addUserToPartyLine(user);
  }
  removeUserFromPartyLine(userid) {
    websocketServices.signals.removeUserFromPartyLine(userid);
  }

  addUserToControlRoom(user) {
    websocketServices.signals.addUserToControlRoom(user);
  }
  removeUserFromControlRoom(userid) {
    websocketServices.signals.removeUserFromControlRoom(userid);
  }

  addUserToDirector(user) {
    websocketServices.signals.addUserToDirector(user);
  }
  removeUserFromDirector(userid) {
    websocketServices.signals.removeUserFromDirector(userid);
  }
  
  render() {
    
    return (
      <Grid item xs={12} spacing={1} container>
        <Grid item xs={4} spacing={1}>
          <IntercomPanelComponent
            name={"CONTROL_ROOM"}
            localUser={this.props.localUser}
            intercom={this.state.intercom.controlRoom}
            handleToggleActive={toggleControlRoomActive}
            handleTogglePTT={toggleControlRoomPTT}
            handleAddToWhisper={addUserToControlRoomWhisper}
            handleRemoveFromWhisper={removeUserFromControlRoomWhisper}
            addUser={this.addUserToControlRoom}
            removeUser={this.removeUserFromControlRoom}
            cameraConnected={this.props.cameraConnected}
            dimension={this.state.dimension}
          />
        </Grid>
        <Grid item xs={4} spacing={1}>
          <IntercomPanelComponent
            name={"DIRECTOR"}
            localUser={this.props.localUser}
            intercom={this.state.intercom.director}
            handleToggleActive={toggleDirectorActive}
            handleTogglePTT={toggleDirectorPTT}
            handleAddToWhisper={addUserToDirectorWhisper}
            handleRemoveFromWhisper={removeUserFromDirectorWhisper}
            addUser={this.addUserToDirector}
            removeUser={this.removeUserFromDirector}
            cameraConnected={this.props.cameraConnected}
            dimension={this.state.dimension}
          />
        </Grid>
        <Grid item xs={4} spacing={1}>
          <IntercomPanelComponent
            name={"PARTY_LINE"}
            localUser={this.props.localUser}
            intercom={this.state.intercom.partyLine}
            handleToggleActive={togglePartyLineActive}
            handleTogglePTT={togglePartyLinePTT}
            handleAddToWhisper={addUserToPartyLineWhisper}
            handleRemoveFromWhisper={removeUserFromPartyLineWhisper}
            addUser={this.addUserToPartyLine}
            removeUser={this.removeUserFromPartyLine}
            cameraConnected={this.props.cameraConnected}
            dimension={this.state.dimension}
          />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  intercom: state.websocket.intercom,
  controlRoomInputValue: state.ui.callModeratorComponent.controlRoomInputValue,
  directorInputValue: state.ui.callModeratorComponent.directorInputValue,
  partyLineInputValue: state.ui.callModeratorComponent.partyLineInputValue,
  controlRoomSelected: state.ui.callModeratorComponent.controlRoomSelected,
  directorSelected: state.ui.callModeratorComponent.directorSelected,
  partyLineSelected: state.ui.callModeratorComponent.partyLineSelected,
});
export default withTranslation()(
  connect(mapStateToProps)(IntercomPanelContainer)
);
