import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  title: {
    
    "& span" : {
      fontSize: "1.0vw",
    }
  },
  label : {
    fontSize: "1.0vw",
  },
  input : {
    fontSize: "0.8vw",
  },
  formControl : {
    "& span" : {
      fontSize: "0.9vw",
    }
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
  },
  cardContent: {
    width: "100%",
  },
  textAreaContent: {
    width: "100%",
  },
  buttonClass: {
    margin: "5px",
    fontSize: "0.6vw",
  },
}));

export default function SendEmailToContributor(props) {
  const [copyEmail, setCopyEmail] = useState(false);

  const [helperTextForEmail, setHelperTextForEmail] = useState("");
  const [copyEmailError, setCopyEmailError] = useState(false);

  const { t, i18next } = useTranslation();
  const classes = useStyles();

  function sendInvitationEmail() {
    var emailToSend = {
      id: "webcamcall",
      broadcastid: props.localUser.getBroadcastId(),
      broadcaster: props.localUser.getBroadcaster(),
      type: "sendEmail",
      userid: "none",
      username: props.localUser.getNickname(),
      broadcastName: props.localUser.getBroadcastName(),
      privateBroadcast: "PRIVATE",
      locales: t("locale"),
      emailUsername: props.contributorTokenToWorkWith.token.name,
      to: props.contributorTokenToWorkWith.token.username,
      additionnalText: document.getElementById("additionnalText").value,
      copyEmail: "" + copyEmail,
      url: props.contributorTokenToWorkWith.url,
      copyOnly: false,
      default_email_user: document.getElementById("copyEmailTextField").value,
    };

    return emailToSend;
  }
  const sendEmail = () => {
    if (
      copyEmail &&
      !validateEmail(document.getElementById("copyEmailTextField").value)
    ) {
      setHelperTextForEmail(t("ContributorsComponent|copyEmailError"));
      setCopyEmailError(true);
    } else {
      props.sendEmail(sendInvitationEmail());
      setHelperTextForEmail("");
      setCopyEmailError(false);
      document.getElementById("additionnalText").value = "";
    }
  };

  const handleCheckCopyEmail = (event, isInputChecked) => {
    setCopyEmail(isInputChecked);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  let name = "";
  if (
    props.contributorTokenToWorkWith !== undefined &&
    props.contributorTokenToWorkWith.token !== undefined
  ) {
    name = props.contributorTokenToWorkWith.token.name;
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={t("ContributorsComponent|SendEmailToContributor")}
        subheader={name}
        className = {classes.title}
        
      />
      <CardContent className={classes.cardContent}>
        <TextField
          className={classes.textAreaContent}
          id="additionnalText"
          placeholder={t("ContributorsComponent|additionnalText")}
          multiline
          rows={2}
          maxRows={6}
          variant="outlined"
          InputLabelProps={{
            className: classes.label,
          }}
          InputProps={{
            className: classes.input,
          }}
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <FormControlLabel
          control={<Checkbox id="copyEmail" onChange={handleCheckCopyEmail} />}
          label={t("ContributorsComponent|SendMeACopy")}
          className={classes.formControl}
        />
      </CardContent>
      <CardContent
        style={{ display: copyEmail ? "block" : "none" }}
        className={classes.buttonContainer}
      >
        <TextField
          error={copyEmailError}
          id="copyEmailTextField"
          label={t("ContributorsComponent|Email")}
          variant="outlined"
          helperText={helperTextForEmail}
        />
      </CardContent>
      <CardActions>
        <Box component="div" className={classes.buttonContainer}>
          <Button onClick={() => sendEmail()} className={classes.buttonClass}>
            {" "}
            {t("ContributorsComponent|SendEmail")}
          </Button>
          <Button onClick={props.openList} className={classes.buttonClass}>
            {" "}
            {t("ContributorsComponent|Cancel")}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
