import {
  Tabs,
  Tab,
  makeStyles,
  Badge,
  Typography,
  Box,
} from "@material-ui/core";
import { selectedListTypes, selectList } from "./commons";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  NOT_CONNECTED,
  NOT_CONNECTED_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import { setSelectedList } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { StyledTypography } from "../../../../commons/StyledTypography";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "1.0vw",
    color: theme.palette.reflexYellow.main,
    marginLeft: "5px",
  },
  tabTitle: {
    marginRight: "15px",
    fontSize: "0.7vw",
  },
}));

export function ListTabs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedList = useSelector(selectList);

  const notConnectedLength = useSelector(
    (state) => Object.entries(state.websocket.notConnected).length
  );
  const participantsLength = useSelector(
    (state) => Object.entries(state.websocket.participants).length
  );
  const moderatedLength = useSelector(
    (state) => Object.entries(state.websocket.intercom.moderated).length
  );
  const contributorsLength = useSelector(
    (state) => Object.entries(state.websocket.contributors).length
  );
  const callModeratorsLength = useSelector(
    (state) => Object.entries(state.websocket.callModerators).length
  );

  const handleChange = (_event, list) => {
    if (selectedList === list) {
      dispatch(setSelectedList(null));
    } else {
      dispatch(setSelectedList(list));
    }
  };

  return (
    <Box id="usersContainer">
      <StyledTypography className={classes.header} align="left" variant={"h6"}>
        {t("Users|Users")}
      </StyledTypography>
      <Tabs
        value={selectedList || false}
        onChange={handleChange}
        centered
        // variant="fullWidth"
      >
        {[
          {
            label: t("UsersComponent|Not Connected"),
            value: selectedListTypes.NOT_CONNECTED,
            howMany: notConnectedLength,
          },
          {
            label: t("UsersComponent|Participants"),
            value: selectedListTypes.PARTICIPANTS,
            howMany: participantsLength,
          },
          {
            label: t("UsersComponent|Moderated"),
            value: selectedListTypes.MODERATED,
            howMany: moderatedLength,
          },
          {
            label: t("UsersComponent|Contributors"),
            value: selectedListTypes.CONTRIBUTORS,
            howMany: contributorsLength,
          },
          {
            label: t("UsersComponent|Call Moderators"),
            value: selectedListTypes.CALL_MODERATORS,
            howMany: callModeratorsLength,
          },
        ].map((info) => (
          <Tab
          style={{minWidth:"20%",maxWidth:"20%"}}
            label={
              <Badge
                badgeContent={info.howMany}
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography className={classes.tabTitle}>
                  {info.label}
                </Typography>
              </Badge>
            }
            value={info.value}
          />
        ))}
      </Tabs>
    </Box>
  );
}
