import { Box, makeStyles, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { localUser } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
    root: {
        top: "50px",
        right: "10px",
        width: "250px",
        height: "100px",
        position: "absolute",
        border: "solid",
        borderWidth: "4px",
        borderColor: theme.palette.primary.main,
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.grey7.main + "5f",
    },
    rootHidden: {
        display: "none"
    },
    redDot : {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "50%",
        width: "50px",
        minWidth: "50px",
        height: "50px",
        display: "flex",
        animationName: '$blinker',
        animationDuration: '0.80s',
        animationDirection: 'alternate',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    yellowDot : {
        backgroundColor: theme.palette.reflexYellow.main,
        borderRadius: "50%",
        width: "50px",
        minWidth: "50px",
        height: "50px",
        display: "flex",
        animationName: '$blinker',
        animationDuration: '0.80s',
        animationDirection: 'alternate',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    grayDot : {
        backgroundColor: theme.palette.grey2.main,
        borderRadius: "50%",
        width: "50px",
        minWidth: "50px",
        height: "50px",
        display: "flex",
        animationName: '$blinker',
        animationDuration: '0.80s',
        animationDirection: 'alternate',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    numberText : {
        margin: "auto",
        fontWeight: "bold"
    },
    statusText : {
        // Needed to be empty
    },
    statusTextOnAir : {
        fontSize: "30px",
        fontWeight: "bold"
    },
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },
    statusMessageContainer : {
        width: "100%",
        textAlign: "center"
    }
}))

/**
 * expansion possible in the case where user is  not in queue (whereLocalUser = null)
 */
export function OnAirStatus(props) {
    const classes = useStyles()
    const { t } = useTranslation()

    const isOnAir = useSelector(state => state.websocket.call.isOnAir)
    const onAir = useSelector(state => state.websocket.call.onAir)
    const localUserId = localUser().getUserId()
    
    let whereLocalUser = null
    // null not in queue, 0 on air, >0 in queue
    for (const [key, webcamSlot] of Object.entries(onAir)) {
        const pos = webcamSlot.findIndex(user => user.userid === localUserId)
        if (pos >= 0 && key !== "100") {
            if (whereLocalUser === null ||
                pos < whereLocalUser) {
                whereLocalUser = pos+1
            } 
        }
    } 

    const isLocalUserOnAir = whereLocalUser === 1 && isOnAir

    return (
        <Box className={isOnAir && whereLocalUser !== null? classes.root: classes.rootHidden}>
            <Box className={whereLocalUser === 2 ? classes.yellowDot : isLocalUserOnAir? classes.redDot : classes.grayDot}>
                {!isLocalUserOnAir && (
                    <Typography className={classes.numberText}>
                        {whereLocalUser}
                    </Typography>
                )}
            </Box>
            <Box className={classes.statusMessageContainer}>
                <Typography className={isLocalUserOnAir? classes.statusTextOnAir : classes.statusText}>
                    {
                        isLocalUserOnAir 
                        ? t("OnAirStatus|OnAir") 
                        : whereLocalUser === 2 ? t("OnAirStatus|Next") : `${t("OnAirStatus|OnAirCountBegining")} ${whereLocalUser} ${t("OnAirStatus|OnAirCountEnd")}`.trim()
                    }
                </Typography>
            </Box>
            
        </Box>
        )
    }