import React from "react";
import i18nInstance from "../../i18n";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import SwitchVideoIcon from "@material-ui/icons/SwitchVideo";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import CallIcon from "@material-ui/icons/Call";
import ClearIcon from "@material-ui/icons/Clear";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import MicOffIcon from "@material-ui/icons/MicOff";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import ChatIcon from "@material-ui/icons/Chat";
import LanguageIcon from "@material-ui/icons/Language";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import CheckIcon from '@material-ui/icons/Check';
import { Box, IconButton, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";
import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import NoteAddIcon from '@material-ui/icons/NoteAdd';

export function TakeUserNote(props) {
  return (
    <Tooltip
      title={props.tootTip}
      arrow
    >
      <IconButton size="small" aria-label="AddUserNote" {...props}>
        <NoteAddIcon />
      </IconButton>
    </Tooltip>
  );
}

export function WebcamDownButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Take the user down in the list")}
      arrow
    >
      <IconButton size="small" aria-label="WebcamDown" {...props}>
        <ArrowDownwardIcon />
      </IconButton>
    </Tooltip>
  );
}
export function WebcamUpButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Take the user up in the list")}
      arrow
    >
      <IconButton size="small" aria-label="WebcamUp" {...props}>
        <ArrowUpwardIcon />
      </IconButton>
    </Tooltip>
  );
}
export function WebcamRowDownButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Take all users down in the list")}
      arrow
    >
      <IconButton size="small" aria-label="WebcamRowDown" {...props}>
        <VerticalAlignBottomIcon style={{fontSize:props.fontSize}}/>
      </IconButton>
    </Tooltip>
  );
}
export function WebcamRowUpButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Take all users up in the list")}
      arrow
    >
      <IconButton size="small" aria-label="WebcamRowUp" {...props}>
        <VerticalAlignTopIcon />
      </IconButton>
    </Tooltip>
  );
}

export function OnlineButton(props) {
  const onlineButtonUseStyles = makeStyles((theme) => ({
    root: {
      color: getOnlineColor(theme),
    },
  }));
  const classes = onlineButtonUseStyles();
  function getOnlineColor(theme) {
    if (props.online) {
      if (props.big) {
        return theme.palette.reflexYellow.main;
      } else {
        return theme.palette.reflexBlue.main;
      }
    } else {
      return theme.palette.primary.main;
    }
  }
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Display if user is online")}
      arrow
    >
      <WifiTetheringIcon className={classes.root} />
    </Tooltip>
  );
}

export function AddUserToIntercomButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Add user to control room intercom")}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToIntercom" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <path d="M12,22c1.1,0,2-0.9,2-2h-4C10,21.1,10.9,22,12,22z" />
          <path
            d="M18,16v-5c0-3.1-1.6-5.6-4.5-6.3V4c0-0.8-0.7-1.5-1.5-1.5S10.5,3.2,10.5,4v0.7C7.6,5.4,6,7.9,6,11v5l-2,2v1
			h16v-1L18,16z M14.9,12.6c-0.8,0.1-1.4-0.5-1.4-1.3c0-1.7-1-2.3-1.1-2.4c-0.6-0.4-0.9-1.2-0.5-1.8c0.3-0.6,1.1-0.9,1.8-0.6
			c0.9,0.5,2.5,2,2.5,4.8C16.2,12,15.6,12.6,14.9,12.6z"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export function AddUserToDirectorButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Add user to director intercom")}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToDirector" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <polygon points="6.4,2 4.4,2 4.4,4.6 2,4.6 2,6.6 4.4,6.6 4.4,9 6.4,9 6.4,6.6 9,6.6 9,4.6 6.4,4.6 		" />
          <path
            d="M20.7,8.2V7.6h-1.3v0.7h-6.7V7.6h-1.3v0.7H10V15h1.3v1.2h-0.7V17l4,2.3L12,20.8c-0.3,0.2-0.4,0.6-0.2,0.9
			s0.6,0.4,0.9,0.2L16,20l3.3,1.9c0.3,0.2,0.7,0.1,0.9-0.2s0.1-0.7-0.2-0.9l-2.7-1.5l4-2.3v-0.8h-0.7V15H22v-0.1V8.2H20.7z M16,18.5
			L13.3,17h5.3L16,18.5z M19.3,16.1h-6.7v-1.2h6.7V16.1z"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export function AddUserToPartyLineButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Add user to party line intercom")}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToPartyLine" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <path
            d="M22.2,7.4c0-3.4-2.6-6-6-6s-6,2.6-6,6c0,4.4,2.5,9.1,5.5,9.6l0,0l0,0l0,0l0,0l-1,2h1c0,0,0,2.5-1.5,2.5
				c-0.7,0-1-0.5-1.6-1.3c-0.5-0.8-1.2-1.7-2.4-1.7S8.4,19.4,8,20.1c-0.5,0.9-0.8,1.4-1.8,1.4v1c1.6,0,2.2-1,2.7-1.9
				c0.4-0.7,0.7-1.1,1.3-1.1c0.7,0,1.1,0.5,1.6,1.3s1.1,1.7,2.4,1.7c2,0,2.5-2.3,2.5-3.5h1l-1-2l0,0l0,0l0,0l0,0
				C19.4,16.5,22.2,11.9,22.2,7.4z M18.5,7.4c0-1.7-1-2.3-1.1-2.4c-0.6-0.4-0.9-1.2-0.5-1.8c0.3-0.6,1.1-0.9,1.8-0.6
				c0.9,0.5,2.5,2,2.5,4.8c0,0.7-0.6,1.3-1.3,1.3C19.1,8.9,18.5,8.2,18.5,7.4z"
          />
          <polygon
            points="8.8,6.8 6.2,6.8 6.2,9.2 4.2,9.2 4.2,6.8 1.8,6.8 1.8,4.8 4.2,4.8 4.2,2.2 6.2,2.2 6.2,4.8 8.8,4.8 
							"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//TODO Change Icons
export function AddUsersToIntercomButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Add all selected users to control room intercom"
      )}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToIntercom" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <path d="M12,22c1.1,0,2-0.9,2-2h-4C10,21.1,10.9,22,12,22z" />
          <path
            d="M18,16v-5c0-3.1-1.6-5.6-4.5-6.3V4c0-0.8-0.7-1.5-1.5-1.5S10.5,3.2,10.5,4v0.7C7.6,5.4,6,7.9,6,11v5l-2,2v1
			h16v-1L18,16z M14.9,12.6c-0.8,0.1-1.4-0.5-1.4-1.3c0-1.7-1-2.3-1.1-2.4c-0.6-0.4-0.9-1.2-0.5-1.8c0.3-0.6,1.1-0.9,1.8-0.6
			c0.9,0.5,2.5,2,2.5,4.8C16.2,12,15.6,12.6,14.9,12.6z"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}
//TODO Change Icons
export function AddUsersToDirectorButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Add all selected users to director intercom"
      )}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToDirector" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <polygon points="6.4,2 4.4,2 4.4,4.6 2,4.6 2,6.6 4.4,6.6 4.4,9 6.4,9 6.4,6.6 9,6.6 9,4.6 6.4,4.6 		" />
          <path
            d="M20.7,8.2V7.6h-1.3v0.7h-6.7V7.6h-1.3v0.7H10V15h1.3v1.2h-0.7V17l4,2.3L12,20.8c-0.3,0.2-0.4,0.6-0.2,0.9
			s0.6,0.4,0.9,0.2L16,20l3.3,1.9c0.3,0.2,0.7,0.1,0.9-0.2s0.1-0.7-0.2-0.9l-2.7-1.5l4-2.3v-0.8h-0.7V15H22v-0.1V8.2H20.7z M16,18.5
			L13.3,17h5.3L16,18.5z M19.3,16.1h-6.7v-1.2h6.7V16.1z"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}
//TODO Change Icons
export function AddUsersToPartyLineButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Add all selected users to party line intercom"
      )}
      arrow
    >
      <IconButton size="small" aria-label="AddUserToPartyLine" {...props}>
        {/* <GroupAddIcon /> */}
        <SvgIcon>
          <path
            d="M22.2,7.4c0-3.4-2.6-6-6-6s-6,2.6-6,6c0,4.4,2.5,9.1,5.5,9.6l0,0l0,0l0,0l0,0l-1,2h1c0,0,0,2.5-1.5,2.5
				c-0.7,0-1-0.5-1.6-1.3c-0.5-0.8-1.2-1.7-2.4-1.7S8.4,19.4,8,20.1c-0.5,0.9-0.8,1.4-1.8,1.4v1c1.6,0,2.2-1,2.7-1.9
				c0.4-0.7,0.7-1.1,1.3-1.1c0.7,0,1.1,0.5,1.6,1.3s1.1,1.7,2.4,1.7c2,0,2.5-2.3,2.5-3.5h1l-1-2l0,0l0,0l0,0l0,0
				C19.4,16.5,22.2,11.9,22.2,7.4z M18.5,7.4c0-1.7-1-2.3-1.1-2.4c-0.6-0.4-0.9-1.2-0.5-1.8c0.3-0.6,1.1-0.9,1.8-0.6
				c0.9,0.5,2.5,2,2.5,4.8c0,0.7-0.6,1.3-1.3,1.3C19.1,8.9,18.5,8.2,18.5,7.4z"
          />
          <polygon
            points="8.8,6.8 6.2,6.8 6.2,9.2 4.2,9.2 4.2,6.8 1.8,6.8 1.8,4.8 4.2,4.8 4.2,2.2 6.2,2.2 6.2,4.8 8.8,4.8 
							"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//ClearIcon
export function RemoveUserFromCallButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Remove user from call")}
      arrow
    >
      <IconButton size="small" aria-label="removeUserFromCall" {...props}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  );
}

//TODO change ClearIcon
export function RemoveUsersFromCallButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Remove all selected users from call"
      )}
      arrow
    >
      <IconButton size="small" aria-label="removeUserFromCall" {...props}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  );
}

//DesktopWindowsIcon
export function AllowScreenShareButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Allow screenshare")} arrow>
      <IconButton size="small" aria-label="AllowScreenShare" {...props}>
        <DesktopWindowsIcon />
      </IconButton>
    </Tooltip>
  );
}
//TODO Change CallIcon
export function ModerateUsersButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Moderate all selected users")}
      arrow
    >
      <IconButton size="small" aria-label="ModerateUser" {...props}>
        <CallIcon />
      </IconButton>
    </Tooltip>
  );
}
//CallIcon
export function ModerateUserButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Moderate user")} arrow>
      <IconButton size="small" aria-label="ModerateUser" {...props}>
        <CallIcon />
      </IconButton>
    </Tooltip>
  );
}
//ExpandMoreIcon
export function ShowUserDetailsButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Show/Hide user details")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <ExpandMoreIcon />
      </IconButton>
    </Tooltip>
  );
}

//SwitchVideoIcon
export function SwitchCameraButton(props) {
  return (
    <Tooltip 
      // title={`${i18nInstance.t("CommonButtons|Switch user camera")} | ${i18nInstance.t("CommonButtons|availableDevices")}: ${props.numDevices || 0}`} 
      title={
        <>
          <Typography>
            {i18nInstance.t("CommonButtons|Switch user camera")}
          </Typography>
          <Typography>
            {i18nInstance.t("CommonButtons|availableDevices")}: {props.devices?.length || 0}
          </Typography>
          <Box>
            {props.devices?.map(device => <Typography>- {device.name}</Typography>)}
          </Box>
        </>
      }
      arrow
    >
      <IconButton size="small" aria-label="CallOnAir" {...props}>
        <SwitchVideoIcon />
      </IconButton>
    </Tooltip>
  );
}

export function CallOnAirButtonTimeSlot(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Put call On Air")} arrow>
      <IconButton aria-label="CallOnAir" {...props}>
        {/* <LiveTvIcon /> */}
        {props.isOnAir === false && (
          <SvgIcon>
            <path
              d="M0.3,2.8L3.5,6H3C1.9,6,1,6.9,1,8v12c0,1.1,0.9,2,2,2h16.5l1.7,1.7l1.3-1.3L1.5,1.5L0.3,2.8z M3,20V8h2.5
			l12,12H3z"
            />
            <path
              d="M21,6h-7.6l3.3-3.3L16,2l-4,4L8,2L7.3,2.7L10.6,6H8.4l2,2H21v10.5l1.9,1.9c0-0.1,0.1-0.3,0.1-0.4V8
			C23,6.9,22.1,6,21,6z"
            />
          </SvgIcon>
        )}

        {props.isOnAir === true && (
          <SvgIcon>
            <g>
              <polygon points="9,11.5 9,19.5 16,15.5 		" />
              <path
                d="M21,7.5h-3.8l3.3-3.3l-0.7-0.7l-4,4h-3.6h-4l-4-4L3.5,4.2l3.3,3.3H3c-1.1,0-2,0.9-2,2v12c0,1.1,0.9,2,2,2h18
			c1.1,0,2-0.9,2-2v-12C23,8.4,22.1,7.5,21,7.5z M21,21.5H3v-12h18V21.5z"
              />
              <g>
                <path d="M12,0.5c-2.2,0-4.1,0.9-5.5,2.2l1,1c1.2-1.2,2.7-1.8,4.5-1.8s3.3,0.6,4.5,1.8l1-1C16,1.4,14,0.5,12,0.5z" />
                <path d="M8.5,4.7l1,1c0.6-0.6,1.5-1,2.4-1c1,0,1.8,0.4,2.4,1l1-1c-0.9-0.9-2.2-1.4-3.5-1.4S9.4,3.8,8.5,4.7z" />
              </g>
            </g>
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}
export function CallOnAirButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Put call On Air")} arrow>
      <IconButton aria-label="CallOnAir" {...props}>
        {/* <LiveTvIcon /> */}
        {props.color === "primary" && (
          <SvgIcon style={{fontSize: props.fontSize}}>
            <path
              d="M0.3,2.8L3.5,6H3C1.9,6,1,6.9,1,8v12c0,1.1,0.9,2,2,2h16.5l1.7,1.7l1.3-1.3L1.5,1.5L0.3,2.8z M3,20V8h2.5
			l12,12H3z"
            />
            <path
              d="M21,6h-7.6l3.3-3.3L16,2l-4,4L8,2L7.3,2.7L10.6,6H8.4l2,2H21v10.5l1.9,1.9c0-0.1,0.1-0.3,0.1-0.4V8
			C23,6.9,22.1,6,21,6z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon style={{fontSize: props.fontSize}}>
            <g>
              <polygon points="9,11.5 9,19.5 16,15.5 		" />
              <path
                d="M21,7.5h-3.8l3.3-3.3l-0.7-0.7l-4,4h-3.6h-4l-4-4L3.5,4.2l3.3,3.3H3c-1.1,0-2,0.9-2,2v12c0,1.1,0.9,2,2,2h18
			c1.1,0,2-0.9,2-2v-12C23,8.4,22.1,7.5,21,7.5z M21,21.5H3v-12h18V21.5z"
              />
              <g>
                <path d="M12,0.5c-2.2,0-4.1,0.9-5.5,2.2l1,1c1.2-1.2,2.7-1.8,4.5-1.8s3.3,0.6,4.5,1.8l1-1C16,1.4,14,0.5,12,0.5z" />
                <path d="M8.5,4.7l1,1c0.6-0.6,1.5-1,2.4-1c1,0,1.8,0.4,2.4,1l1-1c-0.9-0.9-2.2-1.4-3.5-1.4S9.4,3.8,8.5,4.7z" />
              </g>
            </g>
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

//ExpandLessIcon
export function ExpandButton(props) {
  return (
    <IconButton size="small" aria-label="Expand" {...props}>
      <ExpandLessIcon />
    </IconButton>
  );
}
//ExpandMoreIcon
export function ReduceButton(props) {
  return (
    <IconButton size="small" aria-label="Reduce" {...props}>
      <ExpandMoreIcon />
    </IconButton>
  );
}

//LayersClearIcon
export function ClearCallButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Remove all users from call")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <LayersClearIcon />
      </IconButton>
    </Tooltip>
  );
}
//FHDQualityIcon
export function FHdButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Change resolution to Full HD Definition"
      )}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <path
              d="m20.3,3.3l0,17.1l-17.2,0l0,-17.1l17.2,0m1.4,-1.4l-1.4,0l-17.2,0l-1.4,0l0,1.4l0,17.1l0,1.5l1.4,0l17.1,0l1.5,0l0,-1.4l0,-17.2l0,-1.4l0,0z"
              id="svg_4"
            />
            <path d="m4.4,4.1l2.8,0l0,15.6l-2.8,0l0,-15.6z" id="svg_8" />
            <path
              d="m8.500002,3.999999l2.8,0l0,15.6l-2.8,0l0,-15.6z"
              id="svg_9"
            />
            <line
              fill="none"
              id="svg_10"
              x1="13.2"
              x2="13.2"
              y1="19.799994"
              y2="8.099997"
            />
            <path
              d="m12.485731,3.333493l2.773045,0l0,11.302727l-2.773045,0l0,-11.302727z"
              id="svg_12"
              transform="rotate(43.8767 13.8723 8.9849)"
            />
            <path
              d="m13.976872,9.449781l2.757905,0l0,10.621549l-2.757905,0l0,-10.621549z"
              id="svg_13"
              transform="rotate(-35.242 15.3558 14.7606)"
            />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}
//HDQualityIcon
export function HdButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Change resolution to High Definition"
      )}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <g>
              <polygon
                points="9.2,5 9.2,10.9 7.1,10.9 7.1,5 5,5 5,10.9 5,13.1 5,19 7.1,19 7.1,13.1 9.2,13.1 9.2,19 11.3,19 
				11.3,13.1 11.3,10.9 11.3,5 			"
              />
              <path d="M15.3,7.1l1.6,1.6v6.7l-1.6,1.6h-0.5V7.1H15.3 M16.2,5h-3.5v14h3.5l2.8-2.8V7.8L16.2,5L16.2,5z" />
            </g>
            <path d="M20.6,3.4v17.1H3.4V3.4H20.6 M22,2h-1.4H3.4H2v1.4v17.1V22h1.4h17.1H22v-1.4V3.4V2L22,2z" />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//HighQualityIcon
export function HqButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Change resolution to High Quality")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <g>
              <path d="M16.2,5h-3.5v14h3.5l2.8-2.8V7.8L16.2,5z M16.9,15.3l-1.6,1.6h-0.5V7.1h0.5l1.6,1.6V15.3z" />
              <path
                d="M7.1,5H6.4C5.6,5,5,5.6,5,6.4v0.7v3.8v0.7c0,0.8,0.6,1.4,1.4,1.4h0.7h2.1v3.8H7.1v-2.1H6.4
				c-0.8,0-1.4,0.6-1.4,1.4v1.4L6.4,19h3.5c0.8,0,1.4-0.6,1.4-1.4v-4.6v-0.7c0-0.8-0.6-1.4-1.4-1.4H9.2H7.1V7.1h2.1v2.1h0.7
				c0.8,0,1.4-0.6,1.4-1.4V6.4L9.9,5H7.1z"
              />
            </g>
            <path d="M20.6,3.4v17.1H3.4V3.4H20.6 M22,2h-1.4H3.4H2v1.4v17.1V22h1.4h17.1H22v-1.4V3.4V2L22,2z" />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//SquareQualityIcon
export function FourByThreeButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Change resolution to SD quality")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <polygon points="9,15 11,15 11,5 9,5 9,9 7,9 7,5 5,5 5,11 9,11 		" />
            <rect x="13" y="5" width="2" height="2" />
            <rect x="9" y="17" width="2" height="2" />
            <path
              d="M19,17v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V11c0-1.1-0.9-2-2-2h-4v2h4v2h-2v2h2v2h-4v2h4
			C18.1,19,19,18.1,19,17z"
            />
            <path d="M20.6,3.4v17.1H3.4V3.4H20.6 M22,2h-1.4H3.4H2v1.4v17.1V22h1.4h17.1H22v-1.4V3.4V2L22,2z" />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//LowResQualityIcon
export function LowResButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t(
        "CommonButtons|Change resolution to low resolution"
      )}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <path d="M20.6,3.4v17.1H3.4V3.4H20.6 M22,2h-1.4H3.4H2v1.4v17.1V22h1.4h17.1H22v-1.4V3.4V2L22,2z" />
            <g>
              <polygon points="7.1,13.1 7.1,10.9 7.1,5 5,5 5,10.9 5,13.1 5,16.9 5,19 7.1,19 11.3,19 11.3,16.9 7.1,16.9 			" />
              <path
                d="M19,7.8L16.2,5h-3.5v7v2.1V19h2.1v-4.9h0.5l1.6,1.6V19H19v-4.2l-1.8-1.8l1.8-1.8V7.8z M14.8,12V7.1h0.5
				l1.6,1.6v1.8L15.3,12H14.8z"
              />
            </g>
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export function WebcamSettingsButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Configure webcams and microphone")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <path
            d="M18,9.5V6c0-0.5-0.5-1-1-1H3C2.5,5,2,5.5,2,6v12c0,0.5,0.5,1,1,1h14c0.5,0,1-0.5,1-1v-3.5l4,4v-13L18,9.5z
		 M7.1,13.1h-2v-2h2V13.1z M11.1,13.1h-2v-2h2V13.1z M15.1,13.1h-2v-2h2V13.1z"
          />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}
//MicOffIcon
export function MuteUserButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Mute user microphone")} arrow>
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <MicOffIcon />
      </IconButton>
    </Tooltip>
  );
}

//SettingsVoiceIcon
export function ChooseMicButton(props) {
  return (
    <Tooltip
      // title={`${i18nInstance.t("CommonButtons|Choose user microphone")} | ${i18nInstance.t("CommonButtons|availableDevices")}: ${props.numDevices || 0}`} // TODO: mic details
      title={
        <>
          <Typography>
            {i18nInstance.t("CommonButtons|Choose user microphone")}
          </Typography>
          <Typography>
            {i18nInstance.t("CommonButtons|availableDevices")}: {props.devices?.length || 0}
          </Typography>
          <Box>
            {props.devices?.map(device => <Typography>- {device.name}</Typography>)}
          </Box>
        </>
      }
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SettingsVoiceIcon />
      </IconButton>
    </Tooltip>
  );
}

//ChatIcon
export function SendMessageToUserButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Send private message")}
      arrow
    > 
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <ChatIcon />
      </IconButton>
    </Tooltip>
  );
}

//BlockIcon
export function KickUserButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Kick out and ban user")}
      arrow
    >
      <IconButton size="small" aria-label="ShowUserDetails" {...props}>
        <SvgIcon>
          <g>
            <path
              d="M23.1,21.9L2.1,1L0.9,2.3l5.9,5.9c0.5,2.1,2.2,3.8,4.3,4.3l0.2,0.2c-3.5,0.3-8.7,2-8.7,4.9v1.9h15.5l3.7,3.7
			L23.1,21.9z"
            />
            <path d="M22.6,19v-1.4c0-2.2-2.9-3.7-5.8-4.4L22.6,19z" />
            <path d="M18.6,6.8c0-3.3-2.7-6-6-6c-2.3,0-4.2,1.3-5.2,3.1l8.2,8.1C17.4,11,18.6,9,18.6,6.8z" />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

//LanguageIcon
export function LanguageButton(props) {
  return (
    <Tooltip title={i18nInstance.t("CommonButtons|Choose your language")} arrow>
      <IconButton size="small" aria-label="Language" {...props}>
        <LanguageIcon />
      </IconButton>
    </Tooltip>
  );
}

export function Webcam1Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam1" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M12,17h2V7h-4v2h2V17z M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z
		 M19,19H5V5h14V19z"
            />
          </SvgIcon>
        )}
        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M14,17h-2V9h-2V7h4V17
     z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam2Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam2" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M15,15h-4v-2h2c1.1,0,2-0.9,2-2V9c0-1.1-0.9-2-2-2H9v2h4v2h-2c-1.1,0-2,0.9-2,2v4h6V15z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,11
            c0,1.1-0.9,2-2,2h-2v2h4v2H9v-4c0-1.1,0.9-2,2-2h2V9H9V7h4c1.1,0,2,0.9,2,2V11z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam3Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam3" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M15,15v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V9c0-1.1-0.9-2-2-2H9v2h4v2h-2v2h2v2H9v2h4C14.1,17,15,16.1,15,15z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,10.5
            c0,0.8-0.7,1.5-1.5,1.5c0.8,0,1.5,0.7,1.5,1.5V15c0,1.1-0.9,2-2,2H9v-2h4v-2h-2v-2h2V9H9V7h4c1.1,0,2,0.9,2,2V10.5z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam4Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam4" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M13,17h2V7h-2v4h-2V7H9v6h4V17z M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5
		C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,17h-2v-4H9V7h2v4
            h2V7h2V17z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam5Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam5" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M15,15v-2c0-1.1-0.9-2-2-2h-2V9h4V7H9v6h4v2H9v2h4C14.1,17,15,16.1,15,15z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,9h-4v2h2
            c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2H9v-2h4v-2H9V7h6V9z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam6Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam6" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M11,17h2c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2h-2V9h4V7h-4C9.9,7,9,7.9,9,9v6C9,16.1,9.9,17,11,17z M11,13h2v2h-2V13z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M11,15h2v-2h-2V15z M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z
            M15,9h-4v2h2c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h4V9z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam7Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam7" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
            M11,17l4-8V7H9v2h4l-4,8H11z"
            />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,9l-4,8H9l4-8H9V7h6
            V9z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam8Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam8" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M11,17h2c1.1,0,2-0.9,2-2v-1.5c0-0.8-0.7-1.5-1.5-1.5c0.8,0,1.5-0.7,1.5-1.5V9c0-1.1-0.9-2-2-2h-2C9.9,7,9,7.9,9,9v1.5
		c0,0.8,0.7,1.5,1.5,1.5C9.7,12,9,12.7,9,13.5V15C9,16.1,9.9,17,11,17z M11,9h2v2h-2V9z M11,13h2v2h-2V13z"
            />
          </SvgIcon>
        )}
        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,10.5
            c0,0.8-0.7,1.5-1.5,1.5c0.8,0,1.5,0.7,1.5,1.5V15c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2v-1.5c0-0.8,0.7-1.5,1.5-1.5
            C9.7,12,9,11.3,9,10.5V9c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2V10.5z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam9Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam9" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z
		 M13,7h-2C9.9,7,9,7.9,9,9v2c0,1.1,0.9,2,2,2h2v2H9v2h4c1.1,0,2-0.9,2-2V9C15,7.9,14.1,7,13,7z M13,11h-2V9h2V11z"
            />
          </SvgIcon>
        )}
        {props.color === "secondary" && (
          <SvgIcon>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15,15
            c0,1.1-0.9,2-2,2H9v-2h4v-2h-2c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2V15z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export function Webcam10Button(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Send user to webcam input")}
      arrow
    >
      <IconButton size="small" aria-label="Webcam10" {...props}>
        {props.color === "primary" && (
          <SvgIcon>
            <polygon points="9,17 11,17 11,7 7,7 7,9 9,9 		" />
            <path d="M15,7h-1c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h1c1.1,0,2-0.9,2-2V9C17,7.9,16.1,7,15,7z M15,15h-1V9h1V15z" />
            <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
          </SvgIcon>
        )}

        {props.color === "secondary" && (
          <SvgIcon>
            <rect x="14" y="9" width="1" height="6" />
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M11,17H9V9H7V7h4V17z
				 M17,15c0,1.1-0.9,2-2,2h-1c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h1c1.1,0,2,0.9,2,2V15z"
            />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}
//AddIcCallIcon

export function AddUserToModeratedButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Add user to moderated list")}
      arrow
    >
      <IconButton size="small" aria-label="addUserToModerated" {...props}>
        <CheckIcon />
      </IconButton>
    </Tooltip>
  );
}

export function ConnectUserButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Connect user to real time system")}
      arrow
    >
      <IconButton size="small" aria-label="ConnectUser" {...props}>
        <SvgIcon>
          <g>
            <path d="M12,15.1c-3.3,0-10,1.7-10,5V22h20v-1.9C22,16.8,15.3,15.1,12,15.1z" />
            <path
              d="M12,13.4c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S8.7,13.4,12,13.4z M12,3.2c2.3,0,4.2,1.9,4.2,4.2
				s-1.9,4.2-4.2,4.2S7.8,9.7,7.8,7.4S9.7,3.2,12,3.2z"
            />
            <polygon points="12.5,7.4 12.5,4.6 11.1,4.6 11.1,8.2 13.9,9.8 14.6,8.7 			" />
          </g>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export function MuteCamButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Mute/Unmute user Camera")}
      arrow
    >
      <IconButton size="small" aria-label="Mute/Unmute Cam" {...props}>
        {props.color === "primary" && <Videocam />}

        {props.color === "secondary" && <VideocamOff color="secondary" />}
      </IconButton>
    </Tooltip>
  );
}

export function MuteMicButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Mute/Unmute user Microphone")}
      arrow
      {...props}
    >
      <IconButton size="small" aria-label="Mute/Unmute Mic" {...props}>
        {props.color === "primary" && <Mic />}

        {props.color === "secondary" && <MicOff color="secondary" />}
      </IconButton>
    </Tooltip>
  );
}

export function MuteLocalCamButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Mute/Unmute Camera")}
      arrow
      {...props}
    >
      <IconButton size="small" aria-label="Mute/Unmute Cam" {...props}>
        {props.disabled === false && props.isvideoactive === "true" && (
          <Videocam />
        )}
        {props.disabled === false && props.isvideoactive === "false" && (
          <VideocamOff color="secondary" />
        )}
        {props.disabled === true && <Videocam />}
      </IconButton>
    </Tooltip>
  );
}

export function MuteLocalMicButton(props) {
  return (
    <Tooltip
      title={i18nInstance.t("CommonButtons|Mute/Unmute Microphone")}
      arrow
      {...props}
    >
      <IconButton size="small" aria-label="Mute/Unmute Mic" {...props}>
        {props.disabled === false && props.isaudioactive === "true" && <Mic />}
        {props.disabled === false && props.isaudioactive === "false" && (
          <MicOff color="secondary" />
        )}
        {props.disabled === true && <Mic />}
      </IconButton>
    </Tooltip>
  );
}
