import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  title: {
    "& span" : {
      fontSize: "1.3vw",
    }
  },
  cardContent: {
    padding: "0.2vw",
  },
  datePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
  },
  buttonClass: {
    margin: "5px",
  },
  textField : {
    width: '90%',
  },
  label : {
    fontSize: "0.8vw"
  },
  input : {
    fontSize: "0.8vw"
  },
  formControl : {
    "& span" : {
      fontSize: "0.9vw",
    }
  },
  ckeckBox : {
    fontSize: "0.8vw"
  },
  dateTextField : {

  },
  dateInput : {

  }
}));

export default function ContributorTokenCreator(props) {
  const [expiringToken, setExpiringToken] = useState(true);
  const [passwordProtected, setPasswordProtected] = useState(true);

  const [helperTextForEmail, setHelperTextForEmail] = useState("");
  const [helperTextForName, setHelperTextForName] = useState("");
  const [helperTextForPassword, setHelperTextForPassword] = useState("");
  const [helperTextForDate, setHelperTextForDate] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const { t, i18next } = useTranslation();
  const classes = useStyles();

  const handleCheckPasswordProtected = (event, isInputChecked) => {
    setPasswordProtected(isInputChecked);
  };

  const handleCheckExpiringToken = (event, isInputChecked) => {
    setExpiringToken(isInputChecked);
  };
  const validateDate = (date) => {
    const dateToCHeck = new Date(date);
    var now = new Date();
    if (dateToCHeck < now) {
      return false
    }
    return true
  }
  const createContributorToken = (withEmail) => {
    let completed = true;
    const name = document.getElementById("nameToken").value;
    const email = document.getElementById("emailToken").value;
    let passwordProtectedToken = false;
    
    if (
      passwordProtected &&
      document.getElementById("passwordToken").value !== "" &&
      document.getElementById("passwordToken").value.length > 4
    ) {
      passwordProtectedToken = document.getElementById("passwordToken").value;
      setHelperTextForPassword("");
      setPasswordError(false);
    } else if (passwordProtected) {
      completed = false;
      setHelperTextForPassword(t("ContributorsComponent|passwordError"));
      setPasswordError(true);
    }
    let expirationDate = "0";
    if (expiringToken && document.getElementById("date").value !== "" && validateDate(document.getElementById("date").value)) {
      expirationDate = document.getElementById("date").value;
      setHelperTextForDate("");
      setDateError(false);
    } else if (expiringToken) {
      completed = false;
      if ( document.getElementById("date").value === "") {
        setHelperTextForDate(t("ContributorsComponent|dateError"));
      } else if (!validateDate(document.getElementById("date").value)) {
        setHelperTextForDate(t("ContributorsComponent|dateExpiredError"));
      }
      setDateError(true);
    }
    let tokenToCreate = {
      date: expirationDate,
      type: "webcamCall",
      singleUse: false,
      passwordProtected: passwordProtected,
      password: passwordProtectedToken,
      name: name,
      username: email,
      broadcastid: props.localUser.getBroadcastId(),
    };

    let message = {
      id: "webcamcall",
      type: "createToken",
      token: tokenToCreate,
      broadcastid: props.localUser.getBroadcastId(),
      broadcaster: props.localUser.getBroadcaster(),
    };

    if (!validateEmail(document.getElementById("emailToken").value)) {
      completed = false;
      setHelperTextForEmail(t("ContributorsComponent|copyEmailError"));
      setEmailError(true);
    } else {
      setHelperTextForEmail("");
      setEmailError(false);
    }

    if (document.getElementById("nameToken").value === "") {
      completed = false;
      setHelperTextForName(t("ContributorsComponent|nameError"));
      setNameError(true);
    } else {
      setHelperTextForName("");
      setNameError(false);
    }

    if (completed) {
      props.createToken(message);
      props.openList();
      //empty the field
      document.getElementById("nameToken").value = "";
      document.getElementById("emailToken").value = "";
      document.getElementById("passwordToken").value = "";
      setHelperTextForEmail("");
      setEmailError(false);
      setHelperTextForName("");
      setNameError(false);
      setHelperTextForDate("");
      setDateError(false);
      setHelperTextForPassword("");
      setPasswordError(false);
    }
  };

  var date = new Date()
  date.setDate(date.getDate() + 7);
  let year = date.getFullYear().toString();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (day.toString().length === 1) {
    day = "0" + day.toString();
  }
  if (month.toString().length === 1) {
    month = "0" + month.toString();
  }
  let stringDate = year + "-" + month + "-" + day;
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const close = (event) => {
    props.openList();
    document.getElementById("nameToken").value = "";
    document.getElementById("emailToken").value = "";
    document.getElementById("passwordToken").value = "";
    setHelperTextForEmail("");
    setEmailError(false);
    setHelperTextForName("");
    setNameError(false);
    setHelperTextForDate("");
    setDateError(false);
    setHelperTextForPassword("");
    setPasswordError(false);
  };
  return (
    <Card className={classes.root} autoComplete="one-time-code"
    readonly>
      <CardHeader className={classes.title} title={t("ContributorsComponent|CreateToken")} />
      <CardContent className={classes.cardContent}>
        <TextField
          autoComplete="one-time-code"
          readonly
          error={nameError}
          helperText={helperTextForName}
          required
          size="small"
          id="nameToken"
          label={t("ContributorsComponent|Name")}
          variant="outlined"
          className={classes.textField}
          InputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <TextField
          autoComplete="one-time-code"
          readonly
          error={emailError}
          helperText={helperTextForEmail}
          required
          size="small"
          id="emailToken"
          label={t("ContributorsComponent|Email")}
          variant="outlined"
          className={classes.textField}
          InputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      </CardContent>
      <CardContent
        style={{ display: passwordProtected ? "block" : "none" }}
        className={classes.cardContent}
      >
        <TextField
          autoComplete="one-time-code"
          readonly
          error={passwordError}
          helperText={helperTextForPassword}
          type="password"
          required
          size="small"
          id="passwordToken"
          inputProps={{
            autocomplete: "new-password",
            className: classes.input,
            form: {
              autocomplete: "off",
            },
          }}
          label={t("ContributorsComponent|Password")}
          variant="outlined"
          className={classes.textField}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <FormControlLabel
        className={classes.formControl}
          control={
            <Checkbox
              id="passwordProtected"
              defaultChecked
              onChange={handleCheckPasswordProtected}
              className={classes.checkbox}
            />
          }
          label={t("ContributorsComponent|passwordProtected")}
        />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <FormControlLabel
          id="ExpiringInvitation2"
          className={classes.formControl}
          control={
            <Checkbox
              id="ExpiringInvitation"
              defaultChecked
              onChange={handleCheckExpiringToken}
              className={classes.checkbox}
            />
          }
          label={t("ContributorsComponent|ExpiringInvitation")}
        />
      </CardContent>
      <CardContent
        className={classes.cardContent}
        style={{ display: expiringToken ? "block" : "none" }}
      >
        <TextField
          error={dateError}
          helperText={helperTextForDate}
          id="date"
          size="small"
          label={t("ContributorsComponent|ExpiringDate")}
          type="date"
          defaultValue={stringDate}
          className={classes.datePicker}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.dateTextField}
          InputProps={{
            className: classes.dateInput,
          }}
        />
      </CardContent>
      <CardActions>
        <Box component="div" className={classes.buttonContainer}>
          <Button
            onClick={() => createContributorToken(false)}
            className={classes.buttonClass}
          >
            {" "}
            {t("ContributorsComponent|CreateNewToken")}
          </Button>
          <Button onClick={close} className={classes.buttonClass}>
            {" "}
            {t("ContributorsComponent|CancelInvitation")}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
