import React, { Component } from "react";
import i18nInstance from "../../i18n";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import { toggle } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import "./ToolbarComponent.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import { LocalStorageManager } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/storage/LocalStorageManager";
import IconButton from "@material-ui/core/IconButton";
import { reflexWsDisconnect } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/middlewares/actions/reflexApiActions";
import {
  localMicStatusChanged,
  localCamStatusChanged,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { Settings } from "@material-ui/icons";
import {
  LanguageButton,
  MuteLocalCamButton,
  MuteLocalMicButton,
} from "../buttons/commonButtons";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { store } from "react-notifications-component";
import packageJson from "../../../package.json";
import { IntercomStatus } from "./IntercomStatus";
import { addOrRemoveMeFromWhispering } from "../../utils/participantUtils";
import notificationSound from "../../sounds/notifications/Pop_Ding_Notification_Sound_01.mp3";
import { MonitorWallButton } from "../callModerator/components/MonitorWallButton";
import { withStyles } from "@material-ui/core/styles";
import UserAvatar from "../callModerator/components/users/UserAvatar";
const logo = require("../../assets/images/_reflex_logo_Horiz_rouge_RGB.png");
var audioNotification = new Audio(notificationSound);
const styles = (theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35vw",
    height: "20vh",
    maxHeight: "30vh",
    overflow: "hidden",
    backgroundColor: theme.palette.grey1.main,
    textAlign: "center"
  },
});
class ToolbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
      connected: false,
      publishingReady: false,
      cameraConnected: false,
      screenSharingSlot: [],
      showNotification: false,
      chatDisplay: "none",
      openPlayIntercomAudio: false,
    };
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.browserUtils = new BrowserUtils();
    this.changeLanguage = this.changeLanguage.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.modalFixAudio = this.modalFixAudio.bind(this);
  }

  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (prevProps.screenSharingSlot != this.props.screenSharingSlot) {
      state.screenSharingSlot = this.props.screenSharingSlot;
    }
    if (prevProps.chatDisplay !== this.props.chatDisplay) {
      state.chatDisplay = this.props.chatDisplay;
    }
    if (prevProps.playIntercomAudioEvent !== this.props.playIntercomAudioEvent) {
      state.playIntercomAudioEvent = this.props.playIntercomAudioEvent;
      state.openPlayIntercomAudio = true;
    }
    if (prevProps.chatNotificationEvent !== this.props.chatNotificationEvent) {
      if (this.state.chatDisplay === "none") {
        state.showNotification = true;
      } else {
        state.showNotification = false;
      }
      if (!this.props.user.isStreamer()) {
        setTimeout(() => {
          audioNotification.play();
        }, 50);
      }
      
    }

    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }

  micStatusChanged() {
    localMicStatusChanged();
  }

  camStatusChanged() {
    localCamStatusChanged();
  }

  screenShare() {
    this.props.screenShare();
  }

  stopScreenShare() {
    this.props.stopScreenShare();
  }

  toggleFullscreen() {
    this.setState({ fullscreen: !this.state.fullscreen });
    this.browserUtils.toggleFullscreen();
  }

  leaveSession() {
    //TODO Add modal to confirm disconnection

    this.props.dispatch(reflexWsDisconnect());
  }
  changeLanguage() {
    if (i18nInstance.t("locale") === "en") {
      i18nInstance.changeLanguage("fr");
      LocalStorageManager.setStorage(
        LocalStorageManager.STORAGE_KEYS.LANGUAGE,
        "fr"
      );
      this.props.user.setLanguage("fr");
      websocketServices.sendSignalUserChanged({
        language: "fr",
      });
    } else {
      i18nInstance.changeLanguage("en");
      LocalStorageManager.setStorage(
        LocalStorageManager.STORAGE_KEYS.LANGUAGE,
        "en"
      );
      this.props.user.setLanguage("en");
      websocketServices.sendSignalUserChanged({
        language: "en",
      });
    }
  }
  showInfo() { // TODO: add user info
    const year = new Date().getFullYear();
    store.addNotification({
      title: "Informations",
      // message:
      //   i18nInstance.t("playerVersion") +
      //   packageJson.version +
      //   " | " +
      //   i18nInstance.t("footerAppBar|Currently using React") +
      //   " " +
      //   React.version +
      //   " | " +
      //   i18nInstance.t("footerAppBar|and WebRTC Server") +
      //   i18nInstance.t("version") +
      //   " | Copyright \xA9 " +
      //   year +
      //   " 9337-9899 Quebec inc.",
      message: (
        <>
          <Typography>user: {this.props.user.nickname}</Typography>
          <Typography>{i18nInstance.t("playerVersion")} {packageJson.version}</Typography>
          <Typography>{i18nInstance.t("footerAppBar|Currently using React")} {React.version}</Typography>
          <Typography>{i18nInstance.t("footerAppBar|and WebRTC Server")} {i18nInstance.t("version")}</Typography>
          <br/>
          <Typography>{"Copyright \xA9 "}{ year }</Typography>
          <Typography> {" 9337-9899 Quebec inc."}</Typography>
        </>
      ),
      type: "info",
      insert: "center",
      container: "center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      // content: <ReflexNotification  type={"info"} title={"Informations"} message={i18nInstance.t("playerVersion") + packageJson.version + " | " + i18nInstance.t("footerAppBar|Currently using React") + " " + React.version + " | "  + i18nInstance.t("footerAppBar|and WebRTC Server") + i18nInstance.t("version") + " | Copyright \xA9 " + year + " 9337-9899 Quebec inc."} />,
    });
  }

  modalFixAudio() {
    for (const audioElement of document.getElementById("audioContainer").children) {
      audioElement.play()
    }
    this.setState({openPlayIntercomAudio: false})
  }

  render() {
    const { classes, theme } = this.props;
    const mySessionId = this.props.sessionId;
    const localUser = this.props.user;
    const toggleChat = () => {
      this.props.dispatch(toggle());
      if (this.state.chatDisplay === "none") {
        this.setState({ showNotification: false });
      }
    };
    const screenSharingAllowed = Object.values(
      this.state.screenSharingSlot
    ).find((it) => it.userid === localUser.getUserId());
    if (!screenSharingAllowed && this.props.isScreenShareActive) {
      this.stopScreenShare();
    }
    const devicesSettingsDisabled =
      this.state.cameraConnected &&
      localUser !== undefined &&
      !localUser.isStreamer();
    const disconnectDisabled =
      !this.state.connected ||
      (localUser !== undefined && localUser.isStreamer());
    return (
      <AppBar className="toolbar" id="header">
        <Toolbar className="toolbar">
          <div id="navSessionInfo">
            <img id="header_img" alt="Reflex Interactions Logo" src={logo} />

            {this.props.sessionId && (
              <div id="titleContent">
                <span id="session-title">{mySessionId}</span>
              </div>
            )}
          </div>

          <div className="buttonsContent">
            {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined && (
                <MuteLocalMicButton
                  className="navButton"
                  disabled={
                    !this.state.cameraConnected || localUser.isStreamer()
                  }
                  isaudioactive={this.props.isaudioactive}
                  onClick={this.micStatusChanged}
                  id="navMicButton"
                />
              )}
            {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined && (
                <MuteLocalCamButton
                  disabled={
                    !this.state.cameraConnected || localUser.isStreamer()
                  }
                  isvideoactive={this.props.isvideoactive}
                  className="navButton"
                  id="navCamButton"
                  onClick={this.camStatusChanged}
                />
              )}
            {localUser !== undefined && !localUser.isCallModerator() && (
              <IconButton
                color="inherit"
                className="navButton"
                onClick={this.toggleFullscreen}
              >
                {this.state.fullscreen ? (
                  <Tooltip
                    title={i18nInstance.t("ToolBar|Exit fullScreen")}
                    arrow
                  >
                    <FullscreenExit />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={i18nInstance.t("ToolBar|Make player fullScreen")}
                    arrow
                  >
                    <Fullscreen />
                  </Tooltip>
                )}
              </IconButton>
            )}

            <Tooltip
              title={i18nInstance.t("ToolBar|Configure webcam and microphone")}
              arrow
            >
              <IconButton
                color="inherit"
                className="navButton"
                onClick={this.props.toggleDevicesSettings}
              >
                <Settings />
              </IconButton>
            </Tooltip>
            {localUser !== undefined && localUser.isCallModerator() && (
              <MonitorWallButton />
            )}
            {/* {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined &&
              !localUser.isStreamer() && (
                <Tooltip
                  title={i18nInstance.t(
                    "ToolBar|Disconnect from Reflex player"
                  )}
                  arrow
                >
                  <IconButton
                    color="secondary"
                    disabled={disconnectDisabled}
                    className="navButton"
                    onClick={this.leaveSession}
                    id="navLeaveButton"
                  >
                    <PowerSettingsNew />
                  </IconButton>
                </Tooltip>
              )} */}
            {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined &&
              !localUser.isStreamer() && (
                <IntercomStatus
                  id="intercomStatusButton"
                  addOrRemoveMeFromWhispering={addOrRemoveMeFromWhispering}
                  localUser={localUser}
                />
              )}

            <IconButton id="infoButton" onClick={this.showInfo}>
              <InfoIcon />
            </IconButton>
            <Box
              component="span"
              id="languageButton"
              onClick={this.changeLanguage}
            >
              <LanguageButton />
              <Trans>localeName</Trans>
            </Box>

            {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined && (
                <IconButton
                  color="inherit"
                  disabled={
                    this.props.chatDisabled ||
                    !this.state.connected ||
                    localUser.isStreamer()
                  }
                  onClick={toggleChat}
                  id="navChatButton"
                >
                  {this.state.showNotification && (
                    <div id="point" className="" />
                  )}
                  <Tooltip title={i18nInstance.t("chat|Open Chat window")}>
                    <QuestionAnswer />
                  </Tooltip>
                </IconButton>
              )}
          </div>


          <Modal open={this.state.openPlayIntercomAudio} >
            <Box className={classes.root}>
              <Typography>
                {i18nInstance.t("global|restartAudio")}
              </Typography>
              <Button onClick={this.modalFixAudio}>
                OK
              </Button> 
            </Box>
          </Modal>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  chatDisabled: state.ui.chat.chatDisabled,
  chatDisplay: state.ui.chat.chatDisplay,
  connected: state.websocket.connection.connected,
  publishingReady: state.websocket.connection.events.publishingReady,
  cameraConnected: state.websocket.connection.cameraConnected,
  screenSharingSlot: state.websocket.call.onAir[100],
  chatNotificationEvent: state.websocket.chat.notificationEvent,
  playIntercomAudioEvent: state.ui.playIntercomAudioEvent
});

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(ToolbarComponent)));
