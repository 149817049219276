import React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import VideoRoomComponent from "../videoRoom/VideoRoomComponent";
import { Box, Grid, Typography, Modal } from "@material-ui/core/";
import ToolbarComponent from "../toolbar/ToolbarComponent";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import {
  callDidUpdate,
  DevicesSettingsManager,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import ChatComponent from "../chat/ChatComponent";
import DevicesSettings from "../commons/DevicesSettings";
import MainVideoRoomComponent from "../mainVideoContainer/MainVideoRoomComponent";
import WelcomeScreen from "../commons/WelcomeScreen";
import ReactModal from "react-modal-resizable-draggable";
import ScreenSharingMonitor from "../commons/ScreenSharingMonitor";
import PulseLoader from "react-spinners/PulseLoader";
import { localUserErrorNotification } from "../../utils/participantUtils";
import OvVideoComponentHidden from "../stream/OvVideoHidden";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { OnAirStatus } from "./OnAirStatus";
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';

const styles = (theme) => ({
  reflexPlayer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey4.main,
    overflow: "hidden",
  },
});
class ReflexPlayer extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();

    this.state = {
      dimension: this.browserUtil.getWindowDimensions(),
      messageReceived: false,
      publishingReady: false,
      subscribingReady: false,
      localUser: undefined,
      userOnAir: false,
      devicesSettingsOpened: false,
      devices: null,
      connectWebcam: false,
      connectWebcamOnAir: false,
      devicesSettingsIsOpen: "none",
      deviceSettingsIsOnAir: false,
      cameraConnected: false,
      modalIsOpen: false,
      screenShareSlot: [],
      startIntercom: false,
      initCompleted: false,
      isMobile: this.browserUtil.isMobile(),
      alreadyLogin: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.isAudioActive = this.isAudioActive.bind(this);
    this.isVideoActive = this.isVideoActive.bind(this);
    this.handleClosedDevicesSettings =
      this.handleClosedDevicesSettings.bind(this);
    this.toggleDevicesSettings = this.toggleDevicesSettings.bind(this);
    this.reduxToggleDevicesSettings = this.reduxOpenDevicesSettings.bind(this);
    this.isScreenShareActive = this.isScreenShareActive.bind(this);
  }

  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);
    if (
      prevProps.localUserReady !== this.props.localUserReady &&
      this.props.localUserReady === true
    ) {
      state.localUser = websocketServices.localUser;
      state.subscribers = websocketServices.subscribers;
    }
    if (prevProps.onAir !== this.props.onAir) {
      state.screenShareSlot = this.props.onAir["100"];
      if (
        this.props.isOnAir === true &&
        this.props.onAir["1"][0] !== undefined &&
        this.props.onAir["1"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["2"][0] !== undefined &&
        this.props.onAir["2"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["3"][0] !== undefined &&
        this.props.onAir["3"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["4"][0] !== undefined &&
        this.props.onAir["4"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["5"][0] !== undefined &&
        this.props.onAir["5"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["6"][0] !== undefined &&
        this.props.onAir["6"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["7"][0] !== undefined &&
        this.props.onAir["7"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["8"][0] !== undefined &&
        this.props.onAir["8"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["9"][0] !== undefined &&
        this.props.onAir["9"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else if (
        this.props.isOnAir === true &&
        this.props.onAir["10"][0] !== undefined &&
        this.props.onAir["10"][0].userid ===
          websocketServices.localUser.getUserId()
      ) {
        state.userOnAir = true;
      } else {
        state.userOnAir = false;
      }
    }
    if (prevProps.devicesSettingsIsOpen !== this.props.devicesSettingsIsOpen) {
      this.reduxOpenDevicesSettings(this.props.deviceSettings);
    }
    if (prevProps.chatDisplay !== this.props.chatDisplay) {
      if (this.props.chatDisplay === "none") {
        state.modalIsOpen = false;
      } else {
        state.modalIsOpen = true;
      }
    }
    if (prevProps.error !== this.props.error) {
      localUserErrorNotification(this.props.error);
    }
    if (prevProps.alreadyLogin !== this.props.alreadyLogin) {
      if (this.props.alreadyLogin === true) {
        state.alreadyLogin = this.props.alreadyLogin;
      }
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }

  isAudioActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isAudioActive &&
      this.state.localUser.isAudioActive()
    );
  }

  isVideoActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isVideoActive &&
      this.state.localUser.isVideoActive()
    );
  }
  isScreenShareActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isScreenShareActive &&
      this.state.localUser.isScreenShareActive()
    );
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  toggleDevicesSettings() {
    if (!this.state.devicesSettingsOpened) {
      DevicesSettingsManager.getDevices().then((_devices) => {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: false,
          connectWebcamOnAir: false,
        });
      });
    } else {
      this.setState({
        devices: null,
        devicesSettingsOpened: false,
        connectWebcam: false,
        connectWebcamOnAir: false,
      });
    }
  }
  reduxOpenDevicesSettings(deviceSettings) {
    DevicesSettingsManager.getDevices().then((_devices) => {
      if (!deviceSettings.onIntercom) {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: deviceSettings.connectWebcam,
          connectWebcamOnAir: deviceSettings.onAir,
          startIntercom: deviceSettings.onIntercom,
        });
      } else {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: deviceSettings.connectWebcam,
          connectWebcamOnAir: deviceSettings.onAir,
          startIntercom: deviceSettings.onIntercom,
        });
      }
    });
  }
  handleClosedDevicesSettings() {}

  openModal() {
    this.setState({ modalIsOpen: true });
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  render() {
    const { classes, theme } = this.props;
    let welcomeMessage = "";
    let welcomeImage = undefined;
    const localUser = this.state.localUser;
    var playerWidth = this.state.dimension.width + "px";
    var playerHeight = this.state.dimension.height + "px";
    var ratio = 0;
    let screenSharingAllowed = false;
    if (localUser !== undefined) {
      welcomeMessage = localUser.getWelcome();
      welcomeImage = localUser.getWelcomeImage().playlists[0].image;
      screenSharingAllowed = Object.values(this.state.screenShareSlot).find(
        (it) => it.userid === localUser.getUserId()
      );
    }

    if (
      this.state.publishingReady === true ||
      this.state.subscribingReady === true
    ) {
      ratio = 0.2;
    }
    var videoroomHeight = ratio * playerHeight;
    var lowerHeight = playerHeight - videoroomHeight;

    

    let isPortrait = false;
    if (this.state.dimension.width < this.state.dimension.height) {
      isPortrait = true;
    }

    if (this.state.alreadyLogin) {
      return (
        <Redirect
          to={{
            pathname: "/alreadyLogin",
          }}
        />
      );
    }
    return (
      <Box
        className={classes.reflexPlayer}
        id="container"
        style={{ display: "flex" }}
      >
        <Modal
          open={this.state.devicesSettingsOpened}
          onClose={this.handleClosedDevicesSettings}
        >
          <DevicesSettings
            devices={this.state.devices}
            toggleDevicesSettings={this.toggleDevicesSettings}
            localUser={this.state.localUser}
            connectWebcam={this.state.connectWebcam}
            connectWebcamOnAir={this.state.connectWebcamOnAir}
            startIntercom={this.state.startIntercom}
          />
        </Modal>

        <ReactModal
          initWidth={"20vw"}
          initHeight={"50vh"}
          left={20}
          top={"20VH"}
          onFocus={() => console.log("Modal is clicked")}
          onRequestClose={this.closeModal}
          isOpen={this.state.modalIsOpen}
        >
          {localUser !== undefined &&
            localUser.getConnectionId !== undefined &&
            localUser.getConnectionId() !== undefined && (
              <ChatComponent
                user={localUser}
                websocketServices={websocketServices}
                height={"60vh"}
                cameraConnected={false}
                
              />
            )}
        </ReactModal>

        <ToolbarComponent
          sessionId={websocketServices.callSessionId}
          user={localUser}
          camStatusChanged={websocketServices.camStatusChanged}
          micStatusChanged={websocketServices.micStatusChanged}
          screenShare={websocketServices.screenShare}
          isaudioactive={"" + this.isAudioActive()}
          isvideoactive={"" + this.isVideoActive()}
          isScreenShareActive={this.isScreenShareActive()}
          stopScreenShare={websocketServices.stopScreenShare}
          leaveSession={websocketServices.leaveSession}
          toggleDevicesSettings={this.toggleDevicesSettings}
        />

        <Grid
          id="reflexPlayerContainer"
          direction="row" // using this with xs={12} on children allows us to use the full with and stack as columns at the same time
          justifyContent="center"
          container
          style={{ marginTop: "40px" }}
          spacing={0}
        >
          <Grid id="videoRoomContainer" item xs={12}>
            <Box
              style={{ display: this.state.cameraConnected ? "block" : "none" }}
            >
              <VideoRoomComponent
                ratio={ratio}
                // checkNotification={this.checkNotification}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box justifyContent="center">
              <Box width="100%" className="player">
                <Box
                  width="100%"
                  height="100%"
                  style={{
                    display: !this.state.userOnAir ? "block" : "none",
                    // position: "relative"
                  }}
                >
                  <WelcomeScreen
                    welcomeMessage={welcomeMessage}
                    welcomeImage={welcomeImage}
                  />
                </Box>
                <Box
                  style={{
                    display: this.state.userOnAir ? "block" : "none",
                  }}
                >
                  {localUser !== undefined &&
                    localUser.getConnectionId !== undefined &&
                    localUser.getConnectionId() !== undefined && (
                      <MainVideoRoomComponent
                        ratio={1 - ratio}
                        // checkNotification={this.checkNotification}
                        localUser={localUser}
                      />
                    )}
                </Box>
                {/* <Box id="MultiFunctionSpaceContainer">
                  TODO: implement
                </Box>
                <Box id="OnTheFLyContainer">
                  TODO: implement
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {localUser !== undefined &&
          localUser.getConnectionId !== undefined &&
          localUser.getConnectionId() !== undefined &&
          !this.state.isMobile && (
            <ScreenSharingMonitor
              screenSharingStarted={localUser.isScreenShareActive()}
              screenSharingAllowed={screenSharingAllowed}
              startScreenShare={websocketServices.startScreenShare}
              stopScreenShare={websocketServices.stopScreenShare}
            />
          )}

        {
          localUser?.getConnectionId !== undefined &&  
          localUser.getConnectionId() !== undefined && 
          !this.state.isMobile && (
            <OnAirStatus/>
          )
        }

        {/* TODO: Display component if orientation is portrait that say turn your device to landscape */}
        {isPortrait && 
        (<Box
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: "999999",
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle"
          }}
          
        >
          <Box style={{margin:"auto",padding:"20px"}}><ScreenRotationIcon style={{fontSize:"25vh"}}/><Typography style={{fontSize:"5vh"}}>
          <Trans>global|pleaseRotate</Trans>
        </Typography></Box>
          
        </Box>)
        }

        <Box
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: !this.state.initCompleted ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000000",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderWidth: "0px",
            }}
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Trans>global|waitingInit</Trans>
            </Typography>
            <PulseLoader
              color="#ff2900"
              loading="true"
              style={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
                borderWidth: "0px",
              }}
              size={35}
            />
          </Box>
        </Box>
        <Box id="audioContainer" style={{ display: "none" }} />
        {/* /////////////////////////////////////////////////////////////////////////////////
        //                     Modif Tensorflow */}
        {/* {localUser !== undefined &&
              localUser.getConnectionId !== undefined &&
              localUser.getConnectionId() !== undefined && (
        <OvVideoComponentHidden user={localUser} style={{display: "none"}}/>)} */}
        {/* ///////////////////////////////////////////////////////////////////////////////// */}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  initCompleted: state.ui.initCompleted,
  chatDisplay: state.ui.chat.chatDisplay,
  isOnAir: state.websocket.call.isOnAir,
  onAir: state.websocket.call.onAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  publishingReady: state.websocket.connection.events.publishingReady,
  subscribingReady: state.websocket.connection.events.subscribingReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  localUserRefresh: state.websocket.callEvents.localUserRefresh,
  devicesSettingsIsOpen: state.ui.devicesSettings.isOpenEvent,
  deviceSettings: state.ui.devicesSettings,
  cameraConnected: state.websocket.connection.cameraConnected,
  error: state.websocket.connection.events.error,
  alreadyLogin: state.websocket.connection.alreadyLogin,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(ReflexPlayer))
);
