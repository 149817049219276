import React, { Component } from "react";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setTutorialScenario } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { resetTutorialScenario } from "./callModeratorScenarios";

class TutorialController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepsEnabled: false,
      initialStep: 0,
      steps: [],
      hintsEnabled: false,
      hints: [],
    };
  }

  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints,
    } = this.state;

    return (
      <div>
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        />
        <Hints enabled={hintsEnabled} hints={hints} />
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    var state = {};
    if (prevProps.hints !== this.props.hints) {
      state.hints = this.props.hints;
      state.hintsEnabled = true;
    }
    if (prevProps.steps !== this.props.steps) {
      state.steps = this.props.steps;
      state.stepsEnabled = true;
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
    this.props.dispatch(setTutorialScenario(resetTutorialScenario));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  toggleHints = () => {
    this.setState((prevState) => ({ hintsEnabled: !prevState.hintsEnabled }));
  };
}
const mapStateToProps = (state) => ({
  steps: state.ui.tutorialScenario.steps,
  hints: state.ui.tutorialScenario.hints,
});
export default withTranslation()(connect(mapStateToProps)(TutorialController));
