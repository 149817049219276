import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  Paper,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core/";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import WebcamSelectComponent from "./WebcamSelectorComponent";
import {
  resetEvent,
  setSelectedUser,
  setTutorialScenario,
  setUserInputValue,
  setWebcamInputValue,
  setSelectedWebcam,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import {
  moderatedNotSelected,
  participantNotSelected,
  participantWebcamIdNotSelected,
  webcamIdNotSelected,
  webcamSlotNotSelected,
  webcamSlotWebcamIdNotSelected,
} from "../../../../tutorials/callModeratorScenarios";
import {
  MODERATED,
  MODERATED_INPUT_VALUE,
  PARTICIPANTS,
  PARTICIPANTS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import { CommandsButton } from "../../../../commons/CommandsButton";
import { StyledTypography } from "../../../../commons/StyledTypography";
class CallCommandComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      webcamSelectIsOpen: false,
    };
    // this.setOnAir = this.setOnAir.bind(this);
    this.addToCall = this.addToCall.bind(this);
    this.addModerated = this.addModerated.bind(this);
    // this.clearCall = this.clearCall.bind(this);
    this.clearModerated = this.clearModerated.bind(this);
    this.clearWebcamPlayerCall = this.clearWebcamPlayerCall.bind(this);
    this.removeFromCall = this.removeFromCall.bind(this);
    this.removeModerated = this.removeModerated.bind(this);
  }
  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);

    if (prevProps.webcamSelectIsOpen !== this.props.webcamSelectIsOpen) {
      state.webcamSelectIsOpen = this.props.webcamSelectIsOpen;
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  // setOnAir() {
  //   if (this.state.isOnAir) {
  //     websocketServices.signals.stopCall();
  //   } else {
  //     websocketServices.signals.startCall();
  //   }
  // }
  // clearCall() {
  //   websocketServices.signals.clearCall();
  // }
  addToCall() {
    var user;

    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    if (
      this.props.participantSelected === null &&
      this.props.webcamIdSelected === null
    ) {
      this.props.dispatch(setTutorialScenario(participantWebcamIdNotSelected));
    } else if (this.props.participantSelected === null) {
      this.props.dispatch(setTutorialScenario(participantNotSelected));
    } else if (this.props.webcamIdSelected === null) {
      this.props.dispatch(setTutorialScenario(webcamIdNotSelected));
    } else {
      user = {
        userid: this.props.participantSelected.userid,
        username: this.props.participantSelected.username,
        // avatar: this.props.participantSelected.avatar,
      };
      websocketServices.signals.addCallParticipant(
        user,
        this.props.webcamIdSelected
      );
      this.props.dispatch(
        setUserInputValue({ type: PARTICIPANTS_INPUT_VALUE, value: "" })
      );
      var payload = { userType: PARTICIPANTS, value: null };
      this.props.dispatch(setSelectedUser(payload));
    }
  }
  removeFromCall() {
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    var userWebcamId = null;
    var selectedId = this.props.webcamIdSelected;
    try {
      if (this.props.webcamIdSelected !== null) {
        userWebcamId = this.props.webcamSelected[this.props.webcamIdSelected];
      }
    } catch (e) {}
    if (userWebcamId === null && this.props.webcamIdSelected === null) {
      this.props.dispatch(
        setTutorialScenario(
          webcamSlotWebcamIdNotSelected(this.props.webcamIdSelected)
        )
      );
    } else if (userWebcamId === null) {
      if (this.state.isOnAir) {
        user = {
          userid: this.props.onAir[this.props.webcamIdSelected]["0"].userid,
          username: this.props.onAir[this.props.webcamIdSelected]["0"].username,
          // avatar: this.props.onAir[this.props.webcamIdSelected]["0"].avatar,
          id: this.props.onAir[this.props.webcamIdSelected]["0"].id,
        };

        websocketServices.signals.removeCallParticipant(
          user,
          this.props.webcamIdSelected
        );
        this.props.dispatch(
          setWebcamInputValue({
            webcamId: this.props.webcamIdSelected,
            value: "",
          })
        );
        this.props.dispatch(
          setSelectedWebcam({
            webcamId: this.props.webcamIdSelected,
            value: null,
          })
        );
      } else {
        this.props.dispatch(
          setTutorialScenario(
            webcamSlotNotSelected(this.props.webcamIdSelected)
          )
        );
      }
    } else if (this.props.webcamIdSelected === null) {
      this.props.dispatch(setTutorialScenario(webcamIdNotSelected));
    } else {
      user = {
        userid: userWebcamId.userid,
        username: userWebcamId.username,
        // avatar: userWebcamId.avatar,
        id: userWebcamId.id,
      };

      websocketServices.signals.removeCallParticipant(
        user,
        this.props.webcamIdSelected
      );

      // TODO Executer seulement si le user est on ondes
      this.props.dispatch(
        setWebcamInputValue({
          webcamId: this.props.webcamIdSelected,
          value: "",
        })
      );
      this.props.dispatch(
        setSelectedWebcam({
          webcamId: this.props.webcamIdSelected,
          value: null,
        })
      );
    }
  }
  clearWebcamPlayerCall() {
    if (this.props.webcamIdSelected === null) {
      this.props.dispatch(setTutorialScenario(webcamIdNotSelected));
    } else {
      websocketServices.signals.clearCallParticipant(
        this.props.webcamIdSelected
      );
    }
  }
  addModerated() {
    var user;
    // user: {userid:'64732647', username:'Carmen Santiago', avatar:'base64 encoded image'}
    if (this.props.participantSelected === null) {
      this.props.dispatch(setTutorialScenario(participantNotSelected));
    } else {
      user = {
        userid: this.props.participantSelected.userid,
        username: this.props.participantSelected.username,
        avatar: this.props.participantSelected.avatar,
      };

      websocketServices.signals.addUserToModerated(user);
      this.props.dispatch(
        setUserInputValue({ type: PARTICIPANTS_INPUT_VALUE, value: "" })
      );
      var payload = { userType: PARTICIPANTS, value: null };
      this.props.dispatch(setSelectedUser(payload));
    }
  }
  removeModerated() {
    if (this.props.moderatedSelected === null) {
      this.props.dispatch(setTutorialScenario(moderatedNotSelected));
    } else {
      websocketServices.signals.removeUserFromModerated(
        this.props.moderatedSelected.userid
      );
      this.props.dispatch(
        setUserInputValue({ type: MODERATED_INPUT_VALUE, value: "" })
      );
      var payload = { userType: MODERATED, value: null };
      this.props.dispatch(setSelectedUser(payload));
    }
  }
  clearModerated() {
    websocketServices.signals.clearModerated();
  }
  render() {
    var label = "Call Off Air";
    var onAirColor = "primary";
    if (this.state.isOnAir) {
      label = "Call On Air";
      onAirColor = "secondary";
    }

    return (
      <Paper
        style={{
          transition: "all .5s ease-in-out",
          // backgroundColor: "#000000",
          // BorderAllRounded: true
          margin: 5,
          padding: 5,
        }}
      >
        <StyledTypography align='left' variant={"h6"} color={"reflexGreen"}>
          Call Commands
        </StyledTypography>
        <Box
          marginLeft={"auto"}
          marginRight={"auto"}
          style={{ display: "flex" }}
        >
          <Grid id="commandsContainer" justify="space-between" container spacing={2}>
            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addToCall} color="primary">
                add to Call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeFromCall} color="primary">
                remove from Call
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.addModerated} color="primary">
                add Moderated
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.removeModerated} color="primary">
                remove Moderated
              </CommandsButton>
            </Grid>

            <Grid item xs={"auto"}>
              <CommandsButton onClick={this.clearModerated} color="primary">
                clear Moderated
              </CommandsButton>
            </Grid>
            <Grid item xs={"auto"}>
              <WebcamSelectComponent />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  onAir: state.websocket.call.onAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  notConnectedSelected: state.ui.callModeratorComponent.notConnectedSelected,
  participantSelected: state.ui.callModeratorComponent.participantSelected,
  callModeratorSelected: state.ui.callModeratorComponent.callModeratorSelected,
  streamersSelected: state.ui.callModeratorComponent.streamersSelected,
  webcamPlayerSelected: state.ui.callModeratorComponent.webcamPlayerSelected,
  moderatedSelected: state.ui.callModeratorComponent.moderatedSelected,
  contributorSelected: state.ui.callModeratorComponent.contributorSelected,
  webcamSelected: state.ui.callModeratorComponent.webcamSelected,
  webcamIdSelected: state.ui.callModeratorComponent.webcamIdSelected,
});

export default withTranslation()(
  connect(mapStateToProps)(CallCommandComponent)
);
