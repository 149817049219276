// TODO: propose refactor
import {
  Button,
  Card,
  IconButton,
  List,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { IntercomUserComponent } from "./IntercomUserComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5px",
    marginTop: "5px",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
  },
  rootActive: {
    marginTop: "5px",
    border: "solid",
    borderWidth: "1px",
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    borderColor: theme.palette.reflexBlue.main,
  },

  activateButton: {
    color: (props) =>
      props.intercom && props.intercom.active
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
  title: {
    color: theme.palette.reflexYellow.main,
    fontSize: "0.8em",
  },
  listen: {
    marginLeft: "5px",
  },
  cardContent: {
    padding: "2px",
  },
  cardListContent: {
    padding: "2px",
    height: "100%",
  },
  powerButton: {
    marginLeft: "-15px",
  },
  pttButtonActive: {
    color: theme.palette.primary.main,
    fontSize: "0.6vw",
  },
  pttButton: {
    color: theme.palette.secondary.main,
    fontSize: "0.6vw",
  },
  userList: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById(props.name + "title")?.clientHeight) -
      parseInt(document.getElementById(props.name + "content")?.clientHeight) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      20,
  },
}));

/**
 * props:
 *  name
 *  localuser
 *
 */
export default function IntercomPanelComponent(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const localUserIsInIntercom = !!Object.keys(props.intercom).find(
    (key) => key === props.localUser.getUserId()
  );
  const blacklist = ["active", "ptt", props.localUser.getUserId()];
  const participants = Object.entries(props.intercom || {})
    // we want to make sure the property is not blacklisted
    .filter(([key, _value]) => blacklist.indexOf(key) === -1)
    .map(([_key, value]) => value);

  const addToWhisper = (userId) => {
    console.log(`start whispering to ${userId} in [${props.name} active]`);
    const localUserId = props.localUser.getUserId();
    props.handleAddToWhisper(localUserId, userId);
  };

  const removeFromWhisper = (userId) => {
    console.log(`stop whispering to ${userId} in [${props.name} active]`);
    const localUserId = props.localUser.getUserId();
    props.handleRemoveFromWhisper(localUserId, userId);
  };

  const handleToggleWhisper = (userId) => {
    let userIsInIntercom = [];
    if (props.intercom[props.localUser.getUserId()].whispering !== undefined) {
      userIsInIntercom = props.intercom[
        props.localUser.getUserId()
      ].whispering.filter((id) => id === userId);
      if (userIsInIntercom.length === 1) {
        removeFromWhisper(userId);
      } else if (userIsInIntercom.length === 0) {
        addToWhisper(userId);
      }
    }
  };

  const handleToggleActive = () => {
    console.log(`toggling [${props.name} active]`);
    props.handleToggleActive(!!props.intercom.active);
  };

  const handleTogglePTT = () => {
    console.log(`toggling [${props.name} ptt]`);
    if (!props.intercom.ptt && !props.intercom.active) {
      handleToggleActive();
    }
    if (!props.intercom.ptt && !localUserIsInIntercom) {
      handleCheckListenToIntercom();
    }

    props.handleTogglePTT(!!props.intercom.ptt);
  };

  const handleCheckListenToIntercom = () => {
    if (localUserIsInIntercom) {
      props.removeUser(props.localUser.getUserId());
    } else {
      let user = {
        userid: props.localUser.getUserId(),
        username: props.localUser.getNickname(),
        whispering: [],
        userType: "CALL_MODERATOR",
      };
      props.addUser(user);
    }
  };
  return (
    <Card
      className={
        props.intercom.ptt || false ? classes.rootActive : classes.root
      }
    >
      <CardContent id={props.name + "title"} className={classes.cardContent}>
        <Typography className={classes.title}>
          <IconButton className={classes.powerButton}>
            <PowerSettingsNewIcon
              className={classes.activateButton}
              onClick={handleToggleActive}
            />
          </IconButton>
          {t(`IntercomComponent|${props.name}`)}
        </Typography>
      </CardContent>
      <CardContent id={props.name + "content"} className={classes.cardContent}>
        <Tooltip title={t("IntercomComponent|pttAllButtonTooltip")} arrow>
          <Button
            className={
              props.intercom.ptt || false
                ? classes.pttButton
                : classes.pttButtonActive
            }
            onClick={handleTogglePTT}
          >
            {t("IntercomComponent|pttAllButton")}
          </Button>
        </Tooltip>
        <FormControlLabel
          className={classes.listen}
          control={
            <Checkbox
              checked={localUserIsInIntercom}
              onClick={handleCheckListenToIntercom}
              disabled={!props.intercom.active}
            />
          }
          label={<Typography>{t("IntercomComponent|listen")}</Typography>}
        />
      </CardContent>

      {/* <CardContent className={classes.cardListContent}> */}
      <List dense={true} className={classes.userList}>
        {participants
          .sort((a, b) => a.username.localeCompare(b.username))
          .map((participant) => (
            <IntercomUserComponent
              participant={participant}
              participants={participants}
              handleToggleWhisper={handleToggleWhisper}
              disabled={!localUserIsInIntercom || !props.intercom.active}
              localUser={props.localUser}
              intercom={props.intercom}
              removeUser={props.removeUser}
            />
          ))}
      </List>
      {/* </CardContent> */}

      {/* source: https://v4.mui.com/components/lists/#interactive */}
    </Card>
  );
}
