import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Toolbar,
  IconButton,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  ButtonGroup,
  Button,
  Typography,
} from "@material-ui/core";
import { Camera, Close, Person } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  DevicesSettingsManager,
  LocalStorageManager,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import useDevices from "./useDevices";
import { useDispatch, useSelector } from "react-redux";
import { startIntercom } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/intercom/startIntercom";
import playerSpinner from "../../assets/images/player-spinner.gif";
import playerSpinnerTransparent from "../../assets/images/loader-tranparent.gif";
import espace from "../../assets/images/tensorflow/icons/espace.png";
import egyptIcon from "../../assets/images/tensorflow/icons/egypt.png";
import tailandIcon from "../../assets/images/tensorflow/icons/tailand.png";
import blurIcon from "../../assets/images/tensorflow/icons/blur.png";
import normalIcon from "../../assets/images/tensorflow/icons/normal.png";
import { useBodyPix } from "../../utils/tensorflow/useBodyPix";
import { setFilter } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { filters } from "../../utils/tensorflow/filters";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vh",
    backgroundColor: theme.palette.grey1.main,
    border: "0px",
    padding: "0px",
    boxShadow: 24,
    borderRadius: 20,
    p: 4,
  },
  boxStyle: {
    padding: "20px",
  },
  devicesSettingsToolbar: {
    backgroundColor: theme.palette.darkbackground.main,
    color: theme.palette.primary.main,
    height: "40px",
    minHeight: "40px",
    margin: "0 0 0 0",
    padding: "0",
    borderRadius: "20px 20px 0px 0px",
  },
  closeButton: {
    position: "absolute",
    right: "5px",
  },
  select: {
    minWidth: "40vh",
    color: theme.palette.primary,
    fontSize: "1.5vh",
  },
  selectLabel: {
    paddingTop: "20Px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  devicesSettingsButtons: {
    borderRadius: "20px 20px 20px 20px",
    backgroundColor: theme.palette.grey5.main,
  },
  avatar: {
    width: "12vh",
    height: "12vh",
    backgroundColor: theme.palette.grey5.main,
    borderRadius: "20px 20px 20px 20px",
  },
  buttonGroup: {
    paddingTop: "10px",
  },
  videoContainer: {
    background: "url('" + playerSpinner + "') #2b3345 no-repeat center center",
  },

  normalBgButton: {
    background:
      "url('" + normalIcon + "') rgba(0,0,0,0) no-repeat center center",
    backgroundSize: "contain",
    color: "rgba(0,0,0,0)",
    fontSize: "3vh",
    borderRadius: 0,
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: 25,
    paddingBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginBotton: 10,
  },
  blurBgButton: {
    background: "url('" + blurIcon + "') rgba(0,0,0,0) no-repeat center center",
    backgroundSize: "contain",
    color: "rgba(0,0,0,0)",
    fontSize: "3vh",
    borderRadius: 0,
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: 25,
    paddingBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginBotton: 10,
  },
  image1BgButton: {
    background:
      "url('" + egyptIcon + "') rgba(0,0,0,0) no-repeat center center",
    backgroundSize: "contain",
    color: "rgba(0,0,0,0)",
    fontSize: "3vh",
    borderRadius: 0,
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: 25,
    paddingBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginBotton: 10,
  },
  image2BgButton: {
    background:
      "url('" + tailandIcon + "') rgba(0,0,0,0) no-repeat center center",
    backgroundSize: "contain",
    color: "rgba(0,0,0,0)",
    fontSize: "3vh",
    borderRadius: 0,
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: 25,
    paddingBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginBotton: 10,
  },
  image3BgButton: {
    background: "url('" + espace + "') rgba(0,0,0,0) no-repeat center center",
    backgroundSize: "contain",
    color: "rgba(0,0,0,0)",
    fontSize: "3vh",
    borderRadius: 0,
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0,0,0,0)",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: 25,
    paddingBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginBotton: 10,
  },
  
  buttonSelected: {
    borderColor: theme.palette.secondary.main,
    borderWidth: "1px",
  },
  filterButtonGroup: {
    textAlign: "center",
    position: "relative"
  },
  filterButtonGroupWaiting: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: "contain",
    background: "url('" + playerSpinnerTransparent + "') rgba(0,0,0,0.5) no-repeat center center",
  },
  filterButtonGroupWaitingHidden: {
    display: "none "
  },
  hiddenCanvas: {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },
  filteredCanvas: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
  },
  filteredCanvas4x3: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
  },
  labelSeleted : {
    color: theme.palette.secondary.main
  }
}));

export default function DevicesSettings(props) {
  const { t, i18next } = useTranslation();
  const classes = useStyles();
  const videoRef = useRef();
  const canvasRef = useRef();
  const bodypix = useBodyPix();
  
  const dispatch = useDispatch();
  const [testingAudio, setTestingAudio] = useState(false); // TODO: finish implementing this
  const [avatarBase64, setAvatarBase64] = useState(
    LocalStorageManager.getStorage(LocalStorageManager.STORAGE_KEYS.AVATAR_B64)
  );
  // const [videoReady, setVideoReady] = useState(false)
  // const [filterReady, setFilterReady] = useState(false)
  const [fields, updaters, devices, error] = useDevices({
    defaultResolution: "FHD",
  });
  // const camFilters = useSelector((state) => state.ui.selectedFilters)
  // const filter = camFilters[fields.selectedCamId] || null;
  const cameraConnected = useSelector(
    (state) => state.websocket.connection.cameraConnected
  );
  // const canvasIsVisible = filter !== null && bodypix !== null;
  const refreshUI = useSelector((state) => state.ui.refresh);
  const [oldVideo, setOldVideo] = useState(null);

  // useEffect(() => {
  //   const listenerId = videoRef.current.addEventListener("loadeddata", e => {
  //     if (!videoReady) {
  //       setVideoReady(true)
  //     } 
  //   })
  // }, [])

  useEffect(() => {
    if (videoRef.current !== null) setOldVideo(videoRef.current);
  }, [videoRef.current]);

  if (error) {
    console.error(error);
  }
  
  const saveSettings = () => {
    const KEYS = LocalStorageManager.STORAGE_KEYS;
    LocalStorageManager.setStorageBulk({
      [KEYS.MIC_DEVICE]: fields.selectedMicId,
      [KEYS.CAM_DEVICE]: fields.selectedCamId,
      [KEYS.CAM_CONSTRAINT]: fields.selectedResolution,
      [KEYS.AUDIO_DEVICES]: devices.audio,
      [KEYS.VIDEO_DEVICES]: devices.video,
      [KEYS.AVATAR_B64]: avatarBase64,
      // [KEYS.CAM_FILTER]: JSON.stringify({ ...camFilters, [fields.selectedCamId]: filter || "none" }), //filter? filter: "none",
    });
  };

  const close = () => {
    saveSettings();
    props.toggleDevicesSettings();
    websocketServices.devicesSettingsChanged({
      selectedMicId: fields.selectedMicId,
      selectedCamId: fields.selectedCamId,
      audio: devices.audio,
      video: devices.video,
      avatar: avatarBase64,
      resolution: fields.selectedResolution,
    });
  };

  const ready = () => {
    // TODO: implement me
    close();
    console.error("ready button is not implemented");
  };

  useEffect(() => {
    if (fields.selectedCamId !== null && fields.selectedMicId !== null) {
      if (cameraConnected) {
        DevicesSettingsManager.playWebcamPreviewConnected(videoRef.current);
      } else {
        DevicesSettingsManager.playWebcamPreview(
          videoRef.current,
          fields.selectedCamId,
          fields.selectedMicId,
          fields.selectedResolution
        );
      }
    }

    return () => {
      if (!cameraConnected) {
        let video = oldVideo;
        if (video === null) video = videoRef.current;
        DevicesSettingsManager.stopWebcamPreview(video);
      }
      // setVideoReady(false)
    };
  }, [fields.selectedCamId, fields.selectedMicId, fields.selectedResolution]);

  useEffect(() => {
    return () => {
      if (props.connectWebcam) {
        websocketServices.connectWebcam(props.connectWebcamOnAir);
      }
      if (props.startIntercom) {
        startIntercom();
      }
    };
  }, []);

  // refresh when redux receives a new streamPlaying event
  useEffect(() => {
    return () => {
      if (cameraConnected) {
        DevicesSettingsManager.playWebcamPreviewConnected(videoRef.current);
      }
    };
  }, [refreshUI]);

  // useEffect(() => {
  //   canvasRef.current.width = videoRef.current.width = videoRef.current.videoWidth;
  //   canvasRef.current.height = videoRef.current.height =
  //     videoRef.current.videoHeight;

  //   let continueToNextFrame = true;
  //   async function renderLoop() {
  //     if (canvasIsVisible) {
  //       filters[filter](
  //         canvasRef.current, 
  //         videoRef.current, 
  //         bodypix, 
  //         () => {
  //           if (!filterReady) {
  //             setFilterReady(true)
  //           }
  //         },
  //         fields.selectedResolution,
  //       )
  //     }
  //     if (continueToNextFrame && filterReady) {
  //       requestAnimationFrame(renderLoop);  
  //     }
  //   }
  //   if (filter && videoReady && !cameraConnected) {
  //     requestAnimationFrame(renderLoop);
  //   }
  //   return () => {
  //     continueToNextFrame = false;
  //   };
  // }, [filter, bodypix, videoReady, filterReady, cameraConnected]);

  const handleCamChange = (e) => {
    // setVideoReady(false)
    const camId = e.target.value;
    updaters.updateCamId(camId);
    props.localUser.setCamId(camId);
  };

  const handleMicChange = (e) => {
    // setVideoReady(false)
    const micId = e.target.value;
    updaters.updateMicId(micId);
    props.localUser.setMicId(micId);
  };

  const handleResolution = (res) => {
    // setVideoReady(false)
    updaters.updateResolution(res);
    props.localUser.setResolution(res);
  };

  const handleTakePicture = () => {
    const avatar = DevicesSettingsManager.captureImage({
      video: videoRef.current,
    });
    setAvatarBase64(avatar);
  };

  // const handleSetFilter = (filterName) => {
  //   setFilterReady(false)
  //   console.warn("################################### filterName: ", filterName)
  //   dispatch(setFilter({...camFilters, [fields.selectedCamId]: filterName}));
  // };

  let aspectRatio = "4:3";

  switch (fields.selectedResolution) {
    case "FHD":
    case "HD":
    case "VGA16x9": 
      aspectRatio = "16:9";
      break;
    case "VGA":
    case "QVGA":
      aspectRatio = "4:3";
      break;
  }

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.devicesSettingsToolbar}>
        <IconButton onClick={close} className={classes.closeButton}>
          <Close />
        </IconButton>
      </Toolbar>
      <Box className={classes.boxStyle}>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Box style={{ position: "relative", overflow: "hidden" }}>
              <video
                autoPlay={true}
                ref={videoRef}
                muted={!testingAudio}
                className={classes.videoContainer}
              />
              {/* <canvas
                ref={canvasRef}
                className={
                  !canvasIsVisible || cameraConnected
                    ? classes.hiddenCanvas
                    : aspectRatio === "16:9"
                    ? classes.filteredCanvas
                    : classes.filteredCanvas4x3
                }
              /> */}
            </Box>
            <Box className={classes.buttonContainer}>
              <IconButton
                className={classes.devicesSettingsButtons}
                onClick={handleTakePicture}
              >
                <Camera />
                {t("DevicesSettings|Capture Avatar")}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.buttonContainer}>
              {[null, "", undefined].every((it) => it !== avatarBase64) ? (
                <Box
                  component="img"
                  className={classes.avatar}
                  src={avatarBase64}
                />
              ) : (
                <Person className={classes.avatar} />
              )}
            </Box>
            <InputLabel className={classes.selectLabel} id="cam-selected">
              {t("DevicesSettings|Select your camera")}
            </InputLabel>
            <Select
              className={classes.select}
              labelId="cam-selected"
              value={fields.selectedCamId}
              onChange={handleCamChange}
            >
              {devices.video.map((device, index) => (
                <MenuItem value={device.id} key={device.id}>
                  {device.name !== "" ? device.name : `Camera #${index + 1}`}
                </MenuItem>
              ))}
            </Select>
            <InputLabel className={classes.selectLabel} id="mic-selected">
              {t("DevicesSettings|Select your microphone")}
            </InputLabel>
            <Select
              className={classes.select}
              labelId="mic-selected"
              value={fields.selectedMicId}
              onChange={handleMicChange}
            >
              {devices.audio.map((device, index) => (
                <MenuItem value={device.id} key={device.id}>
                  {device.name !== ""
                    ? device.name
                    : `Microphone #${index + 1}`}
                </MenuItem>
              ))}
            </Select>
            <Box className={classes.buttonContainer}>
              <ButtonGroup
                className={classes.buttonGroup}
                variant="outlined"
                aria-label="outlined button group"
              >
                {["QVGA", "VGA", "VGA16x9", "HD", "FHD"].map((res) => (
                  <Button
                    name={res}
                    value={res}
                    onClick={() => handleResolution(res)}
                    color={
                      res === fields.selectedResolution
                        ? "secondary"
                        : "primary"
                    }
                  >
                    {res}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          </Grid>
          {/* {!props.localUser.isStreamer() && (
            <Grid item xs={12}>
              <Box className={classes.filterButtonGroup}>
                <Typography>{t("DevicesSettings|changeBackground")}</Typography>
                <ButtonGroup>
                  <Box>
                    <Button
                      onClick={() => handleSetFilter(null)}
                      className={filter === null? `${classes.normalBgButton} ${classes.buttonSelected}`:classes.normalBgButton}
                    >
                      00
                    </Button>
                    <Typography className={filter === null?`${classes.labelSeleted}` : ""}>{t("DevicesSettings|normal")}</Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => handleSetFilter("blur")}
                      className={filter === "blur"? `${classes.blurBgButton} ${classes.buttonSelected}`:classes.blurBgButton}
                    >
                      00
                    </Button>
                    <Typography className={filter === "blur"?`${classes.labelSeleted}` : ""}>{t("DevicesSettings|blur")}</Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => handleSetFilter("img:egypt")}
                      className={filter === "img:egypt"? `${classes.image1BgButton} ${classes.buttonSelected}`:classes.image1BgButton}
                    >
                      00
                    </Button>
                    <Typography className={filter === "img:egypt"?`${classes.labelSeleted}` : ""}>{t("DevicesSettings|egypt")}</Typography>
                  </Box>

                  <Box>
                    <Button
                      onClick={() => handleSetFilter("img:tailand")}
                      className={filter === "img:tailand"? `${classes.image2BgButton} ${classes.buttonSelected}`:classes.image2BgButton}
                    >
                      00
                    </Button>
                    <Typography className={filter === "img:tailand"?`${classes.labelSeleted}` : ""}>{t("DevicesSettings|plage")}</Typography>
                  </Box>

                  <Box>
                    <Button
                      onClick={() => handleSetFilter("img:espace")}
                      className={filter === "img:espace"? `${classes.image3BgButton} ${classes.buttonSelected}`:classes.image3BgButton}
                    >
                      00
                    </Button>
                    <Typography className={filter === "img:espace"?`${classes.labelSeleted}` : ""}>{t("DevicesSettings|espace")}</Typography>
                  </Box>
                </ButtonGroup>
                <Box className={ (filterReady || (!filterReady && filter === null)) || cameraConnected? `${classes.filterButtonGroupWaiting} ${classes.filterButtonGroupWaitingHidden}`: classes.filterButtonGroupWaiting}></Box>
              </Box>
            </Grid>
          )} */}

          <Grid item xs={12}>
            <Box className={classes.buttonContainer}>
              <IconButton
                className={classes.devicesSettingsButtons}
                onClick={ready}
              >
                {t("DevicesSettings|Ready!")}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
