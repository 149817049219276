import {
    PARTICIPANTS as reflexConsts_PARTICIPANTS,
    NOT_CONNECTED as reflexConsts_NOT_CONNECTED,
    CALL_MODERATORS as reflexConsts_CALL_MODERATORS,
    MODERATED as reflexConsts_MODERATED,
    CONTRIBUTORS as reflexConsts_CONTRIBUTORS,
    PARTICIPANTS_INPUT_VALUE as reflexConsts_PARTICIPANTS_INPUT_VALUE,
    NOT_CONNECTED_INPUT_VALUE as reflexConsts_NOT_CONNECTED_INPUT_VALUE,
    CONTRIBUTORS_INPUT_VALUE as reflexConsts_CONTRIBUTORS_INPUT_VALUE,
    MODERATED_INPUT_VALUE as reflexConsts_MODERATED_INPUT_VALUE,
    CALL_MODERATORS_INPUT_VALUE as reflexConsts_CALL_MODERATORS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";


export const selectedListTypes = {
    NOT_CONNECTED: "notConnected",
    PARTICIPANTS: "participants",
    MODERATED: "moderated",
    CONTRIBUTORS: "contributors",
    CALL_MODERATORS: "callModerators",
}

export const translateToUserType = (selectedList) => (
    selectedList === selectedListTypes.PARTICIPANTS
    ? reflexConsts_PARTICIPANTS
    : selectedList === selectedListTypes.CONTRIBUTORS
    ? reflexConsts_CONTRIBUTORS
    : selectedList === selectedListTypes.CALL_MODERATORS
    ? reflexConsts_CALL_MODERATORS
    : selectedList === selectedListTypes.MODERATED
    ? reflexConsts_MODERATED
    : reflexConsts_NOT_CONNECTED
)

export const translateToInputValueType = (selectedList) => (
    selectedList === selectedListTypes.PARTICIPANTS
    ? reflexConsts_PARTICIPANTS_INPUT_VALUE
    : selectedList === selectedListTypes.CONTRIBUTORS
    ? reflexConsts_CONTRIBUTORS_INPUT_VALUE
    : selectedList === selectedListTypes.CALL_MODERATORS
    ? reflexConsts_CALL_MODERATORS_INPUT_VALUE
    : selectedList === selectedListTypes.MODERATED
    ? reflexConsts_MODERATED_INPUT_VALUE
    : reflexConsts_NOT_CONNECTED_INPUT_VALUE
)

export const selectInputValue = (state) => {
    const {
      participantsInputValue,
      moderatedInputValue,
      notConnectedInputValue,
      contributorsInputValue,
      callModeratorsInputValue,
    } = state.ui.callModeratorComponent;
  
    return (
      participantsInputValue ||
      moderatedInputValue ||
      notConnectedInputValue ||
      contributorsInputValue ||
      callModeratorsInputValue
    );
};

export const selectSelectedUser = state => {
    const {
        participantSelected,
        moderatedSelected,
        notConnectedSelected,
        contributorSelected,
        callModeratorSelected,
    } = state.ui.callModeratorComponent

    return (
        participantSelected ||
        moderatedSelected ||
        notConnectedSelected ||
        contributorSelected ||
        callModeratorSelected ||
        null
    )
}

  
export const selectList = (state) => state.ui.callModeratorComponent.selectedList;