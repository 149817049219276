import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import i18nInstance from "../../../../i18n";
import { Autocomplete } from "@material-ui/lab/";
import { TextField, Box, Grid, Typography, Chip, Avatar, Popper, Badge } from "@material-ui/core/";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import {
  setSelectedWebcam,
  setWebcamInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import { UserPopper } from "../users/UserPopper";

class WebcamSlotComponent extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      webcamSlot: [],
      dimension: this.browserUtil.getWindowDimensions(),
      webcamSelected: null,
      webcamValue: "",
      webcamInputValue: "",
    };
    this.handleResize = this.handleResize.bind(this);
    this.setSelectedWebcam = this.setSelectedWebcam.bind(this);
    this.setWebcamInputValue = this.setWebcamInputValue.bind(this);
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  setSelectedWebcam(webcamId, value) {
    var payload = { webcamId: webcamId, value: value };
    this.props.dispatch(setSelectedWebcam(payload));
    // webcamSelected
    // this.props.dispatch(setWebcamInputValue({ webcamId: webcamId, value: null }));
    // this.setState({ webcamValue: null });
    // this.setState({ webcamValue: null });
  }
  setWebcamInputValue(webcamId, newInputValue) {
    var payload = { webcamId: webcamId, value: newInputValue };
    this.props.dispatch(setWebcamInputValue(payload));
  }
  handleDelete() {
  }
  render() {
    var title = "Webcam " + this.props.webcamId;
    if (this.props.webcamId === 100) {
      title = i18nInstance.t("WebcamSlotComponent|Screen Sharing");
    }

    var variant = "outlined";
    var isOnAirSelected = {
      webcamSelected: this.state.webcamSelected,
      webcamInputValue: this.state.webcamInputValue,
    };
    
    return (
      <Box
        id={"webcamSlotContainer" + this.props.webcamId}
        style={{
          transition: "all .5s ease-in-out",
        }}
      >
        {/* <Paper /> */}
        <Badge
                badgeContent={this.state.webcamSlot.length}
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ width: "100%" }}
              >
        <Autocomplete
          id={"webcamSlot" + this.props.webcamId}
          options={this.state.webcamSlot.map((option) => option)}
          getOptionLabel={(option) => option.username}
          onChange={(event, value) =>
            this.setSelectedWebcam(this.props.webcamId, value)
          }
          onInputChange={(event, newInputValue) => {
            this.setWebcamInputValue(this.props.webcamId, newInputValue);
          }}
          inputValue={isOnAirSelected.webcamInputValue}
          value={isOnAirSelected.webcamSelected}
          fullWidth
          freeSolo
          PopperComponent={UserPopper}
          renderOption={(option, { selected }) => (
            //TODO Creer le composant userTag polymorphique commun a tout les containers
            <Chip
              variant="outlined"
              key={option}
              style={{
                backgroundColor: `secondary`,
                color: "white",
              }}
              avatar={<Avatar alt={option.username} src={option.avatar} />}
              onDelete={this.handleDelete}
              label={option.username}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={title}
              margin="normal"
              variant={variant}
              error={this.state.isOnAir}
              color="secondary"
              size="small"

            />
          )}
        /></Badge>
      </Box>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  webcamSlot: state.websocket.call.onAir[thisProps.webcamId],
  webcamInputValue:
    state.ui.callModeratorComponent.webcamInputValue[thisProps.webcamId],
  webcamSelected:
    state.ui.callModeratorComponent.webcamSelected[thisProps.webcamId],
});

export default withTranslation()(connect(mapStateToProps)(WebcamSlotComponent));
