import {Paper, Typography, Button, ButtonGroup, makeStyles, Box} from "@material-ui/core"
import UserAvatar from "../UserAvatar"

const useStyles = makeStyles(( theme) => ({
    userItemMod : {
        cursor: "pointer",
        backgroundColor: theme.palette.grey4.main,
        paddingBottom: "5px"
      },
      userItem : {
        cursor: 'default',
        backgroundColor: theme.palette.grey4.main
      },
      userContainer: {
          display: "flex",
          flexDirection: "row",
          padding: "5px"
      },
      username: {
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: "0.75vw"
      },
      avatar: {
        width: "2vw",
        height: "2vw",
      },
      buttonsGroup : {
          marginBottom: "5px"
      }
}))

export function UserItem({
    user,
    isMod,
    onClick,
}) {
    const classes = useStyles()
    return (
        <Paper
            elevation={3}
            // variant="outlined"
            onClick={isMod? undefined: () => onClick(user)}
            className={isMod? classes.userItem: classes.userItemMod}
        >
            <Box className={classes.userContainer}>
            <UserAvatar
                src={user.avatar}
                className={classes.avatar}
            >
                {user.username.split(" ").map(str => str[0]).join("")}
            </UserAvatar>
            <Typography className={classes.username}>
                {user.username}
            </Typography>
            </Box>
            

            {
                isMod && ( // TODO: to be determined
                    <ButtonGroup className={classes.buttonsGroup}>
                        <Button>TODO</Button>
                    </ButtonGroup>
                )
            }
        </Paper>
    )
}