import {createTheme } from "@material-ui/core/styles";
// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
//Theming
// https://material-ui.com/customization/theming/

// const theme = React.useMemo(
//   () =>
//     createMuiTheme({
//       palette: {
//         type: prefersDarkMode ? 'dark' : 'light',
//         primary: {
//           main: '#f0f2f6'
//         },
//         secondary: {
//           main: '#ff2900'
//         },
//         error: {
//           main: '#ff2900'
//         },
//         warning: {
//           main: '#ffc25e'
//         },
//         info: {
//           main: '#3370c2'
//         },
//         success: {
//           main: '#3370c2'
//         },
//       }
//     }),
//   [prefersDarkMode],
// );
export const darkTheme = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#f0f2f6",
      },
      secondary: {
        main: "#ff2900",
      },
      error: {
        main: "#ff2900",
      },
      warning: {
        main: "#ffc25e",
      },
      info: {
        main: "#3370c2",
      },
      success: {
        main: "#12babf",
      },
      darkbackground: {
        main: "#212936",
        dark: "#212936",
      },
      lightbackground: {
        main: "grey",
      },
      reflexBlue: {
        main: "#3370c2",
      },
      reflexOrange: {
        main: "#ff6600",
      },
      reflexRed: {
        main: "#ff2900",
      },
      reflexGreen: {
        main: "#12babf",
      },
      reflexYellow: {
        main: "#ffc25e",
      },
      reflexIndigo: {
        main: "#2e144d",
      },
      reflexIndigoDark: {
        main: "#120a40",
      },
      commandButtonBackgroundColor: {
        main: '#30394a',
      },
      commandButtonBorderColor: {
        main: 'grey'
      },
      grey1: {
        main: '#4a5264'
      },
      grey2: {
        main: '#3f4759'
      },
      grey3: {
        main: '#353d4f'
      },
      grey4: {
        main: '#2b3345'
      },
      grey5: {
        main: '#272f41'
      },
      grey6: {
        main: '#222b3d'
      },
      grey7: {
        main: '#212936'
      },
      grey8: {
        main: '#d7d9d8'
      },
      callModeratorFooter: {
        fontSize: '8px'
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundColor: "#2b3345"
          },
        },
      },
      MuiButton: {
        root: {
          // fontFamily: 'notoBlack',
          backgroundColor: "#212936",
          borderRadius: "1.25em",
          fontSize: "0.80em",
        },
        text: {
          paddingLeft: "15px",
          paddingRight: "15px",
        },
      },
      Toolbar: {
        root: {
          alignItems: "center",
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: "#30394a",
        },
      }
      
    },
    typography: {
      fontFamily: `"notoLight","Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });