import React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import VideoRoomComponent from "../videoRoom/VideoRoomComponent";
import { Box, Grid, Typography, Modal } from "@material-ui/core/";
import Collapse from "@material-ui/core/Collapse";
import "./webRTCStream.css";
import ToolbarComponent from "../toolbar/ToolbarComponent";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import {
  callDidUpdate,
  DevicesSettingsManager,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import DevicesSettings from "../commons/DevicesSettings";
import PulseLoader from "react-spinners/PulseLoader";
import { localUserErrorNotification } from "../../utils/participantUtils";
class WebRTCStreamer extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();

    this.state = {
      dimension: this.browserUtil.getWindowDimensions(),
      messageReceived: false,
      publishingReady: false,
      subscribingReady: false,
      localUser: undefined,
      userOnAir: false,
      devicesSettingsOpened: false,
      devices: null,
      connectWebcam: false,
      connectWebcamOnAir: false,
      devicesSettingsIsOpen: "none",
      deviceSettingsIsOnAir: false,
      cameraConnected: false,
      initCompleted: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.checkNotification = this.checkNotification.bind(this);
    this.isAudioActive = this.isAudioActive.bind(this);
    this.isVideoActive = this.isVideoActive.bind(this);
    this.handleClosedDevicesSettings =
      this.handleClosedDevicesSettings.bind(this);
    this.toggleDevicesSettings = this.toggleDevicesSettings.bind(this);
    this.reduxToggleDevicesSettings = this.reduxOpenDevicesSettings.bind(this);
  }

  componentDidUpdate(prevProps) {
    var state = callDidUpdate(prevProps, this.props);
    if (
      prevProps.localUserReady !== this.props.localUserReady &&
      this.props.localUserReady === true
    ) {
      state.localUser = websocketServices.localUser;
      state.subscribers = websocketServices.subscribers;
    }
    if (prevProps.devicesSettingsIsOpen !== this.props.devicesSettingsIsOpen) {
      this.reduxOpenDevicesSettings(this.props.deviceSettingsIsOnAir);
    }
    if (prevProps.error !== this.props.error) {
      localUserErrorNotification(this.props.error)
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }

  isAudioActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isAudioActive &&
      this.state.localUser.isAudioActive()
    );
  }

  isVideoActive() {
    return (
      this.state.localUser !== undefined &&
      this.state.localUser.isVideoActive &&
      this.state.localUser.isVideoActive()
    );
  }

  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  checkNotification(event) {
    var toDisplay = false;
    if (this.props.chatDisplay === "none") {
      toDisplay = true;
    }
    this.setState({
      messageReceived: toDisplay,
    });
  }
  toggleDevicesSettings() {
    if (!this.state.devicesSettingsOpened) {
      DevicesSettingsManager.getDevices().then((_devices) => {
        this.setState({
          devices: _devices,
          devicesSettingsOpened: true,
          connectWebcam: false,
          connectWebcamOnAir: false,
        });
      });
    } else {
      this.setState({
        devices: null,
        devicesSettingsOpened: false,
        connectWebcam: false,
        connectWebcamOnAir: false,
      });
    }
  }
  reduxOpenDevicesSettings(onAir) {
    DevicesSettingsManager.getDevices().then((_devices) => {
      this.setState({
        devices: _devices,
        devicesSettingsOpened: true,
        connectWebcam: true,
        connectWebcamOnAir: onAir,
      });
    });
  }
  handleClosedDevicesSettings() {}
  render() {
    const localUser = this.state.localUser;
    var ratio = 1;

    return (
      <Box
        className="reflexPlayer"
        id="container"
        style={{ display: "flex", marginTop: "40px" }}
      >
        <Modal
          open={this.state.devicesSettingsOpened}
          onClose={this.handleClosedDevicesSettings}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
        >
          <DevicesSettings
            devices={this.state.devices}
            toggleDevicesSettings={this.toggleDevicesSettings}
            localUser={this.state.localUser}
            connectWebcam={this.state.connectWebcam}
            connectWebcamOnAir={this.state.connectWebcamOnAir}
          />
        </Modal>
        <ToolbarComponent
          sessionId={websocketServices.callSessionId}
          user={localUser}
          showNotification={this.state.messageReceived}
          camStatusChanged={websocketServices.camStatusChanged}
          micStatusChanged={websocketServices.micStatusChanged}
          screenShare={websocketServices.screenShare}
          isaudioactive={"" + this.isAudioActive()}
          isvideoactive={"" + this.isVideoActive()}
          stopScreenShare={websocketServices.stopScreenShare}
          leaveSession={websocketServices.leaveSession}
          toggleDevicesSettings={this.toggleDevicesSettings}
        />
        <Grid
          id="reflexPlayerContainer"
          direction="column"
          justifyContent="center"
          container
          spacing={0}
        >
          <Grid item xs={12}>
            <Collapse orientation="horizontal" in={true}>
              <Box>
                <VideoRoomComponent
                  ratio={ratio}
                  checkNotification={this.checkNotification}
                  streamer={true}
                />
              </Box>
            </Collapse>
          </Grid>
        </Grid>
        <Box
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: !this.state.initCompleted ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000000",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderWidth: "0px",
            }}
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Trans>global|waitingInit</Trans>
            </Typography>
            <PulseLoader
              color="#ff2900"
              loading="true"
              style={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
                borderWidth: "0px",
              }}
              size={35}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  initCompleted: state.ui.initCompleted,
  chatDisplay: state.ui.chat.chatDisplay,
  isOnAir: state.websocket.call.isOnAir,
  onAir: state.websocket.call.onAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  publishingReady: state.websocket.connection.events.publishingReady,
  subscribingReady: state.websocket.connection.events.subscribingReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  localUserRefresh: state.websocket.callEvents.localUserRefresh,
  devicesSettingsIsOpen: state.ui.devicesSettings.isOpenEvent,
  deviceSettingsIsOnAir: state.ui.devicesSettings.onAir,
  cameraConnected: state.websocket.connection.cameraConnected,
  error: state.websocket.connection.events.error,
});

export default withTranslation()(connect(mapStateToProps)(WebRTCStreamer));
