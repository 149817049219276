import React from 'react';
// import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import { validateReflexToken } from '@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI'
import logo from './logo.svg';


class Login extends React.Component {
  state = {
    redirectToReferrer: false,
    redirect: false
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.from !== undefined) {
      // do the token validation
      
      const reflexToken = await validateReflexToken(this.props)
      if (reflexToken !== undefined && reflexToken.valid === true) {
        this.setState({
          redirectToReferrer: true
        })
      } else {
        this.setState({
          redirect: true
        })
      }
    } else {
      this.setState({
        redirect: true
      })
    }
  }

  render() {
    // console.warn("###################################### path: ", this.props.location.state.from.pathname)
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    const override = css`
                display: block;
                margin: 0 auto;
                border-color: red;
              `;
    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    if (this.state.redirect) {
      return <Redirect
        to="/notauthorized"
      />;
    }

    return (
      <div>
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <h3><Trans>global|waiting login</Trans></h3>
          
          <PulseLoader color="#ff2900" loading="true" css={override} size={35} />
          {/* <h1>404 - <Trans>global|Not Found!</Trans></h1> */}

          {/* <a
            className="App-link"
            href="/"
          >
            <Trans>global|Go to Reflex WebRTC Home</Trans> 
          </a> */}
        </header>
      </div>
    )
  }
}

export default withTranslation()(Login);