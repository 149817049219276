import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';
import logo from './logo.svg';
import FooterAppBar from './FooterAppBar';

const NotAuthorized = () => (
  <div>
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>403 - <Trans>global|Not Authorized!</Trans></h1>
        <a
          className="App-link"
          href="/"
        >
          <Trans>global|Go to Reflex WebRTC Home</Trans>
        </a>
        <FooterAppBar />
      </header> 
  </div>
);

export default withTranslation() (NotAuthorized);