import React from 'react';
import i18nInstance from "./i18n";
import { BrowserRouter as Router, Switch, Route,  Redirect } from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import ReflexPlayer from './components/reflexPlayer/ReflexPlayer'
import NotFound from './NotFound';
import NotAuthorized from './NotAuthorized';
import Homepage from './Homepage';
import Login from './Login'
import { isAuth} from '@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI'
import { Box } from "@material-ui/core/";
import './App.css';
import WebRTCPlayerComponent from './components/webRTCPlayer/WebRTCPlayerComponent';
import CallModeratorComponent from './components/callModerator/CallModeratorComponent';
import DialogManager from './components/dialog-manager/DialogManager';
import {LocalStorageManager} from '@reflex-interactions/library-reflex-client/dist/reflexAPI/storage/LocalStorageManager'
import LoginWebcamContributor from './LoginWebcamContributor';
import WebRTCStreamer from './components/webRTCStreamer/WebRTCStreamer';
import AlreadyLogin from './AlreadyLogin';

class App extends React.Component {

  render() {
    const lang = LocalStorageManager.getStorage(LocalStorageManager.STORAGE_KEYS.LANGUAGE)
    if (lang !== null && lang !== undefined) {
      i18nInstance.changeLanguage(lang)
    } else {
      i18nInstance.changeLanguage()
      const _lang = i18nInstance.t('locale')
      LocalStorageManager.setStorage(LocalStorageManager.STORAGE_KEYS.LANGUAGE, _lang)
    }
    return ( 
          <Router>   
            <Switch>
              <Route exact path="/notauthorized" >             
                <Box className="App">
                  <NotAuthorized/>
                </Box>
              </Route>
              <Route exact path="/" >            
                <Box className="App">
                  <Homepage/>
                </Box>
              </Route>
              <Route exact path="/loginWebcam" >            
                <Box className="App">
                  <LoginWebcamContributor/>
                </Box>
              </Route>
              <Route exact path="/alreadyLogin" >            
                <Box className="App">
                  <AlreadyLogin/>
                </Box>
              </Route>
              <Route path="/login" render={(props) => <div className="App"><Login {...props}/></div>} >            
              </Route>
              <PrivateCallRoute exact path="/player/:reflextoken" component={PlayerApp} />
              <PrivateCallRoute exact path="/callModerator/:reflextoken" component={CallModeratorApp} />
              <PrivateCallRoute exact path="/webRTCPlayer/:reflextoken" component={WebRTCPlayerApp} />
              <PrivateCallRoute exact path="/webRTCStreamer/:reflextoken" component={WebRTCStreamerApp} />
              <PrivateCallTestRoute exact path="/callTest/" component={PlayerApp} />
              <Route >
              <Box className="App">
                <NotFound/>
                </Box>
              </Route>
            </Switch>    
          </Router>  
    );
  }
}

const PlayerApp = props => {
     return <Box className="App"><ReactNotification /><ReflexPlayer {...props}/></Box>
}

const CallModeratorApp = props => {
  return <Box className="App"><DialogManager/><ReactNotification /><CallModeratorComponent {...props}/></Box>
}

const WebRTCPlayerApp = props => {
  return <Box className="App"><ReactNotification /><WebRTCPlayerComponent {...props}/></Box>
}

const WebRTCStreamerApp = props => {
  return <Box className="App"><ReactNotification /><WebRTCStreamer {...props}/></Box>
}

const PrivateCallRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={ (props) => (
    isAuth() === true
      ? <Component {...props} {...rest}/>
      : <Redirect {...props} to={{
          pathname: '/login/'+ props.location.search, 
          state: { from: props.location },
          props: props
        }} />
  )} />
)

const PrivateCallTestRoute = ({ component: Component, ...rest }) => (
   //TODO Gestion de l'access a la page de test.
  <Route {...rest} render={ (props) => (
    true === true
      ? <Component {...props} {...rest}/>
      : <Redirect {...props}  to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

export default App;
