import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { Badge, Box } from "@material-ui/core";
import { getUserDetails } from "../../../../utils/callControllerUtils";
import {
  ClearCallButton,
  WebcamDownButton,
} from "../../../buttons/commonButtons";
import UserOnAirChip from "./UserOnAirChip";

class WebcamInputCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      intercomSlot: [],
      webcamSlot: [],
      webcamSelected: null,
      webcamValue: "",
      webcamInputValue: "",
    };
    this.clearWebcamInput = this.clearWebcamInput.bind(this);
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  clearWebcamInput() {
    this.props.websocketServices.signals.clearCallParticipant(
      this.props.webcamId
    );
  }
  componentDidMount() {}
  nextWebcam() {}
  render() {
    var visible =
      this.state.webcamSelected !== null && this.state.webcamInputValue !== "";
    var user = {
      username: "",
      avatar: "",
      userid: undefined,
      details: {},
      isOnAir: false,
    };
    if (this.state.webcamSlot.length > 0) {
      user = getUserDetails(this.state.participants, this.state.webcamSlot[0]);
    }

    return (
      <Box>
        <UserOnAirChip
          user={user}
          webcamId={this.props.webcamId}
          websocketServices={this.props.websocketServices}
        />
      </Box>
    );
  }
}
const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  intercomSlot: state.websocket.intercom[thisProps.intercomSlotId],
  webcamSlot: state.websocket.call.onAir[thisProps.webcamId],
  webcamInputValue:
    state.ui.callModeratorComponent.webcamInputValue[thisProps.webcamId],
  webcamSelected:
    state.ui.callModeratorComponent.webcamSelected[thisProps.webcamId],
});

export default withTranslation()(connect(mapStateToProps)(WebcamInputCard));
