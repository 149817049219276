import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { Box, Collapse } from "@material-ui/core/";
import "react-notifications-component/dist/theme.css";
import "./VideoRoomComponent.css";
import StreamComponent from "../stream/StreamComponent";
import DialogExtensionComponent from "../dialog-extension/DialogExtension";
import { displayChatRoom } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import ReflexPlayerLayout from "../../layout/reflex-player-layout";
import {
  localUser,
  reflexComponentDidUpdate,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import {
  reflexWsConnect,
  reflexWsDisconnect,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/middlewares/actions/reflexApiActions";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import webcam1Background from "../../assets/images/background_webcam1_small.png";
import webcam2Background from "../../assets/images/background_webcam2_small.png";
import webcam3Background from "../../assets/images/background_webcam3_small.png";
import webcam4Background from "../../assets/images/background_webcam4_small.png";
import webcam5Background from "../../assets/images/background_webcam5_small.png";
import webcam6Background from "../../assets/images/background_webcam6_small.png";
import webcam7Background from "../../assets/images/background_webcam7_small.png";
import webcam8Background from "../../assets/images/background_webcam8_small.png";
import webcam9Background from "../../assets/images/background_webcam9_small.png";
import webcam10Background from "../../assets/images/background_webcam10_small.png";
import screenShareBackground from "../../assets/images/background_screen_monitor_small.png";
import programBackground from "../../assets/images/background_program_small.png";
import playerSpinner from "../../assets/images/player-spinner.gif";

const styles = (theme) => ({
  webcam1Background: {
    background:
      "url('" +
      webcam1Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },

  webcam2Background: {
    background:
      "url('" +
      webcam2Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam3Background: {
    background:
      "url('" +
      webcam3Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam4Background: {
    background:
      "url('" +
      webcam4Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam5Background: {
    background:
      "url('" +
      webcam5Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam6Background: {
    background:
      "url('" +
      webcam6Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam7Background: {
    background:
      "url('" +
      webcam7Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam8Background: {
    background:
      "url('" +
      webcam8Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam9Background: {
    background:
      "url('" +
      webcam9Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  webcam10Background: {
    background:
      "url('" +
      webcam10Background +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  screenShareBackground: {
    background:
      "url('" +
      screenShareBackground +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  programBackground: {
    background:
      "url('" +
      programBackground +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  videoBounds: {
    height: "100%",
    position: "relative",
    background:
      "url('" +
      playerSpinner +
      "') " +
      theme.palette.grey4.main +
      " no-repeat center center",
    backgroundSize: "contain",
  },
});
class VideoRoomComponent extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    this.hasBeenUpdated = false;
    this.layout = new ReflexPlayerLayout();
    this.state = {
      myCallSessionId: "",
      myUserName: "",
      callSession: undefined,
      localUser: undefined,
      subscribers: [],
      dimension: this.browserUtil.getWindowDimensions(),
      cameraConnected: false,
      monitorWallActive: false,
      onAir: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        100: [],
      },
    };
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.closeDialogExtension = this.closeDialogExtension.bind(this);
    this.checkSize = this.checkSize.bind(this);
    this.getUserWebcamSlot = this.getUserWebcamSlot.bind(this);
  }

  componentDidMount() {
    this.setState({ localUser: localUser });
    let bigFixedRatio = false;
    let fixedRatio = false;
    if (this.props.streamer || this.props.callModerator) {
      bigFixedRatio = true;
      fixedRatio = true;
    }
   
    const reflexPlayerLayoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: fixedRatio, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      bigClass: "OV_big", // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
      bigFixedRatio: bigFixedRatio, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: false, // Whether you want to animate the transitions
    };
   
    this.layout.initLayoutContainer(
      document.getElementById("layout"),
      reflexPlayerLayoutOptions
    );
    window.addEventListener("beforeunload", this.onbeforeunload);
    window.addEventListener("resize", this.updateLayout);
    window.addEventListener("resize", this.checkSize);

    //********************************************************* */
    //Point de depart de la connection temps reel avec Reflex
    //
    //********************************************************* */
    this.props.dispatch(reflexWsConnect());
    //********************************************************** */
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
    window.removeEventListener("resize", this.updateLayout);
    window.removeEventListener("resize", this.checkSize);
  }

  doReflexComponentDidUpdate(result) {
    if (result.state) {
      this.setState(result.state);
    }
    if (result.layoutToUpdate) {
      this.updateLayout();
    }
  }

  componentDidUpdate(prevProps) {
    this.doReflexComponentDidUpdate(
      reflexComponentDidUpdate(this.props, prevProps)
    );

    if (prevProps.ratio !== this.props.ratio) {
      this.updateLayout();
    }
    if (prevProps.callError !== this.props.callError) {
    }
    if (prevProps.monitorWallActive !== this.props.monitorWallActive) {
      this.setState({ monitorWallActive: this.props.monitorWallActive });
    }
    if (prevProps.onAir !== this.props.onAir) {
      this.setState({ onAir: this.props.onAir });
    }
  }

  onbeforeunload(event) {
    this.props.dispatch(reflexWsDisconnect());
  }

  checkSize() {
    if (
      document.getElementById("layout").offsetWidth <= 700 &&
      !this.hasBeenUpdated
    ) {
      this.props.dispatch(displayChatRoom("none"));
      this.hasBeenUpdated = true;
    }
    if (
      document.getElementById("layout").offsetWidth > 700 &&
      this.hasBeenUpdated
    ) {
      this.hasBeenUpdated = false;
    }
  }

  updateLayout() {
    
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
    setTimeout(() => {
      this.layout.updateLayout();
    }, 20);
    // }
  }

  closeDialogExtension() {
    this.setState({ showExtensionDialog: false });
  }
 
  getUserWebcamSlot(user) {
    for (var x = 1; x < 11; x++) {
      if (user.userid === this.state.onAir[x][0].userid) {
        return x;
      }
    }
    if (user.isStreamer()) {
      return 12;
    } else {
      return 11;
    }
  }
  render() {
    const { classes, theme } = this.props;
    const myCallSessionId = this.state.myCallSessionId;
    const localUser = this.state.localUser;
    var playerWidth = this.state.dimension.width;
    var playerHeight = this.state.dimension.height;
    var videoroomHeight = this.props.ratio * playerHeight;

    return (
      <Box className="videoRoomContainer">
        <DialogExtensionComponent
          showDialog={this.state.showExtensionDialog}
          cancelClicked={this.closeDialogExtension}
        />
        <Box height={videoroomHeight}>
          <Box id="layout" className="bounds">
            {localUser !== undefined &&
              localUser.getStreamManager !== undefined &&
              localUser.getStreamManager !== null &&
              localUser.getStreamManager() !== null &&
              localUser.getStreamManager() !== undefined &&
              (this.state.cameraConnected === true ||
                localUser.isStreamer() === true) && (
                <Box
                  className="OT_root OT_publisher custom-class"
                  id="localUser"
                >
                  <StreamComponent
                    isMainVideo={false}
                    user={localUser}
                    handleNickname={this.nicknameChanged}
                    monitorWallActive={false}
                    onAir={this.state.onAir}
                    getUserWebcamSlot={this.getUserWebcamSlot}
                    overrideClass={classes.videoBounds}
                  />
                </Box>
              )}

            {this.state.monitorWallActive
              ? this.state.subscribers
                  .reduce(
                    (state, sub) => {
                      const [subscribers, screenShare, streamers] = state;
                      const userWebcamSlot = this.getUserWebcamSlot(sub);
                      const ss =
                        userWebcamSlot === 11 &&
                        screenShare === "screenShareEmpty"
                          ? sub
                          : "screenShareEmpty";
                      if (userWebcamSlot === 12) {
                        streamers.push(sub);
                      }
                      if (userWebcamSlot <= 10) {
                        subscribers[userWebcamSlot - 1] = sub;
                      }
                      return [subscribers, ss, streamers];
                    },
                    [
                      Array.from(
                        { length: localUser.getLicence().webcamNumberMaximum },
                        () => null
                      ),
                      "screenShareEmpty",
                      [],
                    ]
                  )
                  .flat()
                  .reduce((acc, curr, idx, arr) => {
                    acc[idx] = curr;
                    if (
                      curr &&
                      (curr === "screenShareEmpty" ||
                        this.getUserWebcamSlot(curr) === 11) &&
                      arr.length - 1 === idx
                    ) {
                      acc.push("programEmpty");
                    }
                    return acc;
                  }, [])
                  .map((sub, idx, arr) => (
                    <Box
                      key={idx}
                      className="OT_root OT_publisher custom-class"
                      id={`remoteUsers-indx-${idx}`}
                    >
                      {sub &&
                      sub !== "screenShareEmpty" &&
                      sub !== "programEmpty" ? (
                        <StreamComponent
                          isMainVideo={false}
                          user={sub}
                          streamId={sub.streamManager.stream.streamId}
                          monitorWallActive={this.state.monitorWallActive}
                          onAir={this.state.onAir}
                          getUserWebcamSlot={this.getUserWebcamSlot}
                          overrideClass={classes.videoBounds}
                        />
                      ) : (
                        <Box
                          className={
                            classes[
                              sub === "screenShareEmpty"
                                ? "screenShareBackground"
                                : sub === "programEmpty"
                                ? "programBackground"
                                : `webcam${idx + 1}Background`
                            ]
                          }
                        />
                      )}
                    </Box>
                  ))
              : this.state.subscribers
                  .filter((sub) => {
                    if (this.state.monitorWallActive) {
                      return true;
                    } else {
                      return sub.isStreamer() === false;
                    }
                  })
                  .map((sub, i) => (
                    <Box
                      key={i}
                      className="OT_root OT_publisher custom-class"
                      id="remoteUsers"
                    >
                      <StreamComponent
                        isMainVideo={false}
                        user={sub}
                        streamId={sub.streamManager.stream.streamId}
                        monitorWallActive={this.state.monitorWallActive}
                        onAir={this.state.onAir}
                        getUserWebcamSlot={this.getUserWebcamSlot}
                        overrideClass={classes.videoBounds}
                      />
                    </Box>
                  ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionReady: state.websocket.connection.events.sessionReady,
  localUserReady: state.websocket.connection.events.localUserReady,
  subscriberEvent: state.websocket.callEvents.subscriberEvent,
  refresh: state.ui.refresh,
  localUserRefresh: state.websocket.callEvents.localUserRefresh,
  cameraConnected: state.websocket.connection.cameraConnected,
  monitorWallActive: state.ui.callModeratorComponent.monitorWallActive,
  onAir: state.websocket.call.onAir,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(VideoRoomComponent))
);
