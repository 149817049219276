import { drawBokehEffect, toMask } from "@tensorflow-models/body-pix";
import blur from "./blur";
import egyptSrcFHD from "../../assets/images/tensorflow/images/egypt-FHD.jpg"
import espaceSrcFHD from "../../assets/images/tensorflow/images/espace-FHD.jpg"
import tailandSrcFHD from "../../assets/images/tensorflow/images/tailand-FHD.jpg"

import egyptSrcHD from "../../assets/images/tensorflow/images/egypt-HD.jpg"
import espaceSrcHD from "../../assets/images/tensorflow/images/espace-HD.jpg"
import tailandSrcHD from "../../assets/images/tensorflow/images/tailand-HD.jpg"

import egyptSrcVGA16x9 from "../../assets/images/tensorflow/images/egypt-VGA16x9.jpg"
import espaceSrcVGA16x9 from "../../assets/images/tensorflow/images/espace-VGA16x9.jpg"
import tailandSrcVGA16x9 from "../../assets/images/tensorflow/images/tailand-VGA16x9.jpg"

import egyptSrcVGA from "../../assets/images/tensorflow/images/egypt-VGA.jpg"
import espaceSrcVGA from "../../assets/images/tensorflow/images/espace-VGA.jpg"
import tailandSrcVGA from "../../assets/images/tensorflow/images/tailand-VGA.jpg"


import egyptSrcQVGA from "../../assets/images/tensorflow/images/egypt-QVGA.jpg"
import espaceSrcQVGA from "../../assets/images/tensorflow/images/espace-QVGA.jpg"
import tailandSrcQVGA from "../../assets/images/tensorflow/images/tailand-QVGA.jpg"

const sources = {
    egyptSrcFHD,
    espaceSrcFHD,
    tailandSrcFHD,
    egyptSrcHD,
    espaceSrcHD,
    tailandSrcHD,
    egyptSrcVGA16x9,
    espaceSrcVGA16x9,
    tailandSrcVGA16x9,
    egyptSrcVGA,
    espaceSrcVGA,
    tailandSrcVGA,
    egyptSrcQVGA,
    espaceSrcQVGA,
    tailandSrcQVGA,
}

/**
 * type Filter = (canvas: HTMLCanvasElement, video: HTMLVideoElement, bodyPix: BodyPix) => void
 * interface Filters {
 *    [name: string]: Filter
 * }
 */

async function applyBackgroundImage(canvas, video, bodypix, image) {
    const transparent = { r: 0, g: 0, b: 0, a: 0 }
    const black = { r: 0, g: 0, b: 0, a: 255 }
    const segmentation = await bodypix.segmentPerson(video)
    const mask = toMask(segmentation, black, transparent)
    const ctxt = canvas.getContext('2d')

    if (ctxt) {
        ctxt.save()
        ctxt.drawImage(video, 0, 0)
        ctxt.globalCompositeOperation = "destination-in"
        const bluredMask = blur(mask, 7, 1)
        ctxt.drawImage(await createImageBitmap(bluredMask), 0, 0)
        ctxt.globalCompositeOperation = "destination-over"
        ctxt.drawImage(image, 0, 0)
        ctxt.restore()
    }
}


export const filters = {
    "blur": async (canvas, video, bodypix, onDone, _resolution) => {
        const segmentation = await bodypix.segmentPerson(video)
        drawBokehEffect(canvas, video, segmentation, 15, 4)
        onDone()
    },
    "img:egypt": async (canvas, video, bodypix, onDone, resolution) => {
        const egyptImage = new Image()
        egyptImage.src = sources[`egyptSrc${resolution}`]
        egyptImage.onload = () => {
            onDone()
        }
        await applyBackgroundImage(canvas, video, bodypix, egyptImage)
    },
    "img:espace": async (canvas, video, bodypix, onDone, resolution) => {
        const espaceImage = new Image()
        espaceImage.src = sources[`espaceSrc${resolution}`]
        espaceImage.onload = () => {
            onDone()
        }
        await applyBackgroundImage(canvas, video, bodypix, espaceImage)
    },
    "img:tailand": async (canvas, video, bodypix, onDone, resolution) => {
        const tailandImage = new Image()
        tailandImage.src = sources[`tailandSrc${resolution}`]
        tailandImage.onload = () => {
            onDone()
        }
        await applyBackgroundImage(canvas, video, bodypix, tailandImage)
    },

    // TODO: perlin noise rainbow filter?
}