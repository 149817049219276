import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";
import { Collapse } from "@material-ui/core";
import {
  getUserDetails,
  addUserToIntercom,
  allowScreenShare,
  moderateUser,
  removeUserFromCall,
  sendMessageToUser,
  setUserInWebcamSlot,
  muteUnmuteUserMic,
  muteUnmuteUserCam,
  setUserCamera,
  toggleUserCamera,
  setUserMicrophone,
  setUserResolution,
  setUserAllowScreenShare,
  setUserScreenShare,
} from "../../../../utils/callControllerUtils";
import UserCard from "../cards/UserCard";
import { openTimeSlotEvent } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";


class ParticipantCard extends Component {
  constructor(props) {
    super(props);
    this.browserUtil = new BrowserUtils();
    const reduxState = websocketServices.reduxStore.default.getState()
    const uiModState = reduxState.ui.callModeratorComponent
    const wsState = reduxState.websocket
    this.state = {
      isOnAir: wsState.call.isOnAir,
      participants: Object.values(wsState.participants),
      callModerators: Object.values(wsState.callModerators),
      participantsInputValue: uiModState.participantsInputValue,
      moderatedInputValue: uiModState.moderatedInputValue,
      notConnectedInputValue: uiModState.notConnectedInputValue,
      contributorsInputValue: uiModState.contributorsInputValue,
      callModeratorsInputValue: uiModState.callModeratorsInputValue,
      participantSelected: uiModState.participantSelected,
      moderatedSelected: uiModState.moderatedSelected,
      notConnectedSelected: uiModState.notConnectedSelected,
      contributorSelected: uiModState.contributorSelected,
      callModeratorSelected: uiModState.callModeratorSelected,
      onAir: wsState.call.onAir,
      intercom: wsState.intercom,
      dimension: this.browserUtil.getWindowDimensions(),
    };
    this.handleResize = this.handleResize.bind(this);
  }
  addUserToModerated(user, userIsInSlot, note) {
    if (userIsInSlot && note === undefined) {
      websocketServices.signals.removeUserFromModerated(user.userid);
    } else {
      if (note !== undefined) {
        user.note = note;
      }
      websocketServices.signals.addUserToModerated(user);
    }
  }
  addUserToDirector(user, userIsInSlot) {
    if (userIsInSlot) {
      websocketServices.signals.removeUserFromDirector(user.userid);
    } else {
      websocketServices.signals.addUserToDirector(user);
    }
  }
  addUserToPartyLine(user, userIsInSlot) {
    if (userIsInSlot) {
      websocketServices.signals.removeUserFromPartyLine(user.userid);
    } else {
      websocketServices.signals.addUserToPartyLine(user);
    }
  }
  connectUser(user) {}
  kickUser(user) {}
  openTimeSlot() {
    websocketServices.reduxStore.default.dispatch(openTimeSlotEvent())
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentDidMount() {}
  render() {
    var visible =
      (this.state.participantSelected !== null &&
        this.state.participantsInputValue !== "") ||
      (this.state.notConnectedSelected !== null &&
        this.state.notConnectedInputValue !== "") ||
      (this.state.moderatedSelected !== null &&
        this.state.moderatedInputValue !== "") ||
      (this.state.contributorSelected !== null &&
        this.state.contributorsInputValue !== "");

    var user = {
      username: "",
      avatar: "",
      userid: "",
      details: {},
    };

    var selected;
    if (this.state.participantSelected !== null && this.state.participantSelected !== undefined) {
      selected = this.state.participantSelected;
    } else if (this.state.notConnectedSelected !== null && this.state.notConnectedSelected !== undefined) {
      selected = this.state.notConnectedSelected;
    } else if (this.state.moderatedSelected !== null && this.state.moderatedSelected !== undefined) {
      selected = this.state.moderatedSelected;
    } else if (this.state.contributorSelected !== null && this.state.contributorSelected !== undefined) {
      selected = this.state.contributorSelected;
    }

    if (visible && selected !== undefined) {
      user = getUserDetails(this.state.participants, selected);
    }
    return (
      // <Collapse in={visible}>
        <UserCard
          size={"big"}
          bigger={this.props.bigger}
          user={user}
          onAir={this.state.onAir}
          intercom={this.state.intercom}
          moderated={this.state.moderated}
          isOnAir={this.state.isOnAir}
          setUserInWebcamSlot={setUserInWebcamSlot}
          moderateUser={moderateUser}
          addUserToIntercom={addUserToIntercom}
          sendMessageToUser={sendMessageToUser}
          removeUserFromCall={setUserInWebcamSlot}
          allowScreenShare={allowScreenShare}
          addUserToModerated={this.addUserToModerated}
          addUserToDirector={this.addUserToDirector}
          addUserToPartyLine={this.addUserToPartyLine}
          connectUser={this.connectUser}
          kickUser={this.kickUser}
          muteUnmuteUserMic={muteUnmuteUserMic}
          muteUnmuteUserCam={muteUnmuteUserCam}
          setUserCamera={setUserCamera}
          toggleUserCamera={toggleUserCamera}
          setUserMicrophone={setUserMicrophone}
          setUserResolution={setUserResolution}
          setUserAllowScreenShare={setUserAllowScreenShare}
          setUserScreenShare={setUserScreenShare}
          cameraConnected={this.props.cameraConnected}
          monitorWallActive={this.props.monitorWallActive}
          openTimeSlot={this.openTimeSlot}
          dimension={this.state.dimension}
        />
      // </Collapse>
    );
  }
}

const mapStateToProps = (state, thisProps) => ({
  isOnAir: state.websocket.call.isOnAir,
  onAir: state.websocket.call.onAir,
  participants: state.websocket.participants,
  callModerators: state.websocket.callModerators,
  moderated: state.websocket.intercom.moderated,
  notConnected: state.websocket.notConnected,
  contributors: state.websocket.contributors,
  participantsInputValue:
    state.ui.callModeratorComponent.participantsInputValue,
  moderatedInputValue: state.ui.callModeratorComponent.moderatedInputValue,
  notConnectedInputValue:
    state.ui.callModeratorComponent.notConnectedInputValue,
  contributorsInputValue:
    state.ui.callModeratorComponent.contributorsInputValue,
  callModeratorsInputValue:
    state.ui.callModeratorComponent.callModeratorsInputValue,
  participantSelected: state.ui.callModeratorComponent.participantSelected,
  moderatedSelected: state.ui.callModeratorComponent.moderatedSelected,
  notConnectedSelected: state.ui.callModeratorComponent.notConnectedSelected,
  contributorSelected: state.ui.callModeratorComponent.contributorSelected,
  callModeratorSelected: state.ui.callModeratorComponent.callModeratorSelected,
  intercom: state.websocket.intercom,
});

export default withTranslation()(connect(mapStateToProps)(ParticipantCard));
