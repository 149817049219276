import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {openDialog}  from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";

export default function RemoveFromCallDialog(props) {
  const [open, setOpen] = React.useState(false);
  var dialog = JSON.parse(JSON.stringify(props.dialog));
  if (dialog.buttons.length === 0) {
    dialog.buttons.push({title:''});
    dialog.buttons.push({title:''}); 
    dialog.buttons.push({title:''});
  }

  const clearFromCall = () => {
    props.websocketServices.signals.clearFromCallParticipant(dialog.buttons[0].user, dialog.buttons[0].slot);
    handleClose();
  }
  const addToCall = () => {
    props.websocketServices.signals.addCallParticipant(dialog.buttons[1].user, dialog.buttons[1].slot);
    handleClose();
  }
  const handleClose = () => {
    var dialog = props.defaultDialog;
    // console.warn("dialog: ", props.defaultDialog)
    
    props.dispatch(
      openDialog(dialog)
    );
  };

  return (
    <div>

      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearFromCall} color="primary">
            {dialog.buttons[0].title}
          </Button>
          <Button onClick={addToCall} color="primary">
          {dialog.buttons[1].title}
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
          {dialog.buttons[2].title}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}