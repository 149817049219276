import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./StreamComponent.css";
import OvVideoComponent from "./OvVideo";
import { withStyles } from "@material-ui/core/styles";
import i18nInstance from "../../i18n";
import MicOff from "@material-ui/icons/MicOff";
import VideocamOff from "@material-ui/icons/VideocamOff";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeOff from "@material-ui/icons/VolumeOff";
import IconButton from "@material-ui/core/IconButton";
import poster from "../../assets/images/poster-image.png";
import { Box, Tooltip } from "@material-ui/core";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import { websocketServices } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { captureUserAvatar } from "../../utils/callControllerUtils";
import {
  setSelectedUser,
  setUserInputValue,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import {
  PARTICIPANTS,
  PARTICIPANTS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
const styles = (theme) => ({
  videoButton: {
    "& svg": {
      fontSize: "1.4vw",
    },
  },

  statusIcons: {
    // paddingTop: 5,
    bottom: 0,
    background: "#c71100",
    width: "2.2vw",
    height: "fit-content",
    position: "absolute",
    color: "#f0f2f6",
    "& svg": {
      fontSize: "1.4vw",
    },
  },

  camIcon: {
    textAlign: "center",
    paddingTop: "0.25vw",
    "& svg": {
      fontSize: "1.4vw",
    },
  },
  micIcon: {
    textAlign: "center",
    paddingTop: "0.25vw",
    "& svg": {
      fontSize: "1.4vw",
    },
  },
  takeAvatarIcon: {
    textAlign: "center",
    paddingTop: "0.25vw",
    cursor: "pointer",
    "& svg": {
      fontSize: "1.4vw",
    },
  },

  volumeButton: {
    backgroundColor: "#000000",
    position: "absolute",
    bottom: "15px",
    right: "1px",
    zIndex: "1000",
    color: "#f0f2f6",
    width: "2.5vw",
    height: "2.5vw",
    "& svg": {
      fontSize: "1.4vw",
    },
  },

  nickname: {
    background: "rgba(58, 64, 74, 0.651)",
    padding: "0.25vw !important",
    position: "absolute",
    top: 0,
    zIndex: "999",
    color: "#f0f2f6",
    "& span": {
      fontSize: "0.7vw",
    },
    cursor: "default",
  },
  nicknameMonitorWall: {
    background: "rgba(58, 64, 74, 0.651)",
    padding: "0.25vw !important",
    position: "absolute",
    top: 0,
    zIndex: "999",
    color: "#f0f2f6",
    "& span": {
      fontSize: "0.7vw",
    },
    cursor: "pointer",
  },
  posterImage : {
    position: "absolute",
    left: "0px",
    bottom: "0px",
    top: "0px",
    right: "0px",
    width: "100%",
    background: "url('"+poster+"') #000000 no-repeat center center",
    backgroundSize: "contain",
}
});
class StreamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: this.props.user.getNickname(),
      showForm: false,
      mutedSound: false,
      isFormValid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePressKey = this.handlePressKey.bind(this);
    this.toggleNicknameForm = this.toggleNicknameForm.bind(this);
    this.toggleSound = this.toggleSound.bind(this);
    this.captureAvatar = this.captureAvatar.bind(this);
    this.getUserWebcamSlotText = this.getUserWebcamSlotText.bind(this);
    this.selectUser = this.selectUser.bind(this);
  }

  handleChange(event) {
    this.setState({ nickname: event.target.value });
    event.preventDefault();
  }

  toggleNicknameForm() {
    if (this.props.user.isLocal()) {
      this.setState({ showForm: !this.state.showForm });
    }
  }

  toggleSound() {
    this.setState({ mutedSound: !this.state.mutedSound });
  }

  getUserWebcamSlotText(user) {
    return "Webcam " + this.props.getUserWebcamSlot(user);
  }
  handlePressKey(event) {
    if (event.key === "Enter") {
      // console.log(this.state.nickname);
      if (this.state.nickname.length >= 3 && this.state.nickname.length <= 20) {
        this.props.handleNickname(this.state.nickname);
        this.toggleNicknameForm();
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    }
  }
  captureAvatar(event) {
    event.preventDefault();
    captureUserAvatar(this.props.user);
  }
  selectUser() {
    if (
      this.props.monitorWallActive &&
      !this.props.user.isStreamer() &&
      !this.props.user.userid.includes("screenSharing")
    ) {
      var payload = {
        userType: PARTICIPANTS,
        value:
          websocketServices.reduxStore.default.getState().websocket
            .participants[this.props.user.getConnectionId()],
      };
      websocketServices.reduxStore.default.dispatch(setSelectedUser(payload));
      var payload2 = {
        type: PARTICIPANTS_INPUT_VALUE,
        value: this.props.user.getNickname(),
      };
      websocketServices.reduxStore.default.dispatch(
        setUserInputValue(payload2)
      );
    }
  }
  render() {
    const { classes, theme } = this.props;
    var player = this.props.player;
    if (player === undefined) {
      player = 0;
    }
    return (
      <div className="OT_widget-container">
        {player === 0 && this.props.isMainVideo === false && (
          <div
            className={
              this.props.monitorWallActive &&
              !this.props.user.isStreamer() &&
              !this.props.user.userid.includes("screenSharing")
                ? classes.nicknameMonitorWall
                : classes.nickname
            }
            onClick={this.selectUser}
          >
            <div>
              {this.props.user.userid.includes("screenSharing") ? (
                <span id="nickname">
                  {i18nInstance.t("stream|monitorScreenShareBegin")}
                  {this.props.user.getNickname()}
                  {i18nInstance.t("stream|monitorScreenShareEnd")}
                </span>
              ) : (
                <span id="nickname">{this.props.user.getNickname()}</span>
              )}
            </div>
            <div>
              {this.props.monitorWallActive &&
                !this.props.user.userid.includes("screenSharing") &&
                !this.props.user.isStreamer() && (
                  <span>{this.getUserWebcamSlotText(this.props.user)}</span>
                )}
            </div>
          </div>
        )}
        {this.props.user !== undefined &&
        this.props.user.getStreamManager !== undefined &&
        this.props.user.getStreamManager() !== undefined ? (
          <div className="streamComponent">
            <OvVideoComponent
              user={this.props.user}
              mutedSound={this.state.mutedSound}
              overrideClass={this.props.overrideClass}
            />
            {!this.props.user.isVideoActive() && (
              // <img className="posterImage" src={poster} />
              <Box className={classes.posterImage}></Box>
            )}
            {this.props.isMainVideo === false && (
              <div id="statusIcons" className={classes.statusIcons}>
                {!this.props.user.isVideoActive() &&
                !this.props.user.userid.includes("screenSharing") ? (
                  <div id="camIcon" className={classes.camIcon}>
                    <VideocamOff id="statusCam" />
                  </div>
                ) : null}

                {!this.props.user.isAudioActive() &&
                !this.props.user.userid.includes("screenSharing") ? (
                  <div id="micIcon" className={classes.micIcon}>
                    <MicOff id="statusMic" />
                  </div>
                ) : null}
                {websocketServices.getLocalUser().isCallModerator() &&
                this.props.user.getUserId() !==
                  websocketServices.getLocalUser().getUserId() &&
                !this.props.user.isCallModerator() &&
                !this.props.user.userid.includes("screenSharing") &&
                !this.props.user.isStreamer() ? (
                  <Tooltip
                    title={i18nInstance.t("CommonButtons|takeAvatar")}
                    arrow
                  >
                    <div
                      id="takeAvatarIcon"
                      onClick={this.captureAvatar}
                      className={classes.takeAvatarIcon}
                    >
                      <AddAPhotoOutlinedIcon />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            )}
            <div>
              {!this.props.user.isLocal() &&
                player === 0 &&
                this.props.isMainVideo === false &&
                !this.props.user.userid.includes("screenSharing") && (
                  <IconButton
                    id="volumeButton"
                    onClick={this.toggleSound}
                    className={classes.volumeButton}
                  >
                    {this.state.mutedSound ? (
                      <VolumeOff color="secondary" />
                    ) : (
                      <VolumeUp />
                    )}
                  </IconButton>
                )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(StreamComponent));
