import React from "react";
import { Collapse } from "@material-ui/core";
import { callDidUpdate } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addModerateUser, removeModerateUser, startStopModeration } from "../../../../utils/callControllerUtils";
import { ModerationCard } from "../cards/ModerationCard";
import { setSelectedIntercomInputValue, setSelectedIntercomSlot } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";
import BrowserUtils from "@reflex-interactions/library-reflex-client/dist/reflexAPI/utils/BrowserUtils";

// TODO: the equivalent compenent for the participant is called ParticipantCard. conflicting naming schemes?
class ModerationComponent extends Component {
  /**
   * props:
   *
   */
  constructor(props) {
    super(props);
    // TODO: implement me
    this.browserUtil = new BrowserUtils();
    this.state = {
      moderationsInputValue: "",
      moderationsSelected: null,
      intercom: {
        partyLine: {},
        controlRoom: {},
        director: {},
        moderations: {},
        moderated: {},
      },
      dimension: this.browserUtil.getWindowDimensions(),
    };
    this.buttonClicked = this.buttonClicked.bind(this)
    this.setSelectedIntercom = this.setSelectedIntercom.bind(this);
    this.setSelectedIntercomInput = this.setSelectedIntercomInput.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  

  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  handleResize() {
    this.setState({ dimension: this.browserUtil.getWindowDimensions() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  setSelectedIntercom(intercomType, value) {
    var payload = { intercomType: intercomType, value: value };
    this.props.dispatch(setSelectedIntercomSlot(payload));
  }
  setSelectedIntercomInput(type, newInputValue) {
    var payload = { type: type, value: newInputValue };
    this.props.dispatch(setSelectedIntercomInputValue(payload));
  }
  buttonClicked(localUserIsProprietor, moderation, moderations) {
    
    if (localUserIsProprietor) {
      //Si localUserIsProprietor fait startStop a partir de moderation.started
      startStopModeration(moderation.id, moderation.started, moderation, moderations) 
    } else {
      
      //Sinon !localUserIsProprietor et le user est dans moderation removeUserToModeration sinon fait addUserToModeration
      if (moderation[this.props.localUser.getUserId()] !== undefined) {
        // console.warn('############################## buttonClicked !localUserIsProprietor im in')
        removeModerateUser(moderation[this.props.localUser.getUserId()], moderation.id, moderations)
      }
      else {
        // console.warn('############################## buttonClicked !localUserIsProprietor im out')
        const user = {
          online: true,
          // avatar: this.props.localUser.getAvatar(),
          type: "CALL_MODERATOR",
          userid: this.props.localUser.getUserId(),
          username: this.props.localUser.getNickname()
        }
        addModerateUser(user, moderation.id)
      }
    }
  }

  render() {

    const selectedModeration = this.state.moderationsSelected
    return (
   
        <ModerationCard 
            localUser={this.props.localUser}
            moderation={selectedModeration}
            removeModerateUser={removeModerateUser}
            buttonClicked={this.buttonClicked}
            setSelectedIntercom={this.setSelectedIntercom}
            setSelectedIntercomInputValue={this.setSelectedIntercomInput}
            selected={this.state.moderationsSelected}
            inputValue={this.state.moderationsInputValue}
            cameraConnected={this.props.cameraConnected}
            dimension={this.state.dimension}
        />
    
    );
  }
}

const mapStateToProps = (state, thisProps) => ({
  intercom: state.websocket.intercom,
  moderationsInputValue: state.ui.callModeratorComponent.moderationsInputValue,
  moderationsSelected: state.ui.callModeratorComponent.moderationsSelected,
  
});

export default withTranslation()(connect(mapStateToProps)(ModerationComponent));
