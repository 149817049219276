import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  makeStyles,
  Typography,
  Box,
  CardContent,
  Chip,
  Avatar,
  List,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  MODERATIONS,
  MODERATIONS_INPUT_VALUE,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/constants/common";
import IntercomSlotComponent from "../intercom/IntercomSlotComponent";
import { ModerationUserComponent } from "./ModerationUserComponent";
import { useState } from "react";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey4.main,
    marginTop: "5px",
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  rootStarted: {
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.reflexBlue.main,
    marginTop: "5px",
    minHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    maxHeight: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      10,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  callModeratorChip: {
    color: theme.palette.reflexBlue.main,
    margin: "5px",
  },
  userChip: {
    color: theme.palette.reflexGreen.main,
    margin: "5px",
  },
  cardHeader: {
    fontSize: "0.8em",
    color: theme.palette.reflexYellow.main,
    marginTop: "15px",
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
  },
  moderations: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  moderationAutocomplete: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardContent: {
    padding: "5px",
  },
  joinButton: {
    fontSize: "0.6vw",
  },
  userList: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: (props) =>
      parseInt(props.dimension.height) -
      parseInt(document.getElementById("header")?.clientHeight) -
      parseInt(
        document.getElementById("moderatorToolBarContainer")?.clientHeight
      ) -
      parseInt(document.getElementById("videoRoomContainer")?.clientHeight) -
      parseInt(document.getElementById("callControllerFooter")?.clientHeight) -
      parseInt(document.getElementById("moderationTitle")?.clientHeight) -
      parseInt(document.getElementById("moderationContent")?.clientHeight) -
      parseInt(document.getElementById("intercomSlotComponent")?.clientHeight) -
      40,
  },
}));

/**
 * interface Moderation = {
 *  [key: string]: User,
 *  started: boolean
 *  name: string,
 * }
 */

/**
 * props:
 *  moderation: Moderation
 *  removeModerateUser: (User, moderationId: string, moderations: { [key: string]: Moderation })
 */

const selectModerations = (state) => state.websocket.intercom.moderations;
const selectMonitorWallActive = state => state.ui.callModeratorComponent.monitorWallActive

export function ModerationCard(props) {
  // TODO: can be cleaner. refactor.
  const { t } = useTranslation();
  const classes = useStyles(props);
  const moderations = useSelector(selectModerations);
  const monitorWallActive = useSelector(selectMonitorWallActive)

  let moderation = { name: "", started: false, id: "" };
  if (
    props.moderation !== null &&
    props.moderation !== undefined &&
    props.moderation !== ""
  ) {
    const realModeration = moderations[JSON.parse(props.moderation).id];
    if (realModeration !== undefined) moderation = realModeration;
  }
  const localUserIsProprietor = moderation.id === props.localUser.getUserId(),
    localUserIsInModeration =
      moderation[props.localUser.getUserId()] !== undefined;

  const buttonDisabled = (!localUserIsProprietor && !moderation.started) || monitorWallActive;

  const [defaultModerationInitialized, setDefaultModerationInitialized] =
    useState(false);

  useEffect(() => {
    if (
      !defaultModerationInitialized &&
      moderations[props.localUser.getUserId()] !== undefined
    ) {
      props.setSelectedIntercomInputValue(
        MODERATIONS,
        moderations[props.localUser.getUserId()][props.localUser.getUserId()]
          .username
      );
      props.setSelectedIntercom(
        MODERATIONS,
        JSON.stringify(moderations[props.localUser.getUserId()])
      );
      setDefaultModerationInitialized(true);
    }
  }, [moderations]);

  const buttonClicked = () => {
    if (props.buttonClicked !== null && props.buttonClicked !== undefined) {
      props.buttonClicked(localUserIsProprietor, moderation, moderations);
    }
  };

  const handleDelete = (id) => {
    const user = moderation[id];
    props.removeModerateUser(
      user,
      JSON.parse(props.moderation).id,
      moderations
    );
  };

  return (
    <Card className={moderation.started ? classes.rootStarted : classes.root}>
      <Typography className={classes.cardHeader} id="moderationTitle">
        {t("ModerationCard|moderationNameBefore") +
          moderation.name +
          t("ModerationCard|moderationNameAfter")}
      </Typography>

      {/* put some sort of indicator to say if the moderation has started */}
      <CardActions id="moderationContent">
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.joinButton}
            disabled={buttonDisabled}
            onClick={buttonClicked}
          >
            {localUserIsProprietor
              ? moderation.started
                ? t("ModerationCard|stopModeration")
                : t("ModerationCard|startModeration")
              : localUserIsInModeration
              ? t("ModerationCard|leaveModeration")
              : t("ModerationCard|joinModeration")}
          </Button>
        </Box>
      </CardActions>
      <IntercomSlotComponent
        className={classes.moderations}
        // type={"moderations"}
        intercomSlotName={t("IntercomSlotComponent|Moderations")}
        intercomSlotId="moderations"
        setSelectedIntercomSlot={props.setSelectedIntercom}
        setSelectedIntercomInputValue={props.setSelectedIntercomInputValue}
        selected={props.moderationsSelected}
        inputValue={props.moderationsInputValue}
        inputValueType={MODERATIONS_INPUT_VALUE}
        type={MODERATIONS}
        localUser={props.localUser}
        disabled={moderation.started}
      />

      <CardContent className={classes.cardContent}>
        <Box>
          <List dense={true} className={classes.userList}>
            {Object.entries(moderation)
              .filter(([key, _value]) =>
                ["started", "name", "id"].every((it) => it !== key)
              )
              .sort(([_id1, user1], [_id2, user2]) =>
                user1.type.localeCompare(user2.type)
              )
              .map(([id, user]) => (
                <ModerationUserComponent
                  participant={user}
                  removeUser={handleDelete}
                  userIsProprietor={
                    user.userid === props.localUser.getUserId() &&
                    localUserIsProprietor === true
                      ? true
                      : false
                  }
                />
              ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}
