import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UsersLists from "./UsersLists";
import { createContext, useContext, useState } from "react";
import { store } from "react-notifications-component";
import {
  Webcam10Icon,
  Webcam1Icon,
  Webcam2Icon,
  Webcam3Icon,
  Webcam4Icon,
  Webcam5Icon,
  Webcam6Icon,
  Webcam7Icon,
  Webcam8Icon,
  Webcam9Icon,
} from "../../../buttons/commonIcon";
import {
  CallOnAirButtonTimeSlot, WebcamRowDownButton,
} from "../../../buttons/commonButtons";
import UserAvatar from "../users/UserAvatar";
import { getUserAvatar } from "../../../../utils/callControllerUtils";
import _ from "lodash";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import { openModalNextOpenEvents } from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui";

const labelIcons = {
  1: Webcam1Icon,
  2: Webcam2Icon,
  3: Webcam3Icon,
  4: Webcam4Icon,
  5: Webcam5Icon,
  6: Webcam6Icon,
  7: Webcam7Icon,
  8: Webcam8Icon,
  9: Webcam9Icon,
  10: Webcam10Icon,
  11: DesktopWindowsIcon,
};

function mapOnAirToOnAirQueue(onAir) {
  /**
   * transforms the shape of onAir to a timeSlots queue
   * FROM
   * {
   *  "1": ["0": mike, "1": john],
   *  "2": ["0": null, "1": claire],
   * }
   * TO
   * [
   *  "0": {"1": mike, "2": null},
   *  "1": {"1": john, "2": claire}
   * ]
   */
  const emptyUser = {
    userid: "",
  };

  return Object.entries(onAir).reduce(
    (accumulator, [webcamSlot, timeSlots]) => {
      timeSlots.forEach((user, timeSlot) => {
        if (accumulator[timeSlot] === undefined) {
          accumulator[timeSlot] = {};
          for (let i = 1; i <= 10; i++) accumulator[timeSlot][i] = emptyUser;
          accumulator[timeSlot][100] = emptyUser;
        }
        accumulator[timeSlot][webcamSlot] = { user, webcamSlot, timeSlot };
      });
      return accumulator;
    },
    []
  );
}

const stringAvatar = (username) => {
    return username.charAt(0).toUpperCase();
};

const TimeSlotEditorBindingsContext = createContext();
const LicencingContext = createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: (props) => (props.cameraConnected || props.monitorWallActive ? parseInt(document.getElementById("header")?.clientHeight) + parseInt(document.getElementById('videoRoomContainer')?.clientHeight) : parseInt(document.getElementById("header")?.clientHeight) + 10),
    bottom: (props) => (props.cameraConnected || props.monitorWallActive ?parseInt(document.getElementById("callControllerFooter")?.clientHeight)  :parseInt(document.getElementById("callControllerFooter")?.clientHeight)) ,
    left: "50%",
    transform: (props) => (props.cameraConnected || props.monitorWallActive ?"translate(-50%, 0%)":"translate(-50%, 0%)"),
    width: "99vw",
    height: (props) => (props.cameraConnected || props.monitorWallActive ?"calc(100vh - 70px - "+parseInt(document.getElementById('videoRoomContainer')?.clientHeight)+"px)": "calc(100vh - 90px )"),
    maxHeight: (props) => (props.cameraConnected || props.monitorWallActive ?"calc(100vh - 70px - "+parseInt(document.getElementById('videoRoomContainer')?.clientHeight)+"px)": "calc(100vh - 90px)"),
    overflow: "hidden",
  },
  header: {
    display: "inline-flex",
    width: "100%",
    height: "40px",
    backgroundColor: theme.palette.reflexGreen.main,
    position: "relative",
    textAlign: "center",
  },
  headerOnAir: {
    display: "inline-flex",
    width: "100%",
    height: "40px",
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
    textAlign: "center",
  },
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "primary",
    marginTop: "7px",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "-4px",
  },
  webcamSlot: {
    backgroundColor: theme.palette.grey7.main,
    borderRadius: 0,
    height: "100%",
    textAlign: "center",
    border: "solid",
    borderColor: theme.palette.grey1.main,
    borderWidth: 1,
    justifyContent: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  webcamSlotNotLicenced : {
    backgroundColor: theme.palette.grey7.main,
    borderRadius: 0,
    height: "100%",
    textAlign: "center",
    border: "solid",
    borderColor: theme.palette.grey1.main,
    borderWidth: 1,
    justifyContent: "center",
    cursor: "not-allowed",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  slotLabel: {
    color: theme.palette.grey1.main,
    marginLeft: "auto", marginRight: "auto",
    textAlign: "center"
  },
  bottomLabel: {
    marginTop: "10px",
    overflow: "hiddem",
  },
  timeSlot: {
    marginBottom: "5px",
    marginTop: "5px",
  },
  username: {
    margin: "auto",
    display: "flex",
    fontSize: "0.7vw",
  },
  usernameEmpty: {
    margin: "auto",
    display: "flex",
    color: theme.palette.grey1.main,
    fontSize: "0.7vw",
  },
  webcamSlotDeleteButton: {
    margin: "auto",
    display: "flex",
    padding: "0px",
    "& svg": {
      fontSize:  "1.5vw",
    },
  },
  indexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  indexLabel: {
    display: "flex",
    margin: "auto",
    color: theme.palette.reflexYellow.main,
  },
  putOnAirButton: {
    position: "absolute",
    left: "10px",
    top: "-4px",
  },
  nextButton : {
    position: "absolute",
    left: "50px",
    top: "6px",
  },
  modalConfirmDelete: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    backgroundColor: theme.palette.grey1.main,
  },
  choseButton: {
    margin: "10px",
  },
  queueList: {
    margin: "10px",
    overflowY: "scroll",
    overflowX: "hidden",
    // height: "calc(100vh - 350px )",
    // maxHeight: "calc(100vh - 350px )",
    height: (props) => (props.cameraConnected || props.monitorWallActive ?"calc(100vh - 350px - "+parseInt(document.getElementById('videoRoomContainer')?.clientHeight)+"px)" : "calc(100vh - 350px)"),
    maxHeight: (props) => (props.cameraConnected || props.monitorWallActive ?"calc(100vh - 350px - "+parseInt(document.getElementById('videoRoomContainer')?.clientHeight)+"px)": "calc(100vh - 350px)"),
  },
  labelContainer: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatar: {
    backgroundColor: theme.palette.reflexYellow.main,
    width: "2vw",
    height: "2vw",
  },
  draggableContainer : {
    cursor: "grab"
  },
  waitingContainer: {
    minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  color: "#f0f2f6",
  }
}));

function isLicenceValid(licence) {
  return licence.webcam && 
         (licence.webcamExpiry === 0 || licence.webcamExpiry > Date.now())
}

// TODO: refactor this whole thing to use a reducer instead of multiple state variables
export function TimeSlotEditorComponent({
  handleClosedTimeSlot,
  addUserToTimeSLot,
  removeUserFromTimeSLot,
  moveTimeSlot,
  removeTimeSlot,
  setOnAir,
  localUser,
  dimension,
}) {
  
  const monitorWallActive = useSelector((state) => state.ui.callModeratorComponent.monitorWallActive);
  const cameraConnected = useSelector((state) => state.websocket.connection.cameraConnected);
  const classes = useStyles({monitorWallActive,cameraConnected});
  const isOnAir = useSelector((state) => state.websocket.call.isOnAir);
  const onAirQueue = useSelector((state) =>
    mapOnAirToOnAirQueue(state.websocket.call.onAir)
  );
  const [oldAirQueue, setOldAirQueue] = useState(onAirQueue)
  const [changeInQueueNotOccured, setChangeInQueueNotOccured] = useState(false)

  if (!_.isEqual(onAirQueue, oldAirQueue)) {
    setChangeInQueueNotOccured(false)
    setOldAirQueue(onAirQueue)
  }

  // redefining parameter functions to add an extra step before them when they get called
  const [old_addUserToTimeSLot, old_removeUserFromTimeSLot, old_moveTimeSlot, old_removeTimeSlot] = [addUserToTimeSLot, removeUserFromTimeSLot, moveTimeSlot, removeTimeSlot]
  addUserToTimeSLot = (...params) => {
    setChangeInQueueNotOccured(true)
    old_addUserToTimeSLot(...params)
  }
  removeUserFromTimeSLot = (...params) => {
    setChangeInQueueNotOccured(true)
    old_removeUserFromTimeSLot(...params)
  }
  moveTimeSlot = (...params) => {
    setChangeInQueueNotOccured(true)
    old_moveTimeSlot(...params)
  }
  removeTimeSlot = (...params) => {
    setChangeInQueueNotOccured(true)
    old_removeTimeSlot(...params)
  }


  // const monitorWallActive = useSelector((state) => state.ui.callModeratorComponent.monitorWallActive);
  // const cameraConnected = useSelector((state) => state.websocket.connection.cameraConnected);

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [timeSlotToDelete, setTimeSlotToDelete] = useState();

  const handleDontDeleteTimeSlot = () => {
    setTimeSlotToDelete(undefined);
  };
  const handleDeleteTimeSlot = () => {
    removeTimeSlot(timeSlotToDelete);
    setTimeSlotToDelete(undefined);
  };

  const [replacingUser, setReplacingUser] = useState();
  const [movingUser, setMovingUser] = useState();


  const replaceUser = () => {
    const { avatar, userid, username, webcamSlot, timeSlot } = replacingUser;
    const user = { avatar, userid, username };
    addUserToTimeSLot(user, webcamSlot, timeSlot);
  }

  const moveUser = () => {
    // moves the user in a timeslot to a different webcamslot
    if (movingUser !== false && movingUser !== undefined) {
      const { avatar, userid, username, webcamSlot, timeSlot } = movingUser;
      const user = { avatar, userid, username };
      const oldSlot = Object.values(onAirQueue[timeSlot]).find(slot => slot.user.userid === userid)
      removeUserFromTimeSLot(oldSlot.webcamSlot, timeSlot)
      addUserToTimeSLot(user, webcamSlot, timeSlot)
    } else {
      const { avatar, userid, username, webcamSlot, timeSlot } = replacingUser
      const user = { avatar, userid, username };
      const oldSlot = Object.values(onAirQueue[timeSlot]).find(slot => slot.user.userid === userid)
      removeUserFromTimeSLot(oldSlot.webcamSlot, timeSlot)
      addUserToTimeSLot(user, webcamSlot, timeSlot)
    }
  }

  const reset = () => {
    setMovingUser(undefined)
    setReplacingUser(undefined)
  }

  const handleReplaceUser = () => {
    if (movingUser !== false) {
      replaceUser()
      reset()
    } else {
      moveUser()
      reset()
    }
  };
  
  const handleMoveUser = () => {
    if (replacingUser === undefined) {
      moveUser()
      reset()
    } else {
      setMovingUser(false)
    }
  }
  
  const handleCancelChange = () => {
    reset()
  };
  const nextWebcamIdOnAir = () => {
    dispatch(openModalNextOpenEvents())
  }
  const addUserToTimeSlotInterceptor = (selectedUser, webcamSlot, timeSlot) => {
    if (selectedUser === undefined) {
      store.addNotification({
        title: t("timeSlotComponent|errorAddUser"),
        message: t("timeSlotComponent|errorAddUserMessage"),
        type: "danger",
        insert: "center",
        container: "center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (
      onAirQueue[timeSlot] === undefined
    ) {
      const user = {
        // avatar: selectedUser.avatar,
        userid: selectedUser.userid,
        username: selectedUser.username,
      };
      addUserToTimeSLot(user, webcamSlot, timeSlot);
    } else if (
      onAirQueue[timeSlot][webcamSlot].user.userid === selectedUser.userid
    ) {
      console.log("trying to replace the same user");
    } else if (
      webcamSlot !== "100" &&
      !!Object.values(onAirQueue[timeSlot]).find(slot => {
        return slot.user.userid === selectedUser.userid && 
               slot.webcamSlot !== webcamSlot &&
               slot.webcamSlot !== "100"
      })
    ) {
      // add a movingUser state and add a check to see if the user is already in the timeSlot, then find a way to do execute the modals consecutively
      // maybe set a move user, then once its completed set a replaceUser?
      
      const movUser = {
        userid: selectedUser.userid,
        username: selectedUser.username,
        webcamSlot,
        timeSlot,
      }
      setMovingUser(movUser)
      if (onAirQueue[timeSlot][webcamSlot].user.userid !== "") {
        setReplacingUser(movUser);
      }

    } else if (
      onAirQueue[timeSlot][webcamSlot].user.userid === ""
    ) {
      // case where the targetted timeslot is new or the user is placed on an empty
      const user = {
        // avatar: selectedUser.avatar,
        userid: selectedUser.userid,
        username: selectedUser.username,
      };
      addUserToTimeSLot(user, webcamSlot, timeSlot);
    } else {
      setReplacingUser({
        // avatar: selectedUser.avatar,
        userid: selectedUser.userid,
        username: selectedUser.username,
        webcamSlot,
        timeSlot,
      });
    }
  };
  const override = css`
                display: block;
                margin: 0 auto;
                border-color: red;
              `;
  return (
    <LicencingContext.Provider
      value={localUser.getLicence()}
    >
    <TimeSlotEditorBindingsContext.Provider
      value={{
        addUserToTimeSLot: addUserToTimeSlotInterceptor,
        removeUserFromTimeSLot,
        moveTimeSlot,
        removeTimeSlot: setTimeSlotToDelete,
      }}
    >

      <Paper className={classes.root}>
        <Box className={isOnAir ? classes.headerOnAir : classes.header}>
          <CallOnAirButtonTimeSlot
            size="large"
            onClick={setOnAir}
            isOnAir={isOnAir}
            className={classes.putOnAirButton}
          />
          <WebcamRowDownButton
                disabled={!isOnAir}
                onClick={nextWebcamIdOnAir}
                color="primary"
                fontSize="2.8vh"
                className={classes.nextButton}
              ></WebcamRowDownButton>
          <Typography className={classes.title}>
            {t("timeSlotComponent|title")}
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleClosedTimeSlot}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Modal open={timeSlotToDelete !== undefined}>
          <Box className={classes.modalConfirmDelete}>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {t("timeSlotComponent|modalTitleDelete")}
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={handleDontDeleteTimeSlot}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <Box style={{ padding: "15px", textAlign: "center" }}>
              <Button
                className={classes.choseButton}
                onClick={handleDeleteTimeSlot}
              >
                {t("timeSlotComponent|modalYes")}
              </Button>
              <Button
                className={classes.choseButton}
                onClick={handleDontDeleteTimeSlot}
              >
                {t("timeSlotComponent|modalNo")}
              </Button>
            </Box>
          </Box>
        </Modal>


        <Modal open={replacingUser !== undefined && !movingUser}>
          <Box className={classes.modalConfirmDelete}>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {t("timeSlotComponent|modalTitleReplaceUser")}
              </Typography>
              <IconButton
                className={classes.closeButton}
                onClick={handleCancelChange}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <Box style={{ padding: "15px", textAlign: "center" }}>
              <Button
                className={classes.choseButton}
                onClick={handleReplaceUser}
              >
                {t("timeSlotComponent|modalYes")}
              </Button>
              <Button
                className={classes.choseButton}
                onClick={handleCancelChange}
              >
                {t("timeSlotComponent|modalNo")}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={movingUser}>
          <Box className={classes.modalConfirmDelete}>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {t("timeSlotComponent|modalTitleMovingUser")}
              </Typography>
              <IconButton
                className={classes.closeButton}
                onClick={handleCancelChange}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <Box style={{ padding: "15px", textAlign: "center" }}>
              <Button
                className={classes.choseButton}
                onClick={handleMoveUser} 
              >
                {t("timeSlotComponent|modalYes")} 
              </Button>
              <Button
                className={classes.choseButton}
                onClick={handleCancelChange}
              >
                {t("timeSlotComponent|modalNo")}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={changeInQueueNotOccured}
        >
          <Box className={classes.waitingContainer}>
           <PulseLoader color="#ff2900" loading="true" css={override} size={35} />
          </Box>
          
        </Modal>


        <Grid container xs={12} justifyContent="center">
          <Grid item xs={10}>
            <UsersLists />
          </Grid>
        </Grid>
        <WebcamSlotLabels />
        <Box className={classes.queueList}>
          {/* some sort of list stylings */}
          {onAirQueue.map((webcamSlot, index) => (
            <TimeSlot webcamSlots={webcamSlot} timeSlotId={index} dimension={dimension} />
          ))}
          <TimeSlot
            webcamSlots={undefined}
            timeSlotId={onAirQueue.length}
            isOnAirSlot={false}
            dimension={dimension}
          />
        </Box>
        <WebcamSlotLabels dimension={dimension} />
      </Paper>
    </TimeSlotEditorBindingsContext.Provider>
    </LicencingContext.Provider>
  );
}

function WebcamSlotLabels(dimension) {
  const classes = useStyles(dimension);
  return (
    <Box className={classes.labelContainer} >
      <Grid
        className={classes.bottomLabel}
        container
        xs={12}
        spacing={0}
        justifyContent="center"
      >
        <Grid item xs={1}></Grid>
        {Object.entries(labelIcons).map(([label, MyIcon]) => (
          <Grid item xs={1} key={label}>
            <Box  className={classes.slotLabel}>
            <MyIcon
             
            />
            </Box>            
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function TimeSlot({ webcamSlots, timeSlotId, isOnAirSlot, dimension }) {
  const classes = useStyles(dimension);
  const { moveTimeSlot, removeTimeSlot } = useContext(
    TimeSlotEditorBindingsContext
  );
  const { t } = useTranslation();

  const isEmptyTimeSlot = webcamSlots === undefined;
  let displayWebcamSlots = webcamSlots;
  if (isEmptyTimeSlot) {
    displayWebcamSlots = {};
    for (let i = 1; i <= 10; i++) {
      displayWebcamSlots[i] = {
        user: {
          userid: "",
        },
        timeSlot: timeSlotId,
        webcamSlot: i,
      };
    }
    displayWebcamSlots[100] = {
      user: {
        userid: "",
      },
      timeSlot: timeSlotId,
      webcamSlot: 100,
    };
  }

  const handleRemove = () => {
    removeTimeSlot(timeSlotId);
  };

  const handleDragStart = (e) => {
    const startTimeSlotId = timeSlotId;
    e.dataTransfer.setData("startTimeSlotId", startTimeSlotId);
  };

  const handleDragOver = (e) => e.preventDefault();

  const handleDrop = (e) => {
    const [startTimeSlotId, stopTimeSlotId] = [
      Number(e.dataTransfer.getData("startTimeSlotId")),
      timeSlotId,
    ];

    if (startTimeSlotId !== stopTimeSlotId) {
      moveTimeSlot(startTimeSlotId, stopTimeSlotId);
    }
  };

  return (
    <Paper
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={classes.draggableContainer}
    >
      <Grid spacing={0} container>
        <Grid item xs={1}>
          <Box className={classes.indexBox}>
            <Typography className={classes.indexLabel}>
              {isEmptyTimeSlot
                ? t("timeSlotComponent|newSlot")
                : timeSlotId + 1}
            </Typography>
            {!isEmptyTimeSlot && (
              <IconButton onClick={handleRemove}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
        {Object.values(displayWebcamSlots).map(
          ({ user, webcamSlot, timeSlot }) => (
            <Grid item xs={1}>
              <WebcamSlot
                user={user}
                slotsCoordinates={{ webcamSlot, timeSlot }}
              />
            </Grid>
          )
        )}
      </Grid>
    </Paper>
  );
}

function WebcamSlot({ user, slotsCoordinates, dimension }) {
  const classes = useStyles(dimension);
  const { addUserToTimeSLot, removeUserFromTimeSLot } = useContext(
    TimeSlotEditorBindingsContext
  );
  const licence = useContext(LicencingContext)

  const { t } = useTranslation();

  const participantSelected = useSelector(
    (state) => state.ui.callModeratorComponent.participantSelected
  );
  const moderatedSelected = useSelector(
    (state) => state.ui.callModeratorComponent.moderatedSelected
  );
  const contributorSelected = useSelector(
    (state) => state.ui.callModeratorComponent.contributorSelected
  );
  const userSelected = [
    participantSelected,
    moderatedSelected,
    contributorSelected,
  ].find((user) => user !== null);

  const isEmptySlot = user === undefined || user.userid === "";

  const handleAdd = () => {
    const { webcamSlot, timeSlot } = slotsCoordinates;
    addUserToTimeSLot(userSelected, webcamSlot, timeSlot);
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    removeUserFromTimeSLot(
      slotsCoordinates.webcamSlot,
      slotsCoordinates.timeSlot
    );
  };


  const goodLicence = isLicenceValid(licence) && (slotsCoordinates.webcamSlot <= licence.webcamNumberMaximum || slotsCoordinates.webcamSlot == 100)

  return (
    <Paper className={goodLicence ? classes.webcamSlot : classes.webcamSlotNotLicenced} onClick={goodLicence && handleAdd}>
      <Typography
        className={isEmptySlot ? classes.usernameEmpty : classes.username}
      >
        {!isEmptySlot && (
          <UserAvatar online={false} src={getUserAvatar(user.userid)} className={classes.avatar}>
            {stringAvatar(user.username)}
          </UserAvatar>
        )}
        {isEmptySlot 
          ? goodLicence 
            ? t("timeSlotComponent|addUser")
            : t("timeSlotComponent|badLicence") 
          : user.username}
      </Typography>

      {!isEmptySlot && (
        <IconButton
          onClick={goodLicence && handleRemove}
          className={classes.webcamSlotDeleteButton}
        >
          <DeleteIcon  />
        </IconButton>
      )}
    </Paper>
  );
}
