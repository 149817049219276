import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Form from './Form';

const ModalDialog = ({ open, handleClose, login, errorMessage, passwordProtected }) => {
    const backDropClick = (e) => {}
  return (
    
    <Dialog open={open} onClose={handleClose} hideBackdrop={true} disableEscapeKeyDown={true} disableBackdropClick={true}>
     
      <Form handleClose={handleClose} login={login} errorMessage={errorMessage} passwordProtected={passwordProtected}/>
    </Dialog>
  );
};

export default ModalDialog;