import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import i18nInstance from "../../../../i18n";
import {
  TextField,
  Box,
  Grid,
  Typography,
  Paper,
  Collapse,
  Badge,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import {
  callDidUpdate,
  websocketServices,
} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/reflexReactAPI";
import WebcamSlotComponent from "./WebcamSlotComponent";
import { StyledTypography } from "../../../commons/StyledTypography";
import SelectedUsersCards from "../users/SelectedUsersCards";
import UserOnAirChip from "../cards/UserOnAirChip";
import {
  WebcamDownButton,
  WebcamRowDownButton,
  WebcamRowUpButton,
  CallOnAirButton,
  ReduceButton,
  ExpandButton,
  ClearCallButton,
} from "../../../buttons/commonButtons";
import WebcamInputCard from "../cards/WebcamInputCard";
import QueuedFunctionsCard from "../cards/QueuedFunctionsCard";
import BallotIcon from '@material-ui/icons/Ballot';
import {openTimeSlotEvent, openModalNextOpenEvents} from "@reflex-interactions/library-reflex-client/dist/reflexAPI/store/reducers/ui"


const styles = (theme) => ({

  timeslotButton : {
      fontSize:"2.8vh",
      // color: theme.palette.reflexBlue.main,
      marginTop: "1px"
  },
  nextButton: {
    marginTop: "1px"
  }

})


class WebcamSlotsComponent extends Component {
  constructor(props) {
    super(props);
    this.nextWebcam = this.nextWebcam.bind(this);
    this.setOnAir = this.setOnAir.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.openTimeSlot = this.openTimeSlot.bind(this);
    this.state = {
      isOnAir: false,
      participants: [],
      streamers: [],
      callModerators: [],
      webcamPlayers: [],
      webcamSelected: {},
      opened: false,
    };
  }
  componentDidUpdate(prevProps) {
    const state = callDidUpdate(prevProps, this.props);
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }
  openTimeSlot() {
    websocketServices.reduxStore.default.dispatch(openTimeSlotEvent())
  }
  nextWebcamIdOnAir() {
    websocketServices.reduxStore.default.dispatch(openModalNextOpenEvents())
  }
  nextWebcam(id) {
    // event.preventDefault();
  }
  setOnAir() {
    if (this.state.isOnAir) {
      websocketServices.signals.stopCall();
    } else {
      websocketServices.signals.startCall();
    }
  }
  setOpen() {
    this.setState({ opened: !this.state.opened });
  }
  clearCall() {
    websocketServices.signals.clearCall();
  }
  render() {
    const { classes, theme } = this.props;
    var webcamSelected = false;
    var found = false;
    var onAirColor = "primary";
    if (this.state.isOnAir) {
      onAirColor = "secondary";
    }
    for (var x = 0; x < 10; x++) {
      if (x === 10) {
        if (
          this.state.webcamSelected["100"] !== null &&
          this.state.webcamSelected["100"] !== undefined
        ) {
          found = true;
        }
      } else {
        if (
          this.state.webcamSelected[x.toString()] !== null &&
          this.state.webcamSelected[x.toString()] !== undefined
        ) {
          found = true;
        }
      }
    }
    if (found && this.state.opened) {
      webcamSelected = true;
    }
    // found = false;
    return (
      <Box
        style={{
          transition: "all .5s ease-in-out",
          // backgroundColor: "#000000",
          // BorderAllRounded: true
          marginBottom: 5,
          // padding:5,
        }}
      >
        <Grid container justify="space-between">
        </Grid>
        <StyledTypography
          id="webcamInputsLabel"
          align="left"
          variant={"h6"}
          color={"reflexGreen"}
          style={{fontSize:"2.1vh"}}
        >
          <Trans>Users|Webcam Inputs Queues</Trans>
        </StyledTypography>
        <Grid
          id="webcamSlotsContainer"
          justify="space-evenly"
          container
          spacing={1}
        >
          <Grid item xs={1} container justify="center">
          <Grid item xs={3}>
            <Tooltip arrow title={i18nInstance.t("webcamSlotComponent|openTimeSlot")}>
              <IconButton  size="small" onClick={this.openTimeSlot}> 
                <BallotIcon className={classes.timeslotButton} 
                // style={{fontSize:"2.8vh"}}
                />
              </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <CallOnAirButton
                size={"small"}
                onClick={this.setOnAir}
                color={onAirColor}
                fontSize="2.8vh"
              ></CallOnAirButton>
            </Grid>
            <Grid item xs={3}>
              <WebcamRowDownButton
                disabled={!this.state.isOnAir}
                onClick={this.nextWebcamIdOnAir}
                color="primary"
                fontSize="2.8vh"
                className={classes.nextButton}
              ></WebcamRowDownButton>
            </Grid>
          </Grid>

          <Grid xs={1} sm={1} md={1} lg={1} xl={1} item container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={1}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 1</Typography>
            </Grid>
            
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={2}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 2</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={3}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 3</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 4</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={5}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 5</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={6}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 6</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={7}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 7</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={8}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 8</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={9}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 9</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Webcam 10</Typography>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} container spacing={0}>
            <Grid item xs={12}>
              <WebcamInputCard
                websocketServices={websocketServices}
                webcamId={100}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight:"bold", fontSize:"1.7vh"}} color={this.state.isOnAir?"secondary":"primary"}>Screen</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
const mapStateToProps = (state) => ({
  isOnAir: state.websocket.call.isOnAir,
  participants: state.websocket.participants,
  streamers: state.websocket.streamers,
  callModerators: state.websocket.callModerators,
  webcamPlayers: state.websocket.webcamPlayers,
  webcamSelected: state.ui.callModeratorComponent.webcamSelected,
});

export default withStyles(styles)(withTranslation()(
  connect(mapStateToProps)(WebcamSlotsComponent))
);
